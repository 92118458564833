import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const appsEndpoint = '/api/some-protected-route';
const authEndpoint = '/api/auth/login';
const logoutEndpoint = '/api/auth/logout';

// Verificar el token al cargar el estado inicial
const token = localStorage.getItem('token');
const initialUser = token ? { token } : null;

const initialState = {
    loading: false,
    isAuthenticated: !!token,
    user: initialUser,
    applications: [],
    error: null
};

// Thunk para login
export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }, { rejectWithValue }) => {
        try {
            const userAgent = navigator.userAgent;
            const response = await axios.post(`${baseUrl}${authEndpoint}`, {
                login: username,
                password,
                user_agent: userAgent
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const token = response.data.token;
            const email = response.data.email;

            // Recuperar aplicaciones usando el token
            const appsResponse = await axios.get(`${baseUrl}${appsEndpoint}`, {
                headers: { 'Authorization': `Bearer ${token}` },
                withCredentials: true
            });

            if (appsResponse.data.message === 'No apps') {
                return { token, applications: [] };
            }

            localStorage.setItem('token', token);
            localStorage.setItem('username', username);


            return { token, applications: appsResponse.data.applications, username, email };

        } catch (error) {
            console.error('Error during login process:', error);
            if (error.response) {
                if (error.response.status === 403 && error.response.data.message === 'No apps') {
                    return rejectWithValue('No tiene aplicaciones asignadas');
                }
                return rejectWithValue(error.response.data.message);
            }
            return rejectWithValue('Login failed or applications fetch failed');
        }
    }
);

// Thunk para logout
export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            await axios.post(`${baseUrl}${logoutEndpoint}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            localStorage.removeItem('token'); // Remover el token del localStorage al cerrar sesión
        } catch (error) {
            console.error('Error during logout process:', error);
            return rejectWithValue('Logout failed');
        }
    }
);

// Thunk para obtener aplicaciones del usuario autenticado
export const fetchApplications = createAsyncThunk(
    'auth/fetchApplications',
    async (_, { rejectWithValue }) => {
        const token = localStorage.getItem('token');
        if (!token) return rejectWithValue('No token available');

        try {
            const response = await axios.get(`${baseUrl}${appsEndpoint}`, {
                headers: { 'Authorization': `Bearer ${token}` },
                withCredentials: true
            });
            return response.data.applications || [];
        } catch (error) {
            console.error('Error fetching applications:', error);
            return rejectWithValue('Failed to fetch applications');
        }
    }
);


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Acción sin lógica específica para logout
        clearLogout: (state) => {
            state.loading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.applications = [];
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.user = {
                    token: action.payload.token,
                    username: action.payload.username,
                    email: action.payload.email,
                };
                state.applications = action.payload.applications;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.error = action.payload;
            })
            .addCase(logout.pending, (state) => {
                state.loading = true;
            })
            .addCase(logout.fulfilled, (state) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.user = null;
                state.applications = [];
                state.error = null;
            })
            // Reducer para fetchApplications
            .addCase(fetchApplications.fulfilled, (state, action) => {
                state.applications = action.payload;
            })
            .addCase(fetchApplications.rejected, (state, action) => {
                console.error(action.payload);
            })

            .addCase(logout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });


    }
});

export const { clearLogout } = authSlice.actions;
export default authSlice.reducer;