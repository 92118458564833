

import React, { useState } from 'react';
import { Button, Row, Col, Tabs } from 'antd';
import { UserOutlined, FileOutlined } from '@ant-design/icons'; // Iconos de Ant Design
import TablaUsuarios from './TablaUsuarios'; 
import SesionCF from './SesionCF';
const { TabPane } = Tabs;


const Usuario = () => {
    return <TablaUsuarios/>;
};


const Sesion = () => {
    return <SesionCF/>;
};

const FortalecimientoCap = () => {
    const [activeTab, setActiveTab] = useState("1"); // Estado para controlar el tab activo

    // Cambia el estado del tab según el botón presionado
    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <div className="container">
            <div className="row mb-2 d-flex align-items-center">
                <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                    <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                        <p>PROYECTO UTF <br /> COL 160 COL</p>
                    </div>
                    <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                        <p>
                            Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
                            institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
                        </p>
                    </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                    <h2 className="text-center mb-2">Fortalecimiento de capacidades</h2>
                    <Row gutter={24}>
                        <Col>
                            <Button
                                onClick={() => handleTabChange("1")}
                                style={{ width: 150 }}
                                icon={<UserOutlined />}
                            >
                                Participantes
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={() => handleTabChange("2")}
                                style={{ width: 150 }}
                                icon={<FileOutlined />}
                            >
                                Sesiónes
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <Tabs activeKey={activeTab} onChange={handleTabChange}  hideAdd>
                    <TabPane key="1">
                        <Usuario />
                    </TabPane>
                    <TabPane key="2">
                        <Sesion />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default FortalecimientoCap;
