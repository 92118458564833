import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpointVerificar = '/api/elevation'; // Este endpoint debe coincidir con el que maneja la altitud

// Crear una instancia de Axios
const api = axios.create({
    baseURL: baseUrl,
});

// Interceptor para agregar el token de autenticación a las solicitudes
api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Función para obtener la altitud usando latitud y longitud
export const getAltitud = async (detalle) => {
    try {
        const response = await api.post(endpointVerificar, detalle, { // Cambiar a POST
            headers: {
                'Content-Type': 'application/json',  // Encabezado JSON para enviar datos correctamente
            },
        });
        return response.data; // Devuelve los datos de la respuesta
    } catch (error) {
        console.error('Error al obtener la altitud:', error);
        throw error; // Lanza el error para que pueda ser manejado donde se llama a la función
    }
};


export default getAltitud;
