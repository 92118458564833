import axios from 'axios';
import config from '../../config'; // Configuración para obtener la URL base

const baseUrl = config.baseUrl;
const endpoint = '/api/cursoFortalecimientosesiones'; // Endpoint completo para las sesiones
const API_URL = `${baseUrl}${endpoint}`;

// Obtener todas las sesiones
export const getAllSesiones = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error al obtener todas las sesiones:', error);
    throw error;
  }
};

// Insertar una nueva sesión
export const insertSesion = async (sesionData) => {
  try {
    console.log('Datos de la sesión:', sesionData);
    const response = await axios.post(API_URL, sesionData);
    return response.data;
  } catch (error) {
    console.error('Error al insertar la sesión:', error);
    throw error;
  }
};

// Actualizar una sesión existente
export const updateSesion = async (oid, sesionData) => {
  try {
    const response = await axios.put(`${API_URL}/${oid}`, sesionData);
    return response.data;
  } catch (error) {
    console.error('Error al actualizar la sesión:', error);
    throw error;
  }
};

// Eliminar una sesión
export const deleteSesion = async (oid) => {
  try {
    const response = await axios.delete(`${API_URL}/${oid}`);
    return response.data;
  } catch (error) {
    console.error('Error al eliminar la sesión:', error);
    throw error;
  }
};

// Obtener sesiones por `ct_usuario`
export const getSesionesByCtUsuario = async (ct_usuario) => {
  try {
    const response = await axios.get(`${API_URL}/${ct_usuario}`);
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('Error al obtener las sesiones por ct_usuario:', error);
    throw error;
  }
};
