import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Form, Select, DatePicker, Button, Row, Col, Table, Space, Tooltip, message, Modal } from 'antd';
import { getAllSesiones, insertSesion, updateSesion, deleteSesion, getSesionesByCtUsuario } from '../../services/cursoFortalecimiento/cursoFortalecimeintoSesionService';
import { getAsistencia } from '../../services/cursoFortalecimiento/cursoFortalecimientoServiceDatos';
import { EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Asistencia from './Asistencia';
import Participantes from './Participantes';

const { Option } = Select;

const SesionCF = forwardRef(({ user, onCancel, onUpdateSessions }, ref) => {
    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [asistencias, setAsistencias] = useState([]);
    const [editingSession, setEditingSession] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [filterFecha, setFilterFecha] = useState(null);
    const [filterAsistencia, setFilterAsistencia] = useState(null);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [sesionOid, setSesionOid] = useState(null);
    useImperativeHandle(ref, () => ({
        resetFormFields() {
            form.resetFields();
        }
    }));

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                if (user) {
                    const data = await getSesionesByCtUsuario(user.oid);
                    setSessions(data);
                } else {
                    const data = await getAllSesiones();
                    setSessions(data);
                }
            } catch (error) {
                console.error('Error al cargar las sesiones:', error);
            }
        };
        fetchSessions();
    }, [user, form]);

    const fetchData = async () => {
        try {
            const asistenciaData = await getAsistencia(); // Obtener las asistencias
            setAsistencias(asistenciaData); // Actualizar el estado con los datos
        } catch (error) {
            console.error('Error al cargar los datos de asistencia:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const filteredSessions = sessions.filter((session) => {
        let matches = true;
        if (filterFecha) {
            const sessionFecha = dayjs(session.fecha_sesion).format('YYYY-MM-DD');
            matches = matches && sessionFecha === filterFecha.format('YYYY-MM-DD');
        }
        if (filterAsistencia !== null && filterAsistencia !== "") {
            matches = matches && session.asistencia_id === filterAsistencia;
        }
        return matches;
    });

    const handleEdit = (session) => {
        setIsEditing(true);
        setEditingSession(session);
        form.setFieldsValue({
            ...session,
            fecha_sesion: session.fecha_sesion ? dayjs(session.fecha_sesion) : null,
        });
    };

    const handleDelete = async (sesion) => {
        try {
            await deleteSesion(sesion.sesion_id);
            message.success('Sesión eliminada con éxito');
            const updatedSessions = sessions.filter((session) => session.sesion_id !== sesion.sesion_id);
            setSessions(updatedSessions);
            onUpdateSessions(user.oid, updatedSessions.length);
        } catch (error) {
            message.error('Error al eliminar la sesión');
            console.error('Error al eliminar la sesión:', error);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setIsEditing(false);
        setEditingSession(null);
    };

    const handleSubmit = async (values) => {
        try {
            const sesionData = {
                participo: values.participo,
                fecha_sesion: values.fecha_sesion,
                ct_usuario: user.oid,
                asistencia_id: values.asistencia_id,
            };

            if (isEditing && editingSession) {
                await updateSesion(editingSession.sesion_id, sesionData);
                message.success('Sesión actualizada con éxito');
            } else {
                await insertSesion(sesionData);
                message.success('Sesión creada con éxito');
            }

            form.resetFields();
            setIsEditing(false);
            setEditingSession(null);
            const updatedSessions = await getSesionesByCtUsuario(user.oid);
            setSessions(updatedSessions);
            onUpdateSessions(user.oid, updatedSessions.length);
        } catch (error) {
            message.error('Error al guardar la sesión');
            console.error('Error al guardar la sesión:', error);
        }
    };
    
    const renderParticipantes = (text, record) => record.participantes || 0;
    const renderHombres = (text, record) => record.hombres || 0;
    const renderMujeres = (text, record) => record.mujeres || 0;
    const columns = [
        {
            title: 'Regional',
            dataIndex: 'regional',
            key: 'regional',
        },
        {
            title: 'Departamento',
            dataIndex: 'departamento',
            key: 'departamento',
        },
        {
            title: 'Municipio',
            dataIndex: 'municipio',
            key: 'municipio',
        },
        {
            title: 'Sesión',
            dataIndex: 'asistencia',
            key: 'asistencia',
        }, 
        {
            title: 'Fecha de Sesión',
            dataIndex: 'fecha_sesion',
            key: 'fecha_sesion',
            align: 'center',
            render: (fecha) => new Date(fecha).toISOString().split('T')[0],
        },
        ...(!user ? [
            {
                title: '# Hombres',
                dataIndex: 'hombres',
                key: 'hombres',
                align: 'center',
                render: renderHombres, 
            },
            {
                title: '# Mujeres',
                dataIndex: 'mujeres',
                key: 'mujeres',
                align: 'center',
                render: renderMujeres, 
            },
            {
                title: '# Participantes',
                dataIndex: 'participantes',
                key: 'participantes',
                align: 'center',
                render: renderParticipantes, 
            },
        ] : []),

        {
            title: 'Acciones',
            key: 'acciones',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    {user ? (
                        <>
                            <Tooltip title="Editar sesión">
                                <Button
                                    type="primary"
                                    icon={<EditOutlined />}
                                    onClick={() => handleEdit(record)}
                                />
                            </Tooltip>
                            <Tooltip title="Eliminar">
                                <Button
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleDelete(record)}
                                />
                            </Tooltip>
                        </>
                    ) : (
                        <Tooltip title="Agregar Participantes">
                            <Button
                                type="primary"
                                icon={<UserAddOutlined />}
                                onClick={() => handleOpenModal(record)}
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const clearFilters = async () => {
        setFilterFecha(null);
        setFilterAsistencia(null);
        form.resetFields();
        let data = [];
        if (user) {
            data = await getSesionesByCtUsuario(user.oid);
        } else {
            data = await getAllSesiones();
        }
        setSessions(data);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancelModel = () => {
        setIsModalVisible(false);
    };

    const handleAsistenciaCreated = () => {
        fetchData();
    };

    // Función para abrir el modal
    const handleOpenModal = (record) => {
        setSesionOid(record);
        setIsModalVisible2(true);
    };

    // Función para cerrar el modal
    const handleCloseModal = () => {
        setSesionOid(null);
        setIsModalVisible2(false);
    };
    return (
        <>
            {user && (<Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={8} lg={4} xl={8}>
                        <Form.Item label="Sesión" name="asistencia_id" rules={[{ required: true, message: 'Seleccione la sesión' }]}>
                            <Select placeholder="Seleccione la sesión">
                                {asistencias.map((asistencia) => (
                                    <Option key={asistencia.oid} value={asistencia.oid}>
                                        {asistencia.nombre}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={2} lg={4} xl={2}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={showModal}
                            style={{
                                backgroundColor: '#3BB44A',
                                color: '#ffffff',
                                marginTop: '28px',
                                width: '100%',
                            }}
                        >
                        </Button>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4} xl={5}>
                        <Form.Item label="Fecha de Sesión" name="fecha_sesion" rules={[{ required: true, message: 'Seleccione la fecha de la sesión' }]}>
                            <DatePicker style={{ width: '100%' }} value={dayjs()} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4} xl={8}>
                        <Form.Item style={{ paddingTop: '29px' }}>
                            <Button type="primary" htmlType="submit" icon={isEditing ? <EditOutlined /> : <PlusOutlined />} style={{ }}>
                                {isEditing ? 'Actualizar' : 'Guardar'}
                            </Button>
                            {isEditing && (
                                <Button style={{ marginLeft: 8, }} onClick={handleCancel} icon={<CloseOutlined />}>
                                    Cancelar
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>)}
            {!user && (<div style={{ marginBottom: '20px', textAlign: 'left' }}>
                <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                    <Col xs={24} sm={12} md={8} lg={4} xl={8}>
                        <Select
                            placeholder="Filtrar por asistencia"
                            value={filterAsistencia || ""}
                            onChange={(value) => setFilterAsistencia(value)}
                            style={{ width: '100%' }}
                        >
                            <Option value="">Todos</Option>
                            {asistencias.map((asistencia) => (
                                <Option key={asistencia.oid} value={asistencia.oid}>
                                    {asistencia.nombre}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={4} xl={5}>
                        <DatePicker
                            placeholder="Filtrar por fecha"
                            value={filterFecha ? dayjs(filterFecha) : null}
                            onChange={(date) => setFilterFecha(date)}
                            style={{ width: '100%' }}
                        />
                    </Col>

                    <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                        <Button
                            onClick={clearFilters}
                            icon={<SearchOutlined style={{ color: '#000000' }} />}
                            style={{
                                backgroundColor: '#1677FF',
                                color: '#ffffff',
                                width: '100%',
                            }}
                        >
                            Limpiar filtros
                        </Button>
                    </Col>
                </Row>
            </div>)}
            
            <Table
                columns={columns}
                dataSource={filteredSessions.map((session, index) => ({ ...session, key: `${session.oid}-${index}` }))}
                pagination={{ pageSize: 10 }}
                style={{ marginTop: '20px' }}
                footer={() => (
                    user ? (
                        <div style={{ textAlign: 'left', paddingRight: '15px' }}>
                            <strong>Total de sesiones: </strong> {filteredSessions.length}
                        </div>
                    ) : null
                )}
            />
            <Modal
                title="Registrar Nueva Sesión"
                open={isModalVisible}
                onCancel={handleCancelModel}
                footer={null}
                width={700}
            >
                <Asistencia onAsistenciaCreated={handleAsistenciaCreated} />
            </Modal>
            <Modal
                title="Participantes"
                open={isModalVisible2}
                onCancel={handleCloseModal}
                footer={null}
                width={1200}
            >
                <Participantes sesion={sesionOid} />
            </Modal>
        </>
    );

});

export default SesionCF;
