import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/ippta';
const endpointExport = '/api/ipptaExportable';

const getIppta = async () => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los reportes:', error);
    throw error; // Lanza el error para ser manejado en el componente React
  }
};

const getIpptaExportable = async () => { 
  try {
    const response = await axios.get(`${config.baseUrl}${endpointExport}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los reportes:', error);
    throw error; // Lanza el error para ser manejado en el componente React
  }
};


export { getIppta, getIpptaExportable };
