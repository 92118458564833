// cookieUtils.js
import Cookies from 'js-cookie';

export const setToken = (token) => Cookies.set('token', token, { expires: 1 });
export const getToken = () => Cookies.get('token');
export const removeToken = () => Cookies.remove('token');

export const setUsername = (username) => Cookies.set('username', username, { expires: 1 });
export const getUsername = () => Cookies.get('username');
export const removeUsername = () => Cookies.remove('username');

export const setApplications = (apps) => Cookies.set('applications', JSON.stringify(apps), { expires: 1 });
export const getApplications = () => {
    const apps = Cookies.get('applications');
    return apps ? JSON.parse(apps) : [];
};
export const removeApplications = () => Cookies.remove('applications');
