import React, { useEffect, useState, useCallback } from 'react';
import { UploadOutlined, DownloadOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import { guardarSoportePP, getSoportesPP, deleteSoportePP, downloadSupportPP, viewSupportPP } from '../../services/planesPedagogicos/planesPedagogicosServicesSoportes';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';

const PlanPedagogicoSoporte = ({ dataPPSoporte, onUpload, onDelete }) => {
  const [soportes, setSoportes] = useState([]);
  const usernameFromState = useSelector((state) => state.auth.user);
  //Control de Permisos
  const appName = 'mer'; // El nombre de la aplicación actual
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionDelete = permissions.priv_delete === 'Y';
  const permissionUpdate = permissions.priv_update === 'Y';
  const permissionInsert = permissions.priv_insert === 'Y';

  // Función para obtener los soportes MER
  const fetchSoportes = useCallback(async () => {
    try {
      const { status, data } = await getSoportesPP(dataPPSoporte.oid);
      if (status === 200) {
        if (Array.isArray(data)) { // Ahora solo necesitas verificar si es un arreglo
          setSoportes(data); // Establece el arreglo de soportes directamente
        } else {
          message.error('Error: el formato de la respuesta no es el esperado');
        }
      } else {
        setSoportes([]); 
        message.warning('Sin soportes');
      }
    } catch (error) {
      message.error('Error al obtener los soportes MER');
    }
  }, [dataPPSoporte.oid]);

  // Cargar los soportes al montar el componente
  useEffect(() => {
    fetchSoportes();
  }, [fetchSoportes]);

  // Función para subir un archivo
  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      if (!file || !dataPPSoporte || !dataPPSoporte.name || !usernameFromState.username) {
        message.error('Faltan datos para realizar la carga');
        return;
      }
      const ruta = `/actividad_22/tarea_221/indicador_32/medio_verificacion_27/Planes Pedagógicos/${dataPPSoporte.name}`;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('nombre', file.name);
      formData.append('ruta', ruta);
      formData.append('usuario', usernameFromState.username);
      formData.append('tipo_archivo', file.type);
      formData.append('oidProyecto', dataPPSoporte.oid);
      formData.append('activo', 1);
      const { status } = await guardarSoportePP(formData);
      if (status === 200) {
        message.success('Archivo subido correctamente');
        onSuccess(null, file);
        onUpload(dataPPSoporte);
        fetchSoportes();
      } else {
        message.error('Error al subir el archivo');
        onError(new Error('Error al subir el archivo'));
      }
    } catch (error) {
      console.error('Error al subir el archivo:', error);
      message.error('Error al subir el archivo');
      onError(error);
    }
  };

  // Propiedades para el componente de subida de archivos
  const uploadProps = {
    customRequest,
    showUploadList: false,
  };

  //funcion para eliminar un soporte
  const handleRemove = async (file) => {
    try {
      const soporteId = file.oid;
      const { status } = await deleteSoportePP(soporteId);
      if (status === 200) {
        message.success('Archivo eliminado correctamente');
        onDelete(dataPPSoporte.oid);
        fetchSoportes();
      } else {
        message.error('Error al eliminar el archivo');
      }
    } catch (error) {
      console.error('Error al eliminar el archivo:', error);
      message.error('Error al eliminar el archivo');
    }
  };

  // Función para descargar un archivo
  const handleDownload = async (file) => {
    try {
      const soporteId = file.oid;
      const nombreSoporte = file.name;
      const { status } = await downloadSupportPP(soporteId, nombreSoporte);
      if (status === 200) {
        message.success('Archivo descargado correctamente');
      } else {
        message.error('Error al descargar el archivo');
      }
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      message.error('Error al descargar el archivo');
    }
  };

  const handlePreview = async (file) => {
    try {
      const extension = file.name.split('.').pop().toLowerCase();
      const allowedTypes = ['png', 'jpeg', 'jpg', 'pdf']; // Extensiones permitidas
      const soporteId = file.oid;
      if (allowedTypes.includes(extension)) {
        const { status } = await viewSupportPP(soporteId);
        status === 200 ? message.success('Archivo abierto correctamente') : message.error('Error al abrir el archivo');
      } else {
        message.info('No se puede visualizar el archivo, se descargará automáticamente.');
        handleDownload(file);
      }
    } catch (error) {
      console.error('Error al abrir el archivo:', error);
      message.error('Error al abrir el archivo');
    }
  };

  return (
    <div>
      {permissionInsert && (
        <div style={{
          border: '2px dashed #d9d9d9',
          padding: 8,
          borderRadius: '10px',
          width: '96%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px', // Ajusta la altura según sea necesario
          textAlign: 'center'
        }}>
          <Upload listType="picture-card" {...uploadProps}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <UploadOutlined style={{ fontSize: '24px' }} />
              <div style={{ marginTop: 8 }}>Cargar Archivo</div>
            </div>
          </Upload>
        </div>
      )}

      <div style={{ marginTop: 8 }}>
        <Upload
          listType="picture"
          fileList={soportes.map((soporte) => ({
            oid: soporte.oid.toString(),
            name: soporte.nombre,
            status: 'done',
            //url: getDownloadUrl(soporte),
          }))}
          onRemove={handleRemove}
          onPreview={handlePreview}
          showUploadList={{
            showPreviewIcon: true,
            previewIcon: <EyeOutlined style={{ fontSize: '20px', color: '#1890ff' }} />,
            showDownloadIcon: true,
            downloadIcon: <DownloadOutlined style={{ fontSize: '20px', color: '#1890ff' }} />,
            showRemoveIcon: permissionDelete ? true : false,
            removeIcon: <DeleteOutlined style={{ fontSize: '20px', color: 'red' }} />,
          }}
          onDownload={handleDownload}
        />
      </div>
    </div>
  );
};

export default PlanPedagogicoSoporte;
