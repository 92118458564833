export const groupLevels = {
    'Grupo A': ['A1', 'A2', 'A3', 'A4', 'A5'],
    'Grupo B': ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7'],
    'Grupo C': ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11', 'C12', 'C13', 'C14', 'C15', 'C16', 'C17', 'C18'],
    'Grupo D': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10', 'D11', 'D12', 'D13', 'D14', 'D15', 'D16', 'D17', 'D18', 'D19', 'D20', 'D21'],
  };
  
 export  const estadosCiviles = [
    { estado: 'Soltero/a' },
    { estado: 'Casado/a' },
    { estado: 'Divorciado/a' },
    { estado: 'Unión libre' }
  ];
  
 export const subregiones = [
    { id: 1, descripcion: "ALTO PATÍA Y NORTE DEL CAUCA" },
    { id: 2, descripcion: "ARAUCA" },
    { id: 3, descripcion: "BAJO CAUCA Y NORDESTE ANTIOQUEÑO" },
    { id: 4, descripcion: "CATATUMBO" },
    { id: 5, descripcion: "CHOCÓ" },
    { id: 6, descripcion: "CUENCA DEL CAGUÁN Y PIEDEMONTE CAQUETEÑO" },
    { id: 7, descripcion: "MACARENA - GUAVIARE" },
    { id: 8, descripcion: "MONTES DE MARÍA" },
    { id: 9, descripcion: "PACÍFICO MEDIO" },
    { id: 10, descripcion: "PACÍFICO Y FRONTERA NARIÑENSE" },
    { id: 11, descripcion: "PUTUMAYO" },
    { id: 12, descripcion: "SIERRA NEVADA - PERIJÁ" },
    { id: 13, descripcion: "SUR DE BOLÍVAR" },
    { id: 14, descripcion: "SUR DE CÓRDOBA" },
    { id: 15, descripcion: "SUR DEL TOLIMA" },
    { id: 16, descripcion: "URABÁ ANTIOQUEÑO" }
  ];

export const dataLineaProductiva = {
    "sectores": [
        {
            "id": 1,
            "nombre": "Acuícola y Pesquero",
            "subsectores": [
                {
                    "id": 1,
                    "nombre": "Pesca Artesanal",
                    "lineas_productivas": [
                        {
                            "id": 1,
                            "nombre": "Crustáceos"
                        },
                        {
                            "id": 2,
                            "nombre": "Mariscos"
                        },
                        {
                            "id": 3,
                            "nombre": "Moluscos"
                        },
                        {
                            "id": 4,
                            "nombre": "Varios Peces"
                        }
                    ]
                },
                {
                    "id": 2,
                    "nombre": "Piscicultura",
                    "lineas_productivas": [
                        {
                            "id": 5,
                            "nombre": "Bocachico"
                        },
                        {
                            "id": 6,
                            "nombre": "Cachama"
                        },
                        {
                            "id": 7,
                            "nombre": "Camarón"
                        },
                        {
                            "id": 8,
                            "nombre": "Carpa"
                        },
                        {
                            "id": 9,
                            "nombre": "Mojarra"
                        },
                        {
                            "id": 10,
                            "nombre": "Varios Peces"
                        },
                        {
                            "id": 11,
                            "nombre": "Tilapia"
                        },
                        {
                            "id": 12,
                            "nombre": "Trucha"
                        }
                    ]
                }
            ]
        },
        {
            "id": 2,
            "nombre": "Agricola",
            "subsectores": [
                {
                    "id": 3,
                    "nombre": "Cereales",
                    "lineas_productivas": [
                        {
                            "id": 13,
                            "nombre": "Arroz"
                        },
                        {
                            "id": 14,
                            "nombre": "Avena"
                        },
                        {
                            "id": 15,
                            "nombre": "Cebada"
                        },
                        {
                            "id": 16,
                            "nombre": "Centeno"
                        },
                        {
                            "id": 17,
                            "nombre": "Maíz"
                        },
                        {
                            "id": 18,
                            "nombre": "Millo"
                        },
                        {
                            "id": 19,
                            "nombre": "Quinua"
                        },
                        {
                            "id": 20,
                            "nombre": "Sorgo"
                        },
                        {
                            "id": 21,
                            "nombre": "Trigo"
                        }
                    ]
                },
                {
                    "id": 4,
                    "nombre": "Cultivos Permanentes",
                    "lineas_productivas": [
                        {
                            "id": 22,
                            "nombre": "Cacao"
                        },
                        {
                            "id": 23,
                            "nombre": "Café"
                        },
                        {
                            "id": 24,
                            "nombre": "Caña Azucarera"
                        },
                        {
                            "id": 25,
                            "nombre": "Caña Miel"
                        },
                        {
                            "id": 26,
                            "nombre": "Caña Panelera"
                        },
                        {
                            "id": 27,
                            "nombre": "Morera"
                        },
                        {
                            "id": 28,
                            "nombre": "Tabaco Negro"
                        }
                    ]
                },
                {
                    "id": 5,
                    "nombre": "Cultivos Transitorios",
                    "lineas_productivas": [
                        {
                            "id": 29,
                            "nombre": "Cannabis"
                        },
                        {
                            "id": 20,
                            "nombre": "Tabaco Rubio"
                        }
                    ]
                },
                {
                    "id": 6,
                    "nombre": "Fibras",
                    "lineas_productivas": [
                        {
                            "id": 31,
                            "nombre": "Algodón"
                        },
                        {
                            "id": 32,
                            "nombre": "Caña Flecha"
                        },
                        {
                            "id": 33,
                            "nombre": "Cáñamo"
                        },
                        {
                            "id": 34,
                            "nombre": "Esparto"
                        },
                        {
                            "id": 35,
                            "nombre": "Estropajo"
                        },
                        {
                            "id": 36,
                            "nombre": "Fique"
                        },
                        {
                            "id": 37,
                            "nombre": "Iraca"
                        },
                        {
                            "id": 38,
                            "nombre": "Mimbre"
                        },
                        {
                            "id": 39,
                            "nombre": "Palma Amarga"
                        }
                    ]
                },
                {
                    "id": 7,
                    "nombre": "Flores y Follajes",
                    "lineas_productivas": [
                        {
                            "id": 40,
                            "nombre": "Anturio"
                        },
                        {
                            "id": 41,
                            "nombre": "Asparragus"
                        },
                        {
                            "id": 42,
                            "nombre": "Astromelia"
                        },
                        {
                            "id": 43,
                            "nombre": "Clavel"
                        },
                        {
                            "id": 44,
                            "nombre": "Cordeline Cinta"
                        },
                        {
                            "id": 45,
                            "nombre": "Crisantemo"
                        },
                        {
                            "id": 46,
                            "nombre": "Eucalipto Baby Blue"
                        },
                        {
                            "id": 47,
                            "nombre": "Fitosporum"
                        },
                        {
                            "id": 48,
                            "nombre": "Flores y Follajes"
                        },
                        {
                            "id": 49,
                            "nombre": "Girasol"
                        },
                        {
                            "id": 50,
                            "nombre": "Gladiolo"
                        },
                        {
                            "id": 51,
                            "nombre": "Helecho"
                        },
                        {
                            "id": 52,
                            "nombre": "Heliconia"
                        },
                        {
                            "id": 53,
                            "nombre": "Hortensia"
                        },
                        {
                            "id": 54,
                            "nombre": "Orquídea"
                        },
                        {
                            "id": 55,
                            "nombre": "Palma Robelina"
                        },
                        {
                            "id": 56,
                            "nombre": "Rosa"
                        },
                        {
                            "id": 57,
                            "nombre": "Tree Fern"
                        }
                    ]
                },
                {
                    "id": 8,
                    "nombre": "Frutales",
                    "lineas_productivas": [
                        {
                            "id": 58,
                            "nombre": "Agraz"
                        },
                        {
                            "id": 59,
                            "nombre": "Aguacate"
                        },
                        {
                            "id": 60,
                            "nombre": "Aguaje"
                        },
                        {
                            "id": 61,
                            "nombre": "Algarrobo"
                        },
                        {
                            "id": 62,
                            "nombre": "Anón"
                        },
                        {
                            "id": 63,
                            "nombre": "Arándano"
                        },
                        {
                            "id": 64,
                            "nombre": "Arazá"
                        },
                        {
                            "id": 65,
                            "nombre": "Asai"
                        },
                        {
                            "id": 66,
                            "nombre": "Bacuri"
                        },
                        {
                            "id": 67,
                            "nombre": "Badea"
                        },
                        {
                            "id": 68,
                            "nombre": "Bananito"
                        },
                        {
                            "id": 69,
                            "nombre": "Banano"
                        },
                        {
                            "id": 70,
                            "nombre": "Borojó"
                        },
                        {
                            "id": 71,
                            "nombre": "Brevo"
                        },
                        {
                            "id": 72,
                            "nombre": "Caducifolios"
                        },
                        {
                            "id": 73,
                            "nombre": "Caimo"
                        },
                        {
                            "id": 74,
                            "nombre": "Chamba"
                        },
                        {
                            "id": 75,
                            "nombre": "Chirimoya"
                        },
                        {
                            "id": 76,
                            "nombre": "Cholupa"
                        },
                        {
                            "id": 77,
                            "nombre": "Chontaduro"
                        },
                        {
                            "id": 78,
                            "nombre": "Ciruela"
                        },
                        {
                            "id": 79,
                            "nombre": "Cítricos"
                        },
                        {
                            "id": 80,
                            "nombre": "Coco"
                        },
                        {
                            "id": 81,
                            "nombre": "Cocona"
                        },
                        {
                            "id": 82,
                            "nombre": "Copoazu"
                        },
                        {
                            "id": 83,
                            "nombre": "Corozo"
                        },
                        {
                            "id": 84,
                            "nombre": "Curuba"
                        },
                        {
                            "id": 85,
                            "nombre": "Dátil"
                        },
                        {
                            "id": 86,
                            "nombre": "Durazno"
                        },
                        {
                            "id": 87,
                            "nombre": "Feijoa"
                        },
                        {
                            "id": 88,
                            "nombre": "Frambuesa"
                        },
                        {
                            "id": 89,
                            "nombre": "Fresa"
                        },
                        {
                            "id": 90,
                            "nombre": "Frutales Varios"
                        },
                        {
                            "id": 91,
                            "nombre": "Granadilla"
                        },
                        {
                            "id": 92,
                            "nombre": "Guama"
                        },
                        {
                            "id": 93,
                            "nombre": "Guanábana"
                        },
                        {
                            "id": 94,
                            "nombre": "Guayaba"
                        },
                        {
                            "id": 95,
                            "nombre": "Gulupa"
                        },
                        {
                            "id": 96,
                            "nombre": "Higo"
                        },
                        {
                            "id": 97,
                            "nombre": "Lima"
                        },
                        {
                            "id": 98,
                            "nombre": "Limón"
                        },
                        {
                            "id": 99,
                            "nombre": "Lulo"
                        },
                        {
                            "id": 100,
                            "nombre": "Macadamia"
                        },
                        {
                            "id": 101,
                            "nombre": "Mamey"
                        },
                        {
                            "id": 102,
                            "nombre": "Mamoncillo"
                        },
                        {
                            "id": 103,
                            "nombre": "Mandarina"
                        },
                        {
                            "id": 104,
                            "nombre": "Mango"
                        },
                        {
                            "id": 105,
                            "nombre": "Mangostino"
                        },
                        {
                            "id": 106,
                            "nombre": "Manzana"
                        },
                        {
                            "id": 107,
                            "nombre": "Maracuyá"
                        },
                        {
                            "id": 108,
                            "nombre": "Marañón"
                        },
                        {
                            "id": 109,
                            "nombre": "Melón"
                        },
                        {
                            "id": 110,
                            "nombre": "Mora"
                        },
                        {
                            "id": 111,
                            "nombre": "Naranja"
                        },
                        {
                            "id": 112,
                            "nombre": "Níspero"
                        },
                        {
                            "id": 113,
                            "nombre": "Noni"
                        },
                        {
                            "id": 114,
                            "nombre": "Papaya"
                        },
                        {
                            "id": 115,
                            "nombre": "Papayuela"
                        },
                        {
                            "id": 116,
                            "nombre": "Patilla"
                        },
                        {
                            "id": 117,
                            "nombre": "Pepa De Pan"
                        },
                        {
                            "id": 118,
                            "nombre": "Pera"
                        },
                        {
                            "id": 119,
                            "nombre": "Piña"
                        },
                        {
                            "id": 120,
                            "nombre": "Pitahaya"
                        },
                        {
                            "id": 121,
                            "nombre": "Pomarrosa"
                        },
                        {
                            "id": 122,
                            "nombre": "Pomelo"
                        },
                        {
                            "id": 123,
                            "nombre": "Rambutan"
                        },
                        {
                            "id": 124,
                            "nombre": "Tamarindo"
                        },
                        {
                            "id": 125,
                            "nombre": "Tangelo"
                        },
                        {
                            "id": 126,
                            "nombre": "Tomate De Árbol"
                        },
                        {
                            "id": 127,
                            "nombre": "Toronja"
                        },
                        {
                            "id": 128,
                            "nombre": "Uchuva"
                        },
                        {
                            "id": 129,
                            "nombre": "Umari"
                        },
                        {
                            "id": 130,
                            "nombre": "Uva"
                        },
                        {
                            "id": 131,
                            "nombre": "Uva Caimarona"
                        },
                        {
                            "id": 132,
                            "nombre": "Zapote"
                        }
                    ]
                },
                {
                    "id": 9,
                    "nombre": "Hongos",
                    "lineas_productivas": [
                        {
                            "id": 133,
                            "nombre": "Champiñón"
                        },
                        {
                            "id": 134,
                            "nombre": "Orellana"
                        },
                        {
                            "id": 135,
                            "nombre": "Shiitake"
                        }
                    ]
                },
                {
                    "id": 10,
                    "nombre": "Hortalizas",
                    "lineas_productivas": [
                        {
                            "id": 136,
                            "nombre": "Acelga"
                        },
                        {
                            "id": 137,
                            "nombre": "Ahuyama"
                        },
                        {
                            "id": 138,
                            "nombre": "Ají"
                        },
                        {
                            "id": 139,
                            "nombre": "Ajo"
                        },
                        {
                            "id": 140,
                            "nombre": "Alcachofa"
                        },
                        {
                            "id": 141,
                            "nombre": "Apio"
                        },
                        {
                            "id": 142,
                            "nombre": "Berenjena"
                        },
                        {
                            "id": 143,
                            "nombre": "Brócoli"
                        },
                        {
                            "id": 144,
                            "nombre": "Calabacín"
                        },
                        {
                            "id": 145,
                            "nombre": "Calabaza"
                        },
                        {
                            "id": 146,
                            "nombre": "Cebolla De Bulbo"
                        },
                        {
                            "id": 147,
                            "nombre": "Cebolla De Rama"
                        },
                        {
                            "id": 148,
                            "nombre": "Cebollín"
                        },
                        {
                            "id": 149,
                            "nombre": "Cilantro"
                        },
                        {
                            "id": 150,
                            "nombre": "Col"
                        },
                        {
                            "id": 151,
                            "nombre": "Coliflor"
                        },
                        {
                            "id": 152,
                            "nombre": "Esparrago"
                        },
                        {
                            "id": 153,
                            "nombre": "Espinaca"
                        },
                        {
                            "id": 154,
                            "nombre": "Guatila"
                        },
                        {
                            "id": 155,
                            "nombre": "Hortalizas Varias"
                        },
                        {
                            "id": 156,
                            "nombre": "Lechuga"
                        },
                        {
                            "id": 157,
                            "nombre": "Nabo"
                        },
                        {
                            "id": 158,
                            "nombre": "Palmito"
                        },
                        {
                            "id": 159,
                            "nombre": "Pepino"
                        },
                        {
                            "id": 160,
                            "nombre": "Pimentón"
                        },
                        {
                            "id": 161,
                            "nombre": "Puerro"
                        },
                        {
                            "id": 162,
                            "nombre": "Rábano"
                        },
                        {
                            "id": 163,
                            "nombre": "Remolacha"
                        },
                        {
                            "id": 164,
                            "nombre": "Repollo"
                        },
                        {
                            "id": 165,
                            "nombre": "Tomate"
                        },
                        {
                            "id": 166,
                            "nombre": "Zanahoria"
                        }
                    ]
                },
                {
                    "id": 11,
                    "nombre": "Leguminosas",
                    "lineas_productivas": [
                        {
                            "id": 167,
                            "nombre": "Alfalfa"
                        },
                        {
                            "id": 168,
                            "nombre": "Arveja"
                        },
                        {
                            "id": 169,
                            "nombre": "Chachafruto"
                        },
                        {
                            "id": 170,
                            "nombre": "Frijol"
                        },
                        {
                            "id": 171,
                            "nombre": "Garbanzo"
                        },
                        {
                            "id": 172,
                            "nombre": "Guandul"
                        },
                        {
                            "id": 173,
                            "nombre": "Haba"
                        },
                        {
                            "id": 174,
                            "nombre": "Habichuela"
                        },
                        {
                            "id": 175,
                            "nombre": "Lenteja"
                        },
                        {
                            "id": 176,
                            "nombre": "Maní"
                        },
                        {
                            "id": 177,
                            "nombre": "Soya"
                        }
                    ]
                },
                {
                    "id": 12,
                    "nombre": "Oleaginosas",
                    "lineas_productivas": [
                        {
                            "id": 178,
                            "nombre": "Ajonjolí"
                        },
                        {
                            "id": 179,
                            "nombre": "Higuerilla"
                        },
                        {
                            "id": 180,
                            "nombre": "Jatropha"
                        },
                        {
                            "id": 181,
                            "nombre": "Palma De Aceite"
                        },
                        {
                            "id": 182,
                            "nombre": "Sacha Inchi"
                        }
                    ]
                },
                {
                    "id": 13,
                    "nombre": "Plantas Aromáticas, Condimentarías y Medicinales",
                    "lineas_productivas": [
                        {
                            "id": 183,
                            "nombre": "Achicoria"
                        },
                        {
                            "id": 184,
                            "nombre": "Achiote"
                        },
                        {
                            "id": 185,
                            "nombre": "Albahaca"
                        },
                        {
                            "id": 186,
                            "nombre": "Amaranto"
                        },
                        {
                            "id": 187,
                            "nombre": "Anís"
                        },
                        {
                            "id": 188,
                            "nombre": "Caléndula"
                        },
                        {
                            "id": 189,
                            "nombre": "Canyarana"
                        },
                        {
                            "id": 190,
                            "nombre": "Cardamomo"
                        },
                        {
                            "id": 191,
                            "nombre": "Chía"
                        },
                        {
                            "id": 192,
                            "nombre": "Cimarrón"
                        },
                        {
                            "id": 193,
                            "nombre": "Cúrcuma"
                        },
                        {
                            "id": 194,
                            "nombre": "Eneldo"
                        },
                        {
                            "id": 195,
                            "nombre": "Flor De Jamaica"
                        },
                        {
                            "id": 196,
                            "nombre": "Hierbabuena"
                        },
                        {
                            "id": 197,
                            "nombre": "Jengibre"
                        },
                        {
                            "id": 198,
                            "nombre": "Laurel"
                        },
                        {
                            "id": 199,
                            "nombre": "Limonaria"
                        },
                        {
                            "id": 200,
                            "nombre": "Manzanilla"
                        },
                        {
                            "id": 201,
                            "nombre": "Menta"
                        },
                        {
                            "id": 202,
                            "nombre": "Moringa"
                        },
                        {
                            "id": 203,
                            "nombre": "Orégano"
                        },
                        {
                            "id": 204,
                            "nombre": "Perejil"
                        },
                        {
                            "id": 205,
                            "nombre": "Pimienta"
                        },
                        {
                            "id": 206,
                            "nombre": "Plantas Aromáticas"
                        },
                        {
                            "id": 207,
                            "nombre": "Plantas Aromáticas Y Medicinales"
                        },
                        {
                            "id": 208,
                            "nombre": "Plantas Medicinales"
                        },
                        {
                            "id": 209,
                            "nombre": "Poleo"
                        },
                        {
                            "id": 210,
                            "nombre": "Romero"
                        },
                        {
                            "id": 211,
                            "nombre": "Ruda"
                        },
                        {
                            "id": 212,
                            "nombre": "Ruscus"
                        },
                        {
                            "id": 213,
                            "nombre": "Sábila"
                        },
                        {
                            "id": 214,
                            "nombre": "Stevia"
                        },
                        {
                            "id": 215,
                            "nombre": "Tangarana"
                        },
                        {
                            "id": 216,
                            "nombre": "Te"
                        },
                        {
                            "id": 217,
                            "nombre": "Tomillo"
                        },
                        {
                            "id": 218,
                            "nombre": "Vainilla"
                        }
                    ]
                },
                {
                    "id": 14,
                    "nombre": "Tubérculos y Plátanos",
                    "lineas_productivas": [
                        {
                            "id": 219,
                            "nombre": "Achira"
                        },
                        {
                            "id": 220,
                            "nombre": "Arracacha"
                        },
                        {
                            "id": 221,
                            "nombre": "Batata"
                        },
                        {
                            "id": 222,
                            "nombre": "Bore"
                        },
                        {
                            "id": 223,
                            "nombre": "Chonque"
                        },
                        {
                            "id": 224,
                            "nombre": "Malanga"
                        },
                        {
                            "id": 225,
                            "nombre": "Ñame"
                        },
                        {
                            "id": 226,
                            "nombre": "Papa"
                        },
                        {
                            "id": 227,
                            "nombre": "Plátano"
                        },
                        {
                            "id": 228,
                            "nombre": "Ruba"
                        },
                        {
                            "id": 229,
                            "nombre": "Ulluco"
                        },
                        {
                            "id": 230,
                            "nombre": "Yacón"
                        },
                        {
                            "id": 231,
                            "nombre": "Yota"
                        },
                        {
                            "id": 232,
                            "nombre": "Yuca"
                        }
                    ]
                }
            ]
        },
        {
            "id": 3,
            "nombre": "Agroindustrial",
            "subsectores": [
                {
                    "id": 15,
                    "nombre": "Embalaje, Empaque Y Almacenamiento",
                    "lineas_productivas": [
                        {
                            "id": 233,
                            "nombre": "Flores"
                        }
                    ]
                },
                {
                    "id": 17,
                    "nombre": "Lácteos",
                    "lineas_productivas": [
                        {
                            "id": 234,
                            "nombre": "Helados"
                        },
                        {
                            "id": 235,
                            "nombre": "Mantequilla"
                        },
                        {
                            "id": 236,
                            "nombre": "Queso"
                        },
                        {
                            "id": 237,
                            "nombre": "Yogur"
                        }
                    ]
                },
                {
                    "id": 18,
                    "nombre": "Medicina Natural",
                    "lineas_productivas": [
                        {
                            "id": 238,
                            "nombre": "Plantas Aromáticas Y Medicinales"
                        }
                    ]
                },
                {
                    "id": 19,
                    "nombre": "Mejoramiento Tecnológico",
                    "lineas_productivas": [
                        {
                            "id": 239,
                            "nombre": "Varios"
                        }
                    ]
                },
                {
                    "id": 20,
                    "nombre": "Transformación de Frutas y Alimentos",
                    "lineas_productivas": [
                        {
                            "id": 240,
                            "nombre": "Mermeladas"
                        },
                        {
                            "id": 241,
                            "nombre": "Productos Cárnicos"
                        },
                        {
                            "id": 242,
                            "nombre": "Etc"
                        }
                    ]
                },
                {
                    "id": 21,
                    "nombre": "Transformación de Subproductos",
                    "lineas_productivas": [
                        {
                            "id": 243,
                            "nombre": "Abonos Orgánicos"
                        },
                        {
                            "id": 244,
                            "nombre": "Caña Panelera"
                        }
                    ]
                }
            ]
        },
        {
            "id": 4,
            "nombre": "Ambiental",
            "subsectores": [
                {
                    "id": 22,
                    "nombre": "Protección Conservación",
                    "lineas_productivas": [
                        {
                            "id": 245,
                            "nombre": "Mitigación y Adaptación Cambio Climático"
                        },
                        {
                            "id": 246,
                            "nombre": "Conservación"
                        },
                        {
                            "id": 247,
                            "nombre": "Corredores Biológicos"
                        }
                    ]
                },
                {
                    "id": 23,
                    "nombre": "Restauración",
                    "lineas_productivas": [
                        {
                            "id": 248,
                            "nombre": "Restauración"
                        },
                        {
                            "id": 249,
                            "nombre": "Rehabilitación"
                        },
                        {
                            "id": 250,
                            "nombre": "Recuperación"
                        }
                    ]
                },
                {
                    "id": 24,
                    "nombre": "Negocios Verdes",
                    "lineas_productivas": [
                        {
                            "id": 251,
                            "nombre": "Negocios Para la Restauración"
                        },
                        {
                            "id": 252,
                            "nombre": "Biocomercio"
                        },
                        {
                            "id": 253,
                            "nombre": "Aprovechamiento y Valoración de Residuos"
                        },
                        {
                            "id": 254,
                            "nombre": "Mercado de Carbono",
                            "sub_lineas_productivas": [
                                {
                                    "id": 255,
                                    "nombre": "Mercado Voluntario"
                                },
                                {
                                    "id": 256,
                                    "nombre": "Mercado Regulador"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": 25,
                    "nombre": "Pago por Servicios Ambientales",
                    "lineas_productivas": [
                        {
                            "id": 257,
                            "nombre": "Calidad y Regulación Hídrica"
                        },
                        {
                            "id": 258,
                            "nombre": "Conservación de la Biodiversidad"
                        },
                        {
                            "id": 259,
                            "nombre": "Reducción y Captura de Gases Efecto Invernadero"
                        },
                        {
                            "id": 260,
                            "nombre": "Servicios Culturales Espirituales y de Recreación"
                        }
                    ]
                },
                {
                    "id": 26,
                    "nombre": "Energías Alternativas",
                    "lineas_productivas": [
                        {
                            "id": 261,
                            "nombre": "Biodigestores"
                        },
                        {
                            "id": 262,
                            "nombre": "Energía Eólica"
                        },
                        {
                            "id": 263,
                            "nombre": "Energía Hidroeléctrica"
                        },
                        {
                            "id": 264,
                            "nombre": "Energía Solar"
                        },
                        {
                            "id": 265,
                            "nombre": "Estufas Eficientes"
                        }
                    ]
                }
            ]
        },
        {
            "id": 5,
            "nombre": "Forestal",
            "subsectores": [
                {
                    "id": 27,
                    "nombre": "Bosque Natural",
                    "lineas_productivas": [
                        {
                            "id": 266,
                            "nombre": "Aprovechamiento de Recursos Maderables del Bosque - Manejo Forestal Sostenible"
                        },
                        {
                            "id": 267,
                            "nombre": "Aprovechamiento de Recursos No Maderables del Bosque"
                        }
                    ]
                },
                {
                    "id": 28,
                    "nombre": "Plantaciones",
                    "lineas_productivas": [
                        {
                            "id": 268,
                            "nombre": "Reforestación Comercial - Maderas"
                        },
                        {
                            "id": 269,
                            "nombre": "Caucho"
                        }
                    ]
                },
                {
                    "id": 29,
                    "nombre": "Agroforestería",
                    "lineas_productivas": [
                        {
                            "id": 270,
                            "nombre": "Sistemas Agroforestales"
                        },
                        {
                            "id": 271,
                            "nombre": "Sistemas Silvopastoriles"
                        }
                    ]
                }
            ]
        },
        {
            "id": 6,
            "nombre": "Ganadero",
            "subsectores": [
                {
                    "id": 30,
                    "nombre": "Bovinos",
                    "lineas_productivas": [
                        {
                            "id": 272,
                            "nombre": "Carne"
                        },
                        {
                            "id": 273,
                            "nombre": "Doble Propósito"
                        },
                        {
                            "id": 274,
                            "nombre": "Leche"
                        }
                    ]
                },
                {
                    "id": 31,
                    "nombre": "Ovino - Caprino",
                    "lineas_productivas": [
                        {
                            "id": 275,
                            "nombre": "Cabras"
                        },
                        {
                            "id": 276,
                            "nombre": "Cabras - Ovejas"
                        },
                        {
                            "id": 277,
                            "nombre": "Ovejas"
                        }
                    ]
                }
            ]
        },
        {
            "id": 7,
            "nombre": "Pecuario",
            "subsectores": [
                
                    {
                        "id": 32,
                        "nombre": "Apicultura",
                        "lineas_productivas": [
                            {
                                "id": 278,
                                "nombre": "Cera"
                            },
                            {
                                "id": 279,
                                "nombre": "Jalea Real"
                            },
                            {
                                "id": 280,
                                "nombre": "Miel"
                            },
                            {
                                "id": 281,
                                "nombre": "Polen"
                            },
                            {
                                "id": 282,
                                "nombre": "Propóleo"
                            },
                            {
                                "id": 283,
                                "nombre": "Veneno"
                            }
                        ]
                    },
                    {
                        "id": 33,
                        "nombre": "Avicultura",
                        "lineas_productivas": [
                            {
                                "id": 284,
                                "nombre": "Avestruz"
                            },
                            {
                                "id": 285,
                                "nombre": "Codorniz"
                            },
                            {
                                "id": 286,
                                "nombre": "Gallinas"
                            },
                            {
                                "id": 287,
                                "nombre": "Pato"
                            },
                            {
                                "id": 288,
                                "nombre": "Pavo"
                            },
                            {
                                "id": 289,
                                "nombre": "Pollos de Engorde"
                            }
                        ]
                    },
                    {
                        "id": 34,
                        "nombre": "Especies Silvestres",
                        "lineas_productivas": [
                            {
                                "id": 290,
                                "nombre": "Babillas"
                            },
                            {
                                "id": 291,
                                "nombre": "Pavitas"
                            }
                        ]
                    },
                    {
                        "id": 35,
                        "nombre": "Porcicultura",
                        "lineas_productivas": [
                            {
                                "id": 292,
                                "nombre": "Cerdos"
                            }
                        ]
                    }
                ]
            
        },
        {
            "id": 8,
            "nombre": "No agropecuario",
            "subsectores": [
                
                    {
                        "id": 36,
                        "nombre": "Comercio",
                        "lineas_productivas": [
                            {
                                "id": 293,
                                "nombre": "Carnicerías"
                            },
                            {
                                "id": 294,
                                "nombre": "Farmacias"
                            },
                            {
                                "id": 295,
                                "nombre": "Mercados Campesinos Y Tiendas Carreteables"
                            },
                            {
                                "id": 296,
                                "nombre": "Otros*"
                            },
                            {
                                "id": 297,
                                "nombre": "Panadería"
                            },
                            {
                                "id": 298,
                                "nombre": "Tiendas De Abarrotes"
                            }
                        ]
                    },
                    {
                        "id": 37,
                        "nombre": "Manufacturas",
                        "lineas_productivas": [
                            {
                                "id": 299,
                                "nombre": "Artesanías"
                            },
                            {
                                "id": 300,
                                "nombre": "Carpintería Y Ebanistería"
                            },
                            {
                                "id": 301,
                                "nombre": "Confecciones"
                            },
                            {
                                "id": 302,
                                "nombre": "Joyería"
                            },
                            {
                                "id": 303,
                                "nombre": "Marquetería"
                            },
                            {
                                "id": 304,
                                "nombre": "Marroquinería"
                            },
                            {
                                "id": 305,
                                "nombre": "Minería"
                            },
                            {
                                "id": 306,
                                "nombre": "Orfebrería"
                            },
                            {
                                "id": 307,
                                "nombre": "Otros*"
                            },
                            {
                                "id": 308,
                                "nombre": "Plantas De Reciclaje"
                            },
                            {
                                "id": 309,
                                "nombre": "Plantas Embotelladoras"
                            },
                            {
                                "id": 310,
                                "nombre": "Reciclaje"
                            },
                            {
                                "id": 311,
                                "nombre": "Talabartería"
                            },
                            {
                                "id": 312,
                                "nombre": "Tallado De Madera"
                            },
                            {
                                "id": 313,
                                "nombre": "Zapatería"
                            }
                        ]
                    },
                    {
                        "id": 38,
                        "nombre": "Servicios",
                        "lineas_productivas": [
                            {
                                "id": 314,
                                "nombre": "Artes Y Diseños"
                            },
                            {
                                "id": 315,
                                "nombre": "Café Internet"
                            },
                            {
                                "id": 316,
                                "nombre": "Inclusión Financiera"
                            },
                            {
                                "id": 317,
                                "nombre": "Investigación"
                            },
                            {
                                "id": 318,
                                "nombre": "Otros*"
                            },
                            {
                                "id": 319,
                                "nombre": "Proyectos Artísticos"
                            },
                            {
                                "id": 320,
                                "nombre": "Restaurantes"
                            },
                            {
                                "id": 321,
                                "nombre": "Salón De Belleza"
                            },
                            {
                                "id": 322,
                                "nombre": "Taller De Mecánica"
                            },
                            {
                                "id": 323,
                                "nombre": "Tapicería"
                            },
                            {
                                "id": 324,
                                "nombre": "Transporte"
                            },
                            {
                                "id": 325,
                                "nombre": "Turismo"
                            }
                        ]
                    }
            ]
        },

        {
            "id": 9,
            "nombre": "Residuos solidos",
            "subsectores": [
                
                    {
                        "id": 39,
                        "nombre": "Residuos solidos",
                        "lineas_productivas": [
                            {
                                "id": 326,
                                "nombre": "Aprovechamiento de residuos solidos"
                            },
                          
                        ]
                    }
            ]
       
        }

    ]
}