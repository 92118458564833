import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl+`/api/`;

// Servicios para obtener datos
export const getDepartamentos = async () => {
    const response = await axios.get(`${baseUrl}/cursoFortalecimientodepartamentos`);
    return response.data;
  };
  
  export const getMunicipios = async () => {
    const response = await axios.get(`${baseUrl}/cursoFortalecimientomunicipios`);
    return response.data;
  };
  
  export const getLugares = async () => {
    const response = await axios.get(`${baseUrl}/cursoFortalecimientolugares`);
    return response.data;
  };
  
  export const getEtnias = async () => {
    const response = await axios.get(`${baseUrl}/cursoFortalecimientoetnias`);
    return response.data;
  };
  
  export const getRangosEdad = async () => {
    const response = await axios.get(`${baseUrl}/cursoFortalecimientorangos-edad`);
    return response.data;
  };
  
  export const getParticipaciones = async () => {
    const response = await axios.get(`${baseUrl}/cursoFortalecimientoparticipacion`);
    return response.data;
  };
  

  export const getAsistencia = async () => {
    const response = await axios.get(`${baseUrl}/cursoFortalecimientoasistencia`);
    return response.data;
  };