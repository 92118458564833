import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Form, Input, Button, Space, Modal, Tooltip } from 'antd';
import { getAllAsistencias, createAsistencia, updateAsistencia, deleteAsistencia } from '../../services/cursoFortalecimiento/cursoFortalecimientoAsis';
import { EditOutlined, DeleteOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
const { confirm } = Modal;

const Asistencia = ({ onAsistenciaCreated }) => {

  const [asistencias, setAsistencias] = useState([]);
  const [nombre, setNombre] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editOid, setEditOid] = useState(null);
  const [form] = Form.useForm();


  useEffect(() => {
    fetchAsistencias();
  }, []);


  const fetchAsistencias = async () => {
    try {
      const data = await getAllAsistencias();
      setAsistencias(data);
    } catch (error) {
      console.error('Error al obtener las asistencias:', error);
    }
  };

  // Función para manejar el envío del formulario
  const handleSubmit = async () => {
    if (editMode) {
      await updateAsistencia(editOid, nombre);
    } else {
      await createAsistencia(nombre);
    }
    setNombre('');
    setEditMode(false);
    setEditOid(null);
    form.resetFields();
    fetchAsistencias();
    onAsistenciaCreated();
  };

  // Función para iniciar la edición de una asistencia
  const handleEdit = (record) => {
    setNombre(record.nombre);
    setEditMode(true);
    setEditOid(record.oid);
    form.setFieldsValue({ nombre: record.nombre });
  };

  // Función para cancelar la edición
  const handleCancelEdit = () => {
    setNombre('');
    setEditMode(false);
    setEditOid(null);
    form.resetFields();
  };

  // Función para manejar la eliminación de una asistencia
  const handleDelete = async (oid) => {
    confirm({
      title: '¿Estás seguro de que deseas eliminar esta asistencia?',
      onOk: async () => {
        await deleteAsistencia(oid);
        fetchAsistencias();
        onAsistenciaCreated();
      },
    });
  };


  const columns = [
    {
      title: 'Nombre de la sesión',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Acciones',
      key: 'acciones',
      align: 'center',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Editar sesión">
            <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => handleDelete(record.oid)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Row gutter={16}>
        <Col span={24}>
          <Form form={form} layout="inline" onFinish={handleSubmit}>
            <Form.Item
              name="nombre"
              style={{ width: '55%' }}
              rules={[{ required: true, message: 'Por favor ingresa un nombre' }]}
            >
              <Input
                placeholder="Nombre de la sesión"	
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={editMode ? <EditOutlined /> : <PlusOutlined />}>
                {editMode ? 'Actualizar' : 'Registrar'}
              </Button>
            </Form.Item>
            {editMode && (
              <Form.Item>
                <Button onClick={handleCancelEdit}>Cancelar</Button>
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            style={{ marginTop: 20 }}
            columns={columns}
            dataSource={asistencias}
            rowKey="oid"
            pagination={{ pageSize: 5 }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Asistencia;
