import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { EnvironmentOutlined, DashboardOutlined, DownloadOutlined } from '@ant-design/icons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ColombiaMapComponent from '../Maps/MapsInicial';
import DashboardInicial from '../DashboardInicial/DashboardInicial';

function Inicio() {
    const [selectedComponent, setSelectedComponent] = useState('map');

    const exportToPDF = async () => {
        const element = document.getElementById('exportable-section');
        if (!element) return;

        // Captura el contenido en formato canvas
        const canvas = await html2canvas(element, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        
        // Crear PDF con jsPDF
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('exported-component.pdf');
    };

    return (
        <>
            <div style={{ textAlign: 'center', padding: '10px' }}>
                <Space style={{ marginBottom: '20px' }}>
                    <Button
                        type="primary"
                        icon={<EnvironmentOutlined />}
                        onClick={() => setSelectedComponent('map')}
                        style={{ paddingTop: '2px' }}
                    >
                        Mapa
                    </Button>
                    <Button
                        type="primary"
                        icon={<DashboardOutlined />}
                        onClick={() => setSelectedComponent('dashboard')}
                        style={{ paddingTop: '2px' }}
                    >
                        Dashboard
                    </Button>
                    <Button
                        type="default"
                        icon={<DownloadOutlined />}
                        onClick={exportToPDF}
                        style={{ paddingTop: '2px' }}
                    >
                        Exportar a PDF
                    </Button>
                </Space>
                <div id="exportable-section">
                    {selectedComponent === 'map' && <ColombiaMapComponent />}
                    {selectedComponent === 'dashboard' && <DashboardInicial />}
                </div>
            </div>
        </>
    );
}

export default Inicio;
