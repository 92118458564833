import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/planesPedagogicosSoportes/';
const getendpoint = '/api/planesPedagogicosSoportes/';
const deteendpoint = '/api/planesPedagogicosSoportes/';
const downloadendpoint = '/api/planesPedagogicosSoportes/download/';
const viewendpoint = '/api/planesPedagogicosSoportes/download/';

const guardarSoportePP = async (formData) => {
  try {
    const response = await axios.post(`${baseUrl}${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al guardar el soporte', error);
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error al guardar el soporte',
    };
  }
};

const getSoportesPP = async (oid) => {
  try {
    const response = await axios.get(`${baseUrl}${getendpoint}${oid}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los soportes', error);
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error al obtener los soportes',
    };
  }
};

const deleteSoportePP = async (soporteId) => {
  try {
    const response = await axios.delete(`${baseUrl}${deteendpoint}${soporteId}`, {});
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al eliminar el soporte', error);
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error al eliminar el soporte',
    };
  }
};

const downloadSupportPP = async (soporteId, nombreSoporte) => {
  try {
      const response = await axios.get(`${baseUrl}${downloadendpoint}${soporteId}`, {
          responseType: 'blob', // Especifica que la respuesta es un archivo
      });

      const contentDisposition = response.headers['content-disposition'];
      let fileName = nombreSoporte;
      if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
          }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return {
          status: response.status,
          message: 'File downloaded successfully',
      };
  } catch (error) {
      console.error('Error downloading the file:', error);
      return {
          status: error.response ? error.response.status : 500,
          message: 'Error downloading the file',
      };
  }
};

const viewSupportPP = async (soporteId) => {
  try {

    const response = await axios.get(`${baseUrl}${viewendpoint}${soporteId}`, {
      responseType: 'blob', // Especifica que la respuesta es un blob (archivo binario)
    });
    let contentType = response.headers['content-type'];
    let fileType;

    // Determina el tipo de archivo por el content-type de la respuesta
    if (contentType.includes('image')) {
      fileType = 'image/png'; // Tipo MIME para imágenes
    } else if (contentType.includes('pdf')) {
      fileType = 'application/pdf'; // Tipo MIME para PDF
    } else {
      throw new Error('Tipo de archivo no soportado');
    }
    // Crea un objeto URL para el blob recibido
    const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
    // Abre el archivo en una nueva ventana
    window.open(url);
    return {
      status: response.status,
      message: 'Archivo abierto correctamente',
    };
  } catch (error) {
    console.error('Error al abrir el archivo:', error);
    return {
      status: error.response ? error.response.status : 500,
      message: 'Error al abrir el archivo',
    };
  }
};

export { guardarSoportePP, getSoportesPP, deleteSoportePP, downloadSupportPP, viewSupportPP};
