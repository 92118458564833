import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const documentEndpoint = '/api/poaDocumentosMedios';

// Función para obtener datos de POA enviando el 'path' en el cuerpo de la solicitud
export const fetchPoaDataByPath = async (path) => {
  try {
    // Log para verificar el valor del 'path' antes de la solicitud

    // Realiza una solicitud POST enviando el 'path' en el cuerpo
    const response = await axios.post(`${baseUrl}${documentEndpoint}`, { path });

    return response.data; // Retorna los documentos obtenidos
  } catch (error) {
    console.error('Error fetching POA data by path:', error);
    throw error;
  }
};
