import React from 'react';
import * as ExcelJS from 'exceljs';
import { Button} from 'antd';
import { DownloadOutlined} from '@ant-design/icons';


const ExportToExcelAcuerdoComercial = ({ data }) => {
    const handleExport = () => {
        const fileExtension = '.xlsx';
        const fileName = 'Acuerdos_comerciales_UTF_COL_160' + fileExtension;

        // Crear un nuevo libro de trabajo
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Acuerdos_comerciales');

        // Encabezado título
        worksheet.mergeCells('A1:M1'); // Titulo
        worksheet.getCell('A1').value = 'LISTA DE ACUERDOS COMERCIALES';

        // Ajustar el formato específico para la fila 1
        const row1 = worksheet.getRow(1);
        row1.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = { size: 16, bold: true }; // Aplicar negrita y tamaño de fuente 16
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true, // Habilitar ajuste de texto
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "5b9bd5" }, // Color de fondo
            };
        });

        const datos = [
            'ID',
            'Departamento',
            'Municipio',
            'Fecha del acuerdo',
            'Tipo de acuerdo',
            'Alcance del acuerdo',
            'Producto comercial',
            'Comprador',
            'Nombre del acuerdo',
            'Codigo proyecto',
            'Nombre proyecto',
            'Organizaciones',
            'Integrantes'
        ];

        // Configurar el ancho fijo de las columnas
        const columnWidth = 30; // Ajusta el ancho según sea necesario
        worksheet.columns = datos.map(() => ({ width: columnWidth }));

        // Agregar fila de encabezado
        worksheet.addRow(datos);

        // Ajustar el formato específico para la fila 2
        const row2 = worksheet.getRow(2);
        row2.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = {
                size: 14,
                bold: true
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true, // Habilitar ajuste de texto
            };
        });

        // Mapear y seleccionar todos los campos de participantes
        const dataToExport = data.map(acm => [
            acm.oid || '', // Si es null o undefined, reemplaza con ''
            acm.departamento || '',
            acm.municipios || '',
            acm.detalle_acuerdo.fecha_acuerdo || '',
            acm.detalle_acuerdo.tipo_acuerdo || '',
            acm.detalle_acuerdo.tipo_alcance || '',
            acm.detalle_acuerdo.producto_comercial || '',
            acm.detalle_acuerdo.comprador || '',
            acm.detalle_acuerdo.nombre_acuerdo || '',
            acm.detalle_acuerdo.codigo_proyecto|| '',
            acm.detalle_acuerdo.nombre_proyecto || '',
            acm.organizaciones || '',
            acm.participantes || ''
        ]);

        // Agregar los datos a la hoja
        worksheet.addRows(dataToExport);

        // Aplicar color de fondo a todas las celdas desde A hasta L en todas las filas a partir de la fila 2
        const numRows = worksheet.rowCount; // Obtén el número total de filas
        for (let rowNum = 2; rowNum <= numRows; rowNum++) {
            const row = worksheet.getRow(rowNum);
            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                cell.alignment = {
                    vertical: 'middle', // Centrar verticalmente
                    wrapText: true // Ajustar texto
                };
            });
        }

        // Ajustar el formato de todas las celdas
        worksheet.eachRow(
            { includeEmpty: true },
            function (row, rowNumber) {
                row.eachCell(
                    function (cell, colNumber) {
                        cell.border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        }; // Bordes
                    }
                );
            }
        );

        // Guardar el archivo Excel
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };

    return (
        <Button
        type="danger"
        className="btn btn-primary me-2"
        size="large"
        onClick={handleExport}
        icon={<DownloadOutlined />}
    >
        Excel
    </Button>
    );
};

export default ExportToExcelAcuerdoComercial;
