import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const baseUrl = config.baseUrl;
const orgEndpointInsert = '/api/insertEdah';


const api = axios.create({
    baseURL: config.baseUrl,
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const insertEdah = async (detalle) => {
    try {
      const response = await axios.post(`${baseUrl}${orgEndpointInsert}`, detalle, {
        headers: {
            'Content-Type': 'application/json',  // Encabezado JSON para enviar datos correctamente
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error al crear la encuesta:', error);
      throw error;
    }
  };
  export default insertEdah;