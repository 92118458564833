import React, { useEffect } from 'react';
import * as ExcelJS from 'exceljs';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';


// Función de exportación a Excel
const IpptaExport = ({ data }) => {

    useEffect(() => {
        if (data) {
            handleExport(); // Llama a la función de exportación cuando hay datos
        }
    }, [data]); // Dependencia en 'data'


    const handleExport = () => {
        const fileExtension = '.xlsx';
        const fileName = 'IPPTA' + fileExtension;

        // Crear un nuevo libro de trabajo
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Resultado NTA');
        // Encabezado de múltiples niveles

        // Función auxiliar para convertir columna a número
        const columnToNumber = (column) => {
            let result = 0;
            for (let i = 0; i < column.length; i++) {
                result *= 26;
                result += column.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
            }
            return result;
        };

        // Función auxiliar para convertir número a columna
        const numberToColumn = (number) => {
            let result = '';
            while (number > 0) {
                let remainder = (number - 1) % 26;
                result = String.fromCharCode(65 + remainder) + result;
                number = Math.floor((number - 1) / 26);
            }
            return result;
        };

        // Función para aplicar color de fondo a un rango de celdas
        const applyBackgroundColor = (startCol, endCol, rowNum, color) => {
            const startColNum = columnToNumber(startCol);
            const endColNum = columnToNumber(endCol);

            for (let colNum = startColNum; colNum <= endColNum; colNum++) {
                const currentCol = numberToColumn(colNum);
                const cellRef = `${currentCol}${rowNum}`;
                worksheet.getCell(cellRef).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: color }
                };
            }
        };

        // Encabezado titulo
        worksheet.mergeCells('A1:AK1'); // Titulo
        worksheet.getCell('A1').value = 'LISTA DE USUARIOS IPPTA';


        // Ajustar el formato específico para la fila 1
        var row1 = worksheet.getRow(1);
        row1.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = {
                size: 16,
                bold: true,
                color: { argb: "FFFFFF" } // Color de la letra blanco
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "042956" }, // Color de fondo
            };
        });




        // Encabezado de múltiples niveles
        worksheet.mergeCells('A2:O2'); // DATOS BÁSICOS
        worksheet.mergeCells('P2:V2'); // DIM AMBIENTAL
        worksheet.mergeCells('W2:AB2'); // DIM SOCIOECONOMICO
        worksheet.mergeCells('AC2:AJ2'); // DIM PRODUCTIVA
        // Asignación de valores a las celdas combinadas
        worksheet.getCell('A2').value = 'DATOS BÁSICOS';
        worksheet.getCell('P2').value = 'DIMENCIÓN AMBIENTAL';
        worksheet.getCell('W2').value = 'DIMENCIÓN SOCIOECONOMICO';
        worksheet.getCell('AC2').value = 'DIMENCIÓN PRODUCTIVA';
        // Asignamos colores a las celdas combinadas
        worksheet.getCell('A2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '849ED0' }
        };

        worksheet.getCell('P2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DFB663' }
        };

        worksheet.getCell('W2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '2E75B5' }
        };

        worksheet.getCell('AC2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'BF9000' }
        };

        worksheet.getCell('AK2').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '042956' }
        };

        // Ajustar el formato específico para la fila 2
        var row2 = worksheet.getRow(2);
        row2.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = {
                size: 14,
                bold: true,
                color: { argb: 'FFFFFFFF' }  // Añadimos esta línea para hacer el texto blanco
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });

        const datos =
            [//DATOS BASICOS
                'Proyecto',
                'Medición',
                'Identificación',
                'Participante del proyecto',
                'Celular',
                'Nombre del familiar 1',
                'Nombre del familiar 2',
                'Nombre del familiar 3',
                'Departamento',
                'Municipio',
                'Vereda',
                'Entidad',
                'Predio',
                'Super visor del proyecto',
                'Profesional responsable',
                //DIM AMBIENTAL
                '1. Protección de fuentes de agua.',
                '2. Manejo del agua para consumo humano.',
                '3. Ahorro de agua en el hogar y sistemas productivos.',
                '4. Protección del bosque en la finca.',
                '5. Conectividad entre paisaje y agroecosistema.',
                '6. Manejo de residuos sólidos.',
                'Nivel de transición Agroecológica',
                //DIM Socioeconomico
                '7. Generación de ingresos e índice de autoempleo.',
                '8. Organización para la comercialización de productos.',
                '9. Autosuficiencia alimentaria familiar.',
                '10. Participación de la familia en procesos comunitarios.',
                '11. Gestión del conocimiento agroecológico.',
                'Nivel de transición Agroecológica',
                //Dimensión Productiva
                '12. Diversidad de cultivos',
                '13. Diversidad de animales (incluyendo peces)',
                '14. Integración entre animales y cultivos',
                '15. Manejo del suelo',
                '16. Bienestar animal (si aplica) a nivel de finca',
                '17. Producción de insumos en la finca',
                '18. Producir y custodiar semillas en la finca',
                'Nivel de transición Agroecológica',
            ];

        const headerRow = worksheet.addRow(datos);
        // Ajustar el ancho de las columnas automáticamente
        headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const maxLength = cell.value ? cell.value.toString().length : 0; // Obtener la longitud del contenido de la celda
            const column = worksheet.getColumn(colNumber); // Obtener la columna correspondiente
            column.width = Math.max(column.width || 0, maxLength + 5);  // Ajustar el ancho, añadiendo un margen de 2
        });

        const dataToExport = data.map(part => [
            part.proyecto, // Proyecto
            part.estado_medicion, // Medición
            part.identificacion, // Identificación
            part.participante, // Participante del proyecto
            part.celular, // Celular
            part.nombreFamiliar1, // Nombre del familiar 1
            part.nombreFamiliar2, // Nombre del familiar 2
            part.nombreFamiliar3, // Nombre del familiar 3
            part.departamento, // Departamento
            part.municipio, // Municipio
            part.vereda, // Vereda
            part.entidadd, // Entidad
            part.predio, // Predio
            part.supervisor_proyecto, // Supervisor del proyecto
            part.profesional_responsable, // Profesional responsable
            // Dimensional Ambiental
            part.ambiental_indicador1, // 1. Protección de fuentes de agua
            part.ambiental_indicador2, // 2. Manejo del agua para consumo humano
            part.ambiental_indicador3, // 3. Ahorro de agua en el hogar y sistemas productivos
            part.ambiental_indicador4, // 4. Protección del bosque en la finca
            part.ambiental_indicador5, // 5. Conectividad entre paisaje y agroecosistema
            part.ambiental_indicador6, // 6. Manejo de residuos sólidos
            // Nivel de transición Agroecológica
            part.porcentajeAmbiental, // Nivel de transición Agroecológica
            // Dimensional Socioeconómico
            part.socioeconomico_indicador1, // 7. Generación de ingresos e índice de autoempleo
            part.socioeconomico_indicador2, // 8. Organización para la comercialización de productos
            part.socioeconomico_indicador3, // 9. Autosuficiencia alimentaria familiar
            part.socioeconomico_indicador4, // 10. Participación de la familia en procesos comunitarios
            part.socioeconomico_indicador5, // 11. Gestión del conocimiento agroecológico
            // Nivel de transición Agroecológica
            part.porcentajeSocioeconomico, // Nivel de transición Agroecológica
            // Dimensional Productiva
            part.productivo_indicador1, // 12. Diversidad de cultivos
            part.productivo_indicador2, // 13. Diversidad de animales (incluyendo peces)
            part.productivo_indicador3, // 14. Integración entre animales y cultivos
            part.productivo_indicador4, // 15. Manejo del suelo
            part.productivo_indicador5, // 16. Bienestar animal (si aplica) a nivel de finca
            part.productivo_indicador6, // 17. Producción de insumos en la finca
            part.productivo_indicador7, // 18. Producir y custodiar semillas en la finca
            // Nivel de transición Agroecológica
            part.porcentajeProductivo, // Nivel de transición Agroecológica
            part.porcentajeTotal
        ]);

        // Agregar los datos a la hoja
        worksheet.addRows(dataToExport);

        // Aplicar colores a todos los rangos
        applyBackgroundColor('A', 'O', '3', 'FFD7E2F4');
        applyBackgroundColor('P', 'V', '3', 'FFE3EFD9');
        applyBackgroundColor('W', 'AB', '3', 'DEEAF6');
        applyBackgroundColor('AC', 'AJ', '3', 'FFFEF2CB');
        applyBackgroundColor('AK', 'AK', '3', '042956');



        // Ajustar el formato específico para la fila 3
        var row3 = worksheet.getRow(3);
        row3.eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.font = { size: 12, bold: true }; // Aplicar negrita y tamaño de fuente 16
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
                color: { argb: 'FFFFFFFF' }
            };
        });

        // Ajustar el formato de todas las celdas
        worksheet.eachRow(
            { includeEmpty: true },
            function (row, rowNumber) {
                row.eachCell(
                    { includeEmpty: true },
                    function (cell, colNumber) {
                        cell.border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                        }; // Bordes
                    }
                );
            }
        );

        const cellAK3 = worksheet.getCell('AK3');
        // Configura el estilo de la celda
        cellAK3.font = {
            color: { argb: 'FFFFFFFF' },
            bold: true
        };
        cellAK3.alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };

        worksheet.mergeCells('AK2:AK3');
        const cellAK2 = worksheet.getCell('AK2'); 
        cellAK2.font = {
            color: { argb: 'FFFFFFFF' }, 
            bold: true
        };

        cellAK2.alignment = {
            horizontal: 'center',
            vertical: 'middle' 
        };
        cellAK2.value = 'Medición General'; 
        worksheet.getColumn('AK').width = 20;
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };
    return null;
};

export default IpptaExport;
