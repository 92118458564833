import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpointProyectos = '/api/proyectos-ippta';
const endpointMunicipiosDepartamentos = '/api/municipios-departamentos-ippta';
const getIpptaUser = '/api/obtener-ippta/';
const getEntidadServices = '/api/entidad-ippta';
// Función para obtener proyectos
const getProyectos = async () => {
  try {
    const response = await axios.get(`${baseUrl}${endpointProyectos}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener proyectos:', error);
    throw error; // Lanza el error para ser manejado en el componente React
  }
};

// Función para obtener municipios y departamentos
const getMunicipiosDepartamentos = async () => {
  try {
    const response = await axios.get(`${baseUrl}${endpointMunicipiosDepartamentos}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener municipios y departamentos:', error);
    throw error; // Lanza el error para ser manejado en el componente React
  }
};


const fetchParticipantData = async (identificacion, medicion) => {
  try {
    const response = await fetch(`${baseUrl}${getIpptaUser}${identificacion}/${medicion}`); // Ajusta la URL según tu API
    if (!response.ok) {
      throw new Error("No se encontró el participante.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al buscar el participante:", error);
    return null;
  }
};

const getEntidad = async () => {
  try {
    const response = await fetch(`${baseUrl}${getEntidadServices}`); // GET request
    if (!response.ok) {
      throw new Error("No se encontró entidades.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al buscar entidades:", error);
    return null;
  }
};

// Exportar las funciones
export { getProyectos, getMunicipiosDepartamentos, fetchParticipantData, getEntidad};
