// src/data/departments.js "user_email":"aracelis.canasbadillo@fao.org"
const departmentListForm160 = () => {
    return [
      { id: '86', name: 'Putumayo' },
      { id: '27', name: 'Chocó' },
      { id: '52', name: 'Nariño' },
      { id: '81', name: 'Arauca' },
      { id: '95', name: 'Guaviare' },
      { id: '50', name: 'Meta' },
      { id: '18', name: 'Caquetá' },
      { id: '5', name: 'Antioquia' },
      { id: '13', name: 'Bolívar' },
      { id: '19', name: 'Cauca' },
      { id: '20', name: 'Cesar' },
      { id: '23', name: 'Córdoba' },
      { id: '44', name: 'La Guajira' },
      { id: '47', name: 'Magdalena' },
      { id: '54', name: 'Norte de Santander' },
      { id: '70', name: 'Sucre' },
    ];
  };

  // data.js

// Función que devuelve un texto asociado a los cereales según el departamento seleccionado.
export function listCereales(department, text = '') {
  const listGroup = {
    'Putumayo': 'Maíz seco, arroz, cebada, trigo, avena y sus derivados (pan, arepa, bollos, buñuelos, espaguetis o fideos, galletas de sal), mazamorra a base de arroz, árbol del pan',
    'Chocó': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, pan ayemado, arepa, bollos, buñuelos, galletas de sal, masitas, pandeyuca)',
    'Nariño': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, pan ayemado, arepa, bollos, buñuelos, galletas de sal, masitas, pandeyuca)',
    'Arauca': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Guaviare': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Meta': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Caquetá': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Antioquia': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Bolívar': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Cauca': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Cesar': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Córdoba': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'La Guajira': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Magdalena': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Norte de Santander': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
    'Sucre': 'Maíz, arroz, pan, fideos, pasta, trigo, avena o derivados (pan, arepa, bollos, buñuelos, galletas de sal)',
  };

  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}


// Función que devuelve un texto asociado a las raíces según el departamento seleccionado.
export function listRaices(department, text = '') {
  const listGroup = {
    'Putumayo': 'Papa parda o pastusa, yota, ñame blanco, yuca blanca, plátano verde, guineo, banano verde, chiro verde, popocho (pelipita), papachina, bore, ulluco u olloco, mafafa y sus derivados',
    'Chocó': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde, papa china, papa cun',
    'Nariño': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde, papa china, papa cun',
    'Arauca': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Guaviare': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Meta': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Caquetá': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Antioquia': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Bolívar': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Cauca': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Cesar': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Córdoba': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'La Guajira': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Magdalena': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Norte de Santander': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
    'Sucre': 'Papa, ñame blanco, yuca blanca, plátano verde, guineo, banano verde',
  };

  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}


export function listTuberculos( department, text) {
  const listGroup = {
    'Putumayo': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, ají dulce, pimentón, calabaza, batata amarilla, chilma',
    'Chocó': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, batata amarilla',
    'Nariño': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, batata amarilla',
    'Arauca': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla, camote',
    'Guaviare': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Meta': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Caquetá': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Antioquia': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Bolívar': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Cauca': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Cesar': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Córdoba': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'La Guajira': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Magdalena': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Norte de Santander': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
    'Sucre': 'Zapallo, yuca amarilla, papa criolla, arracacha, zanahoria, calabaza, pimentón, calabaza, batata amarilla',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}



export function listVerdurasHojaVerde(  department,  text  ) {
  const listGroup = {  
    'Putumayo': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Chocó': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga, apio, pepino de rellenar, etc. Depende de la porción: Cilantro cimarron, chiraran, albahaca morada o blanca, oregano,poleo, hoja de cebolla verde, perejil',
    'Nariño': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga, apio, pepino de rellenar, etc. Depende de la porción: Cilantro cimarron, chiraran, albahaca morada o blanca, oregano,poleo, hoja de cebolla verde, perejil',
    'Arauca': 'Espinacas, acelga, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Guaviare': 'Espinacas, acelga, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc',
    'Meta': 'Espinacas, acelga, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc',
    'Caquetá': 'Espinacas, acelga, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc',
    'Antioquia': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Bolívar': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Cauca': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Cesar': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Córdoba': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'La Guajira': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Magdalena': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Norte de Santander': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
    'Sucre': 'Espinacas, acelga, coles, habichuela, repollo, lechuga, hojas de cebolla larga (dependiendo la porción), apio, perejil (depende las porciones), pepino de rellenar, etc. ',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}



export function listOtrasVerduras(  department, text  ) {
  const listGroup = {
    'Putumayo': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Chocó': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Nariño': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Arauca': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona, remolacha',
    'Guaviare': 'Tomate, apio, pepino, habichuela,espincaca, lechuga crespa, cebolla larga, cilantro, cilantron, albeja verde, frijol verde, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Meta': 'Tomate, apio, pepino, habichuela,espincaca, lechuga crespa, cebolla larga, cilantro, cilantron, albeja verde, frijol verde, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Caquetá': 'Tomate, apio, pepino, habichuela,espincaca, lechuga crespa, cebolla larga, cilantro, cilantron, albeja verde, frijol verde, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Antioquia': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Bolívar': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Cauca': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Cesar': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Córdoba': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'La Guajira': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Magdalena': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Norte de Santander': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
    'Sucre': 'Tomate, apio, pepino, berenjena, maíz tierno (mazorca), rábano, lechuga verde claro (hojas abiertas), remolacha, repollo morado, lechuga morada, cebolla cabezona. ',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}



export function listFrutasVitaminaA(  department,  text = '') {
  const listGroup = {
    'Putumayo': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Chocó': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá,  níspero, melón, guayaba agria, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Nariño': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá,  níspero, melón, guayaba agria, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Arauca': '"Mango maduro, zapote, madroño, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Guaviare': '"Mango maduro, chontaduro, zapote, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Meta': '"Mango maduro, chontaduro, zapote, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Caquetá': '"Mango maduro, chontaduro, zapote, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Antioquia': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Bolívar': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Cauca': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Cesar': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Córdoba': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'La Guajira': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Magdalena': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Norte de Santander': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
    'Sucre': '"Mango maduro, chontaduro, zapote, madroño, almirajo, papayuela, tomate de árbol, lulo, curuba, maracuyá, arazá, níspero, melón, papaya madura, plátano maduro y jugos hechos al 100% con estas frutas (jugos concentrados). ',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}

export function listOtrasFrutas(  department,  text = '' ) {
  const listGroup = {
    'Putumayo': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Chocó': 'Bananos y chiro (maduros), guayaba, limón,marañon, piña, naranja, chirimoya,naidi, guanábana, carambolo, borojó, manzana, pera, feijoa, badea, caimito, milpesos, coco, mora, fresa, granadilla, aguacate, chulupa, guama, uvas, bacao, uva caimarona, kiwi, pitahaya, arbol del pan, corozo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Nariño': 'Bananos y chiro (maduros), guayaba, limón,marañon, piña, naranja, chirimoya,naidi, guanábana, carambolo, borojó, manzana, pera, feijoa, badea, caimito, milpesos, coco, mora, fresa, granadilla, aguacate, chulupa, guama, uvas, bacao, uva caimarona, kiwi, pitahaya, arbol del pan, corozo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Arauca': 'Bananos, guayaba, limón, piña, naranja,  guanábana, carambolo, borojó, manzana, pera,  mora, fresa, granadilla, uvas, kiwi, pitahaya, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Guaviare': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, guanabana, cocona, camucamu, limon pajarito, limon taiti, limon mandarino, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Meta': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, guanabana, cocona, camucamu, limon pajarito, limon taiti, limon mandarino, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Caquetá': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, guanabana, cocona, camucamu, limon pajarito, limon taiti, limon mandarino, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Antioquia': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Bolívar': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Cauca': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Cesar': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Córdoba': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'La Guajira': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Magdalena': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Norte de Santander': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
    'Sucre': 'Bananos y chiro (maduros), guayaba, limón, piña, naranja, chirimoya, guanábana, carambolo, borojó, copoazú, manzana, pera, feijoa,  mora, fresa, granadilla, chulupa, uvas, uva caimarona, kiwi, pitahaya,  caimo, mandarina, sandía y  frutas silvestres o locales y jugos hechos al 100% con estas frutas (jugos concentrados).  ',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}


export function listCarneVisceras(  department,  text  ) {
  const  listGroup = {
    'Putumayo': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Chocó': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla ',
    'Nariño': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla ',
    'Arauca': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Guaviare': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Meta': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Caquetá': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Antioquia': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Bolívar': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Cauca': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Cesar': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Córdoba': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'La Guajira': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Magdalena': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Norte de Santander': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
    'Sucre': 'Hígado, lengua, pajarilla, riñones, corazón, molleja, bofe y alimentos hechos con sangre como la morcilla',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}

export function listCarnes(  department, text ) {
  const listGroup = {
    'Putumayo': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Chocó': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza).-Ahumadas, secas',
    'Nariño': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza).-Ahumadas, secas',
    'Arauca': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Guaviare': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres o carne amazonica (carne de caza)',
    'Meta': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres o carne amazonica (carne de caza)',
    'Caquetá': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres o carne amazonica (carne de caza)',
    'Antioquia': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Bolívar': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Cauca': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Cesar': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Córdoba': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'La Guajira': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Magdalena': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Norte de Santander': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
    'Sucre': 'Carne de ganado (res), cerdo, ovejo, chivo, pollo, pavo, pato, paloma, vísceras blancas, conejo, reptiles, animales silvestres (carne de caza)',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}

export function listHuevos(  department,  text) {
  const listGroup = {
    'Putumayo': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Chocó': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Nariño': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Arauca': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, iguana, tortuga otros',
    'Guaviare': 'Todo tipo de huevos de aves:  gallina, pato, codorniz,  tortuga, otros',
    'Meta': 'Todo tipo de huevos de aves:  gallina, pato, codorniz,  tortuga, otros',
    'Caquetá': 'Todo tipo de huevos de aves:  gallina, pato, codorniz,  tortuga, otros',
    'Antioquia': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Bolívar': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Cauca': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Cesar': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Córdoba': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'La Guajira': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Magdalena': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Norte de Santander': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
    'Sucre': 'Todo tipo de huevos de aves:  gallina, pato, codorniz, otros',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}

export function listPescadoMariscos(  department,  text  ) {
  const listGroup = {
    'Putumayo': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), mariscos, huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Chocó': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), mariscos, huevos de pescado, piangua, gambas, jaiba,langosta, mejillones, cangrejos y embutidos hechos con estas carnes',
    'Nariño': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), mariscos, huevos de pescado, piangua, gambas, jaiba,langosta, mejillones, cangrejos y embutidos hechos con estas carnes',
    'Arauca': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Guaviare': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Meta': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Caquetá': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Antioquia': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Bolívar': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Cauca': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Cesar': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Córdoba': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'La Guajira': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Magdalena': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Norte de Santander': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
    'Sucre': 'Pescado fresco o seco, pescado en conservas (atún y sardinas), huevos de pescado, cangrejos y embutidos hechos con estas carnes',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}




export function listLeguminosasLegumbresNuecesSemillas(  department, text  ) {
  const listGroup = {
    'Putumayo': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Chocó': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Nariño': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Arauca': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, carve y productos elaborados con estos alimentos',
    'Guaviare': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Meta': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Caquetá': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Antioquia': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Bolívar': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Cauca': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Cesar': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Córdoba': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'La Guajira': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Magdalena': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Norte de Santander': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
    'Sucre': 'Fríjol, haba, garbanzo, soya, arveja, lenteja, maní, nueces, semilla sachainchi, carve, semilla palma de mil pesos y productos elaborados con estos alimentos',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}

export function listLecheProductosLacteos(  department, text ) {
  const listGroup = {
    'Putumayo': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Chocó': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, queso costeño, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Nariño': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, queso costeño, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Arauca': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo.EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Guaviare': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla si se consume mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Meta': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla si se consume mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Caquetá': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla si se consume mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Antioquia': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Bolívar': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Cauca': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Cesar': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Córdoba': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'La Guajira': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Magdalena': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Norte de Santander': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
    'Sucre': 'Leche y todos los productos elaborados con derivados de la leche: leche, quesos, yogur, kumis, cuajada, queso crema, helado, suero costeño, leche en polvo, suero de leche en polvo. EXCEPTO: mantequilla y crema de leche y pequeñas cantidades de leche para el té/café',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}

export function listAceitesGrasas(  department,  text  ) {
  const listGroup = {
    'Putumayo': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Chocó': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate,bija, chicharrón y cacao, embutidos con carnes, como las salchichas, rellena, longaniza y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo,queso, papas fritas), además de marcar su grupo principal',
    'Nariño': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate,bija, chicharrón y cacao, embutidos con carnes, como las salchichas, rellena, longaniza y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo,queso, papas fritas), además de marcar su grupo principal',
    'Arauca': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales,  coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Guaviare': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Meta': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Caquetá': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Antioquia': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Bolívar': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Cauca': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Cesar': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Córdoba': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'La Guajira': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Magdalena': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Norte de Santander': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
    'Sucre': 'Mantequilla, crema de leche, manteca de cerdo o res, margarina, mayonesa, aceites vegetales (sachainchi, palma de mil pesos), coco, aguacate, chicharrón y cacao, embutidos con carnes, como las salchichas y chorizos caseros. Marcar este grupo si algunos alimentos consumidos fueron fritos (patacón frito, arepa de huevo, papas fritas), además de marcar su grupo principal',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}

export function listDulces(  department,  text ) {
  const listGroup ={
    'Putumayo': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Chocó': 'Golosinas, galletas dulces, chocolates, cocadas, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Nariño': 'Golosinas, galletas dulces, chocolates, cocadas, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Arauca': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Guaviare': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Meta': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Caquetá': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Antioquia': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Bolívar': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Cauca': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Cesar': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Córdoba': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'La Guajira': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Magdalena': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Norte de Santander': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
    'Sucre': 'Golosinas, galletas dulces, chocolates, caramelos, miel (abejas y caña), panela, mermeladas, pasteles y tortas,  postres, refrescos de frutas azucaradas (jugos comerciales) y bebidas azucaradas (gaseosas), jugo de caña, azúcar blanca y morena',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}

export function listEspeciasCondimentosBebidas(  department,  text  ) {
  const listGroup = {
    'Putumayo': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, cubitos de caldo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Chocó': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, canela, clavos de olor, nuez moscada, cúrcuma, jengibre, té, café, cerveza, vino, bebidas alcohólicas, viche, chicha fermentada o sin fermentar. ',
    'Nariño': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, canela, clavos de olor, nuez moscada, cúrcuma, jengibre, té, café, cerveza, vino, bebidas alcohólicas, viche, chicha fermentada o sin fermentar. ',
    'Arauca': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Guaviare': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Meta': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Caquetá': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Antioquia': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Bolívar': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Cauca': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Cesar': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Córdoba': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'La Guajira': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Magdalena': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Norte de Santander': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
    'Sucre': 'Cebolla de huevo, cebolla larga (pequeñas cantidades de aliños), limón (ejm: gotas para el sabor), sal, ají, salsas (tomate, negra, china), hierbas (cilantro cimarrón, poleo, laurel, yerbabuena), pimienta, achiote, azafrán, ajo, té, café, cerveza, vino, bebidas alcohólicas, chicha fermentada o sin fermentar. ',
  };
  try {
    // Devuelve el mensaje correspondiente al departamento o el texto predeterminado.
  return listGroup[department] ? `¿La familia consumió ${listGroup[department]}?` : text;
  } catch (e) {
    return text;
  }
}




  export default departmentListForm160
  