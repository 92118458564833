import React from 'react';
import * as ExcelJS from 'exceljs';
import { Button} from 'antd';
import { DownloadOutlined} from '@ant-design/icons';

const ExportToExcelParticipantes = ({ data }) => {
    const handleExport = () => {
        const fileExtension = '.xlsx';
    const fileName = 'Participantes_UTF_COL_160' + fileExtension;

    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Participantes');
    // Encabezado de múltiples niveles

    // Función auxiliar para convertir columna a número
    const columnToNumber = (column) => {
      let result = 0;
      for (let i = 0; i < column.length; i++) {
        result *= 26;
        result += column.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
      }
      return result;
    };

    // Función auxiliar para convertir número a columna
    const numberToColumn = (number) => {
      let result = '';
      while (number > 0) {
        let remainder = (number - 1) % 26;
        result = String.fromCharCode(65 + remainder) + result;
        number = Math.floor((number - 1) / 26);
      }
      return result;
    };

      // Define la función de formato de fecha
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son 0-indexados
    const day = String(date.getDate()).padStart(2, '0'); // Asegura que el día tenga dos dígitos

    return `${day}/${month}/${year}`;
  }

    // Función para aplicar color de fondo a un rango de celdas
    const applyBackgroundColor = (startCol, endCol, rowNum, color) => {
      const startColNum = columnToNumber(startCol);
      const endColNum = columnToNumber(endCol);

      for (let colNum = startColNum; colNum <= endColNum; colNum++) {
        const currentCol = numberToColumn(colNum);
        const cellRef = `${currentCol}${rowNum}`;
        worksheet.getCell(cellRef).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        };
      }
    };

    // Encabezado titulo
    worksheet.mergeCells('A1:I1'); // Titulo
    worksheet.getCell('A1').value = 'LISTA DE PARTICIPANTES';


    // Ajustar el formato específico para la fila 1
    var row1 = worksheet.getRow(1);
    row1.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 16, bold: true }; // Aplicar negrita y tamaño de fuente 16
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "5b9bd5" }, // Color de fondo
      };
    });



    // Encabezado de múltiples niveles
    worksheet.mergeCells('A2:K2'); // DATOS BÁSICOS
    worksheet.mergeCells('L2:W2'); // POBLACIÓN
    worksheet.mergeCells('X2:AE2'); // INFORMACIÓN FAMILIAR
    worksheet.mergeCells('AF2:BB2'); // INFORMACIÓN DEL PROYECTO VINCULADO
    worksheet.mergeCells('BC2:BF2'); // DATOS DEL CONTACTO DEL BENEFICIARIO
    worksheet.mergeCells('BG2:BH2'); // CONECTIVIDAD
    worksheet.mergeCells('BI2:BP2'); // INFORMACIÓN BANCARIA
    worksheet.mergeCells('BQ2:BT2'); // INFORMACIÓN ORGANIZACIONAL
    worksheet.mergeCells('BU2:BZ2'); // VALIDACIÓN INTERNA

    // Asignación de valores a las celdas combinadas
    worksheet.getCell('A2').value = 'DATOS BÁSICOS';
    worksheet.getCell('L2').value = 'POBLACIÓN';
    worksheet.getCell('X2').value = 'INFORMACIÓN FAMILIAR';
    worksheet.getCell('AF2').value = 'INFORMACIÓN DEL PROYECTO VINCULADO';
    worksheet.getCell('BC2').value = 'DATOS DEL CONTACTO DEL BENEFICIARIO';
    worksheet.getCell('BG2').value = 'CONECTIVIDAD';
    worksheet.getCell('BI2').value = 'INFORMACIÓN BANCARIA';
    worksheet.getCell('BQ2').value = 'INFORMACIÓN ORGANIZACIONAL';
    worksheet.getCell('BU2').value = 'VALIDACIÓN INTERNA';

    // Asignamos colores a las celdas combinadas
    worksheet.getCell('A2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '1E4E79' }
    };

    worksheet.getCell('L2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '385623' }
    };

    worksheet.getCell('X2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2E75B5' }
    };

    worksheet.getCell('AF2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BF9000' }
    };

    worksheet.getCell('BC2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '7B7B7B' }
    };

    worksheet.getCell('BG2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '8EAADB' }
    };


    worksheet.getCell('BQ2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '7F6000' }
    };

    worksheet.getCell('BU2').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '1E4E79' }
    };
    // Ajustar el formato específico para la fila 2
    var row2 = worksheet.getRow(2);
    row2.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = {
        size: 14,
        bold: true,
        color: { argb: 'FFFFFFFF' }  // Añadimos esta línea para hacer el texto blanco
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    const datos =
      [//DATOS BASICOS
        'ID', 'Nombre completo',
        'Tipo de Identificación',
        'Número de Identificación',
        'Sexo',
        'Fecha de Nacimiento',
        '¿Sabe leer y escribir?',
        'Ingresos Mensuales (pesos m/cte)',
        'Línea Económica Principal',
        'Nivel de Escolaridad',
        'Estado dentro del proyecto',
        //POBLACIÓN
        'Campesino',
        'Comunidad Indígena	',
        'Comunidad NARP',
        'Comunidad ROM',
        'Victima',
        'Discapacidad',
        'LGBT_plus',
        'Mujer rural',
        'Joven rural',
        'Adulto mayor',
        'Reincorporado',
        'Otro',
        //INFORMACIÓN FAMILIAR
        'Cabeza de Familia',
        'Número de Personas a Cargo',
        'Total Núcleo Familiar',
        'Estado Civil',
        'Nombre del Cónyuge',
        'Tipo de Identificación Cónyuge',
        'Identificación Cónyuge',
        'Número de Contacto Cónyuge',

        //INFORMACIÓN DEL PROYECTO VINCULADO
        'Codigo proyecto',
        'Nombre proyecto',
        'Subregión PDET',
        'Departamento',
        'Municipio',
        'Tipo de zona',
        'Nombre de zona',
        'Código shape veredas ART',
        'Nombre del Predio/Finca',
        'Relación de Tenencia con el Predio',
        'Latitud',
        'Longitud',
        'No. Hectáreas Destinadas al Proyecto',
        'Linea productiva 1',
        'Variedad establecida',
        'Linea productiva 2',
        'Número de Hectáreas',
        'Producción anual',
        'Distancia Lugar del Proyecto a Cabecera Municipal - (Kilómetros)',
        'Tiempo Lugar del Proyecto a Cabecera Municipal - (Minutos)',
        'Medio de Transporte',
        '¿Vive en el mismo lugar del Proyecto?',
        '¿Lugar de Vivienda?',


        //DATOS DEL CONTACTO BENEFICIARIOS
        'Dirección de Correspondencia',
        'Número Telefónico 1',
        'Número Telefónico 2',
        'Correo eléctronico',


        //CONECTIVIDAD
        'Telefónica',
        'Internet	',


        //INFORMACIÓN BANCARIA
        'Tipo Establecimiento Bancario cercano a su Viviendaónica',
        '¿Cuenta con Productos Financieros?',
        'Cuenta de Ahorro',
        'Tarjeta de Crédito',
        'Otros Créditos',
        'Tipo de entidad',
        '¿Ha recibido Capacitación en Educación Financiera?',
        '¿Interesado en Información de Inclusión Financiera?',

        //INFORMACIÓN ORGANIZACIONAL
        '¿Vinculado a algúna Organización?',
        'Tipo de Organización',
        'Nombre de la organización',
        'Es una organización de Mujeres?',


        //VALIDACIÓN INTERNA
        '¿Validado Por Registraduría?',
        '¿Validado Por Procuraduría?',
        '¿Validado Por Policía?',
        'Mediadas correctivas',
        '¿Validación Por Contraloría',
        '% Documentación'];


    // Configuración del ancho de las columnas
    worksheet.columns = datos.map(() => ({ width: 20 }));
    // Agregar fila de encabezado
    worksheet.addRow(datos);



    // Mapear y seleccionar todos los campos de participantes
    const dataToExport = data.map(part => [
      //Data DATOS BASICOS
      part.oid,
      part.nombre_participante,
      part.tipo_identificacion,
      part.numero_identificacion,
      part.sexo,
      formatDate(part.fecha_nacimiento),
      part.sabe_leer_escribir,
      part.ingresos_mensuales,
      part.linea_economica,
      part.nivel_escolaridad,
      'Pendiente',
      //DATA POBLACION
      part.Campesino,
      part.Comunidad_Indigena,
      part.Comunidad_NARP,
      part.Comunidad_ROM,
      part.Victima,
      part.Discapacidad,
      part.LGBT_plus,
      part.Mujer_Rural,
      part.Joven_Rural,
      part.Adulto_Mayor,
      part.Reincorporado,
      part.Otro,
      //DATA INFORMACIÓN FAMILIAR
      part.cabeza_familia,
      part.num_personas_cargo,
      part.total_nucleo_familiar,
      part.estado_civil,
      part.nombre_conyuge,
      part.tipo_identificacion_conyuge,
      part.identificacion_conyuge,
      part.numero_contacto_conyuge,


      //INFORMACIÓN DEL PROYECTO
      part.codigo,
      part.proyecto,
      part.nombre_subregion_pdet,
      part.departamento,
      part.municipio,
      part.tipo_zona_influencia,
      part.nombre_zona,
      part.codigo_shape,
      part.nombre_predio,
      part.relacion_tenencia_predio,
      part.latitud,
      part.longitud,
      part.num_hectarias_proyecto,
      part.linea_productiva_1,
      part.variedad_establecida,
      part.linea_productiva_segundaria,
      part.num_hectareas,
      part.produccion_anual,
      part.distancia_cabecera_municipal,
      part.tiempo_cabecera_municipal,
      part.medio_transporte,
      part.vive_mismo_lugar,
      part.lugar_vivienda,

      //DATOS DEL CONTACTO DEL BENEFICIARIO
      part.direccion_correspondencia,
      part.numero_telefonico_1,
      part.numero_telefonico_2,
      part.correo_electronico,

      //DATOS CONECTIVIDAD
      part.telefonica,
      part.internet,

      //INFORMACIÓN BANCARIA
      part.establemcimiento_bancario,
      part.cuenta_con_productos_financieros,
      part.cuenta_de_ahorro,
      part.tarjeta_de_credito,
      part.otros_creditos,
      part.tipo_entidad,
      part.recibio_capacitacion_educacion_financiera,
      part.interesado_informacion_inclusion_financiera,

      //INFORMACIÓN ORGANIZACIONAL
      part.vinculado_alguna_organizacion,
      part.tipo_organizacion,
      part.organizacion,
      part.org_mujeres,

      //DATOS VALIDACIÓN
      part.validado_registraduria,
      part.validado_procuraduria,
      part.validado_policia,
      part.RNMC,
      part.validado_contraloria,
      part.progress

    ]);


    // Agregar los datos a la hoja
    worksheet.addRows(dataToExport);

    // Aplicar colores a todos los rangos
    applyBackgroundColor('A', 'K', '3', 'FFD7E2F4');
    applyBackgroundColor('L', 'W', '3', 'FFE3EFD9');
    applyBackgroundColor('X', 'AE', '3', 'DEEAF6');
    applyBackgroundColor('AF', 'AJ', '3', 'FFFEF2CB');
    applyBackgroundColor('AK', 'AQ', '3', 'FFDEEAF6');
    applyBackgroundColor('AR', 'AW', '3', 'FFECECEC');
    applyBackgroundColor('AX', 'BB', '3', 'FFFEF2CB');
    applyBackgroundColor('BC', 'BF', '3', 'FFD0CECE');
    applyBackgroundColor('BG', 'BH', '3', 'FFD9E3F3');
    applyBackgroundColor('BI', 'BP', '3', 'FFFBE4D5');
    applyBackgroundColor('BQ', 'BT', '3', 'FFFFE598');



    // Ajustar el formato específico para la fila 3
    var row3 = worksheet.getRow(3);
    row3.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true }; // Aplicar negrita y tamaño de fuente 16
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
    });

    // Ajustar el formato de todas las celdas
    worksheet.eachRow(
      { includeEmpty: true },
      function (row, rowNumber) {
        row.eachCell(
          { includeEmpty: true },
          function (cell, colNumber) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            }; // Bordes
          }
        );
      }
    );
    // Guardar el archivo Excel
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });
    };

    return (
        <Button
        type="danger"
        className="btn btn-primary me-2"
        size="large"
        onClick={handleExport}
        icon={<DownloadOutlined />}
    >
        Excel
    </Button>
    );
};

export default ExportToExcelParticipantes;
