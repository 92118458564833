import React, { useEffect, useState } from 'react';
import { Card, Typography, Tree, Row, Col, Layout, Collapse, Spin, Alert, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { faBroom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProyectoService from '../../services/estructuracion/get_proyecto_id'; // Ajusta la ruta según sea necesario
import Header160 from '../header/header';
import './proyecto_estructuracion.css';
import { faFilePdf, faFileWord, faFileExcel, faFileAlt, faFile } from '@fortawesome/free-solid-svg-icons';
import { viewOrDownloadFile } from '../../services/estructuracion/view_supp'; // Importa el servicio

const { Title } = Typography;
const { Panel } = Collapse;
const iconMap = {
    pdf: faFilePdf,
    doc: faFileWord,
    docx: faFileWord,
    xls: faFileExcel,
    xlsx: faFileExcel,
    txt: faFileAlt,
    default: faFile // Icono por defecto para otros tipos de archivos
};

const ProyectoView = () => {
    const { oid } = useParams();

    const [proyecto, setProyecto] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProyectoData = async () => {
            try {
                const data = await ProyectoService.fetchProyectoByOid(oid);
                setProyecto(data);
            } catch (err) {
                console.error("Error al obtener los datos del proyecto:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        if (oid) {
            fetchProyectoData();
        } else {
            console.warn("No se proporcionó un OID.");
        }
    }, [oid]);



    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh' // Ocupa toda la altura de la ventana
            }}>
                <Spin size="large" />
            </div>
        );
    }


    if (error) {
        return <div style={{ padding: '20px' }}><Alert message="Error al cargar el proyecto" type="error" /></div>;
    }

    if (!proyecto) {
        return <div style={{ padding: '20px' }}><Alert message="No se encontró el proyecto" type="warning" /></div>;
    }

    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    };
    const getFileIcon = (extension) => {
        return iconMap[extension] || iconMap.default;
    };

    const handleFileClick = async (soporte) => {
        const extension = soporte.extension.toLowerCase().replace('.', '');
        // Define los tipos de archivos que se pueden visualizar directamente
        const viewableExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'txt', 'doc', 'docx', 'xls', 'xlsx'];
        try {
            if (viewableExtensions.includes(extension)) {
                // Si es un archivo que se puede ver, llamamos al servicio para visualizarlo
                await viewOrDownloadFile(soporte.ruta, soporte.nombre);
            } else {
                // Si es un archivo que no se puede visualizar, llamamos al servicio para descargarlo
                await viewOrDownloadFile(soporte.ruta, soporte.nombre);
            }
        } catch (error) {
            console.error('Error al ver o descargar el archivo:', error);
        }
    };

    return (
        <Layout className="container">
            <div className="header-filter-container d-flex align-items-center justify-content-between">
                <Header160 />
                <div className="container elemtos">
                    <div className="input-group-container">
                        <div className="input-group shadow-sm">
                            <input
                                type="text"
                                className="form-control shadow-sm"
                                placeholder="Buscar por código o nombre"
                            />
                            <button className="btn btn-primary" type="button">
                                <FontAwesomeIcon icon={faBroom} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ padding: '20px', overflowY: 'auto' }}>
                <Title level={3} className="gradient-text">
                    {proyecto.codigo} - {proyecto.nombre_proyecto}
                </Title>
                <Title level={5} className="justified-title">
                    {proyecto.actividades && proyecto.actividades[0]?.codigo} {proyecto.actividades && proyecto.actividades[0]?.nombre_actividad || 'Nombre de la Actividad'}
                </Title>
                <Collapse accordion >
                    {proyecto.actividades.map((actividad, index) => (
                        <Collapse accordion key={index} className="collate-background-estructuracion">
                            {actividad.tareas.map((tarea, tareaIndex) => (
                                <Panel
                                    header={`${tarea.codigo} - ${tarea.nombre_tarea}`}
                                    key={`tarea-${tareaIndex}`}
                                    className="custom-panel-estructuracion"
                                >
                                    <Row gutter={24} justify="center" className="custom-background-estructuracion">
                                        {tarea.indicadores.map((indicador, indicadorIndex) => (
                                            <React.Fragment key={`indicador-${indicadorIndex}`}>
                                                <Row gutter={16} justify="center" style={{ width: '100%' }}>
                                                    <Col span={24} justify="center">
                                                        <div style={{ textAlign: 'center', margin: '0px' }}>
                                                            <h4 style={{ textAlign: 'center' }}>Indicador</h4>
                                                            <h3 style={{ textAlign: 'center' }}>{indicador.nombre_indicador}</h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16} justify="space-around" align="top">
                                                    {indicador.rutas.map((ruta, rutaIndex) => (
                                                        <Col key={`ruta-${rutaIndex}`} className="custom-col">
                                                            <div className="custom-card-container">
                                                                <Card
                                                                    className="custom-card custom-card-header"
                                                                    title={<div className="card-title">{ruta.nombre_ruta}</div>}
                                                                    bordered={false}
                                                                    style={{ width: '300px', padding: '0px' }} // Sin altura fija
                                                                >
                                                                    {Array.isArray(ruta.soportes) && ruta.soportes.length > 0 ? (
                                                                        <ul>
                                                                            {ruta.soportes.map((soporte, soporteIndex) => {
                                                                                const extension = soporte.extension.toLowerCase().replace('.', '');
                                                                                const fileIcon = getFileIcon(extension);
                                                                                const cleanedName = soporte.nombre.replace(/^\d+-/, '');

                                                                                return (
                                                                                    <li key={`soporte-${soporteIndex}`}>
                                                                                        <button
                                                                                            onClick={() => handleFileClick(soporte)}
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                background: 'none',
                                                                                                border: 'none',
                                                                                                padding: 0,
                                                                                                cursor: 'pointer',
                                                                                                textAlign: 'left',
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={fileIcon}
                                                                                                style={{ marginRight: '10px' }}
                                                                                            />
                                                                                            <Tooltip title={cleanedName}>
                                                                                                <span>{truncateString(cleanedName, 40)}</span>
                                                                                            </Tooltip>
                                                                                        </button>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    ) : (
                                                                        <div>No se encontraron soportes</div>
                                                                    )}
                                                                </Card>
                                                            </div>


                                                        </Col>
                                                    ))}
                                                </Row>
                                            </React.Fragment>
                                        ))}
                                    </Row>

                                </Panel>
                            ))}
                        </Collapse>
                    ))}
                </Collapse>
            </div>
        </Layout>
    );

};

export default ProyectoView;
