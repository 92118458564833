import React, { useState, useEffect } from 'react';
import { Tabs, Table, Button, Tooltip, Space, Checkbox, Row, Col, Modal, message, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { getUsersWithAsistenciaId, deleteUserFromSesion, getUsersWithoutAsistenciaId, saveSelectedUsers } from '../../services/cursoFortalecimiento/cursoFortalecimientoParticipantesService'; // Ajusta la ruta según corresponda

const Participantes = (sesion) => {
    const { TabPane } = Tabs;
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [usuariosConAsistencia, setUsuariosConAsistencia] = useState([]);
    const [usuariosSinAsistencia, setUsuarioSinAsistencia] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterNombre, setFilterNombre] = useState('');
    const [filterDocumento, setFilterDocumento] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await getUsersWithAsistenciaId(sesion.sesion);
                const dataA = await getUsersWithoutAsistenciaId(sesion.sesion);
                setUsuarioSinAsistencia(dataA);
                setUsuariosConAsistencia(data);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener usuarios:', error);
                setLoading(false);
            }
        };
        fetchUsers();
    }, [sesion]);

    const filteredUsuariosConAsistencia = usuariosConAsistencia.filter(userfiltro => {
        // Si no hay filtro, devuelve todos los usuarios
        if (!filterNombre && !filterDocumento) {
            return true; // No se aplica filtro, devuelve todos
        }
    
        // Asegúrate de que 'documento' es una cadena antes de aplicar toLowerCase
        const documento = userfiltro.documento ? userfiltro.documento.toString().toLowerCase() : '';
    
        return (
            userfiltro.nombre_participante.toLowerCase().includes(filterNombre.toLowerCase()) &&
            documento.includes(filterDocumento.toLowerCase())
        );
    });
    
    const filteredUsuariosSinAsistencia = usuariosSinAsistencia.filter(userfiltro => {
        // Si no hay filtro, devuelve todos los usuarios
        if (!filterNombre && !filterDocumento) {
            return true; // No se aplica filtro, devuelve todos
        }
    
        // Asegúrate de que 'documento' es una cadena antes de aplicar toLowerCase
        const documento = userfiltro.documento ? userfiltro.documento.toString().toLowerCase() : '';
    
        return (
            userfiltro.nombre_participante.toLowerCase().includes(filterNombre.toLowerCase()) &&
            documento.includes(filterDocumento.toLowerCase())
        );
    });
    
    
    const handleDelete = (key) => {
        Modal.confirm({
            title: <span style={{ textAlign: 'center', width: '100%' }}>¿Estás seguro de que deseas eliminar este participante?</span>,
            content: 'Una vez eliminado, no podrás recuperar esta información.',
            okText: 'Sí, eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk: async () => {
                try {
                    await deleteUserFromSesion(key.id_sesion);
                    const dataConAsistencia = await getUsersWithAsistenciaId(sesion.sesion);
                    const dataSinAsistencia = await getUsersWithoutAsistenciaId(sesion.sesion);
                    setUsuariosConAsistencia(dataConAsistencia);
                    setUsuarioSinAsistencia(dataSinAsistencia);
                } catch (error) {
                    console.error('Error al eliminar el usuario:', error);
                }
            },
        });
    };

    const columns1 = [
        { title: 'Departamento', dataIndex: 'departamento', key: 'departamento' },
        { title: 'Municipio', dataIndex: 'municipio', key: 'municipio' },
        { title: 'Documento', dataIndex: 'documento', key: 'documento' },
        { title: 'Participante', dataIndex: 'nombre_participante', key: 'nombre_participante' },
        { title: 'Genero', dataIndex: 'genero', key: 'genero' },
        {
            title: 'Acciones',
            key: 'acciones',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Eliminar">
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => handleDelete(record)}  // Llama a handleDelete
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const columns2 = [
        {
            title: 'Seleccionar',
            dataIndex: 'select',
            align: 'center',
            render: (_, record) => (
                <Checkbox
                    checked={selectedUsers.includes(record.oid)}
                    onChange={(e) => handleSelectUser(e.target.checked, record.oid)}
                />
            ),
        },
        { title: 'Departamento', dataIndex: 'departamento', key: 'departamento' },
        { title: 'Municipio', dataIndex: 'municipio', key: 'municipio' },
        { title: 'Documento', dataIndex: 'documento', key: 'documento' },
        { title: 'Participante', dataIndex: 'nombre_participante', key: 'nombre_participante' },
        { title: 'Genero', dataIndex: 'genero', key: 'genero' },
    ];

    const handleSelectUser = (checked, key) => {
        if (checked) {
            setSelectedUsers(prevSelected => [...prevSelected, key]);
        } else {
            setSelectedUsers(prevSelected => prevSelected.filter(userKey => userKey !== key));
        }
    };

    const handleSaveSelectedUsers = async () => {
        try {
            const usuariosSeleccionados = usuariosSinAsistencia.filter(user => selectedUsers.includes(user.oid));
            const asistenciaId = sesion.sesion.asistencia_id;
            const fecha = sesion.sesion.fecha_sesion;
            const usuariosConFecha = usuariosSeleccionados.map(user => ({
                ct_usuario: user.oid,
                fecha_sesion: fecha,
                asistencia_id: asistenciaId,
            }));
            await saveSelectedUsers(usuariosConFecha);
            const dataConAsistencia = await getUsersWithAsistenciaId(sesion.sesion);
            const dataSinAsistencia = await getUsersWithoutAsistenciaId(sesion.sesion);
            setUsuariosConAsistencia(dataConAsistencia);
            setUsuarioSinAsistencia(dataSinAsistencia);
            setSelectedUsers([]);
            message.success('Usuarios guardados correctamente');
        } catch (error) {
            console.error('Error al guardar los usuarios:', error);
            message.error('Hubo un problema al guardar los usuarios');
        }
    };

    const handleClearFilters = () => {
        setFilterNombre('');
        setFilterDocumento('');
    };

    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Lista de Usuarios" key="1">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Input
                            placeholder="Buscar por nombre"
                            value={filterNombre}
                            onChange={(e) => setFilterNombre(e.target.value)}
                            style={{ marginBottom: 8, width: 200 }}
                        />
                        <Input
                            placeholder="Buscar por cédula"
                            value={filterDocumento}
                            onChange={(e) => setFilterDocumento(e.target.value)}
                            style={{ marginBottom: 8, width: 200, marginLeft: 16 }}
                        />
                        <Button onClick={handleClearFilters} style={{ marginBottom: 8, marginLeft: 16 }}>
                            Limpiar Filtros
                        </Button>
                        <Table
                            columns={columns1}
                            dataSource={filteredUsuariosConAsistencia}
                            pagination={{ pageSize: 10 }}
                            loading={loading}
                            scroll={{ x: 'max-content' }}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab="Agregar usuarios" key="2">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                    <Input
                            placeholder="Buscar por nombre"
                            value={filterNombre}
                            onChange={(e) => setFilterNombre(e.target.value)}
                            style={{ marginBottom: 8, width: 200 }}
                        />
                        <Input
                            placeholder="Buscar por cédula"
                            value={filterDocumento}
                            onChange={(e) => setFilterDocumento(e.target.value)}
                            style={{ marginBottom: 8, width: 200, marginLeft: 16 }}
                        />
                        <Button onClick={handleClearFilters} style={{ marginBottom: 8, marginLeft: 16 }}>
                            Limpiar Filtros
                        </Button>
                        <Table
                            columns={columns2}
                            dataSource={filteredUsuariosSinAsistencia}
                            pagination={{ pageSize: 10 }}
                            loading={loading}
                            scroll={{ x: 'max-content' }}
                        />
                    </Col>
                </Row>
                <Button
                    type="primary"
                    onClick={handleSaveSelectedUsers}
                    style={{ marginTop: 16 }}
                >
                    Guardar Seleccionados
                </Button>
            </TabPane>
        </Tabs>
    );
};

export default Participantes;
