import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Tree, Button, Spin, DatePicker, Radio, Switch, Form, Progress, Descriptions, Modal, Popconfirm, Select, Layout, Alert, Upload, message, Space, Input, Table, Row, Col, Badge, Tooltip } from 'antd';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import { LeftOutlined, PlusOutlined, CloudUploadOutlined, FileZipOutlined, InboxOutlined, SearchOutlined, FolderOpenOutlined, FolderAddFilled, EyeOutlined, FileExclamationOutlined, CloseCircleOutlined, CloseOutlined, FileOutlined, EditOutlined, SaveOutlined, FolderOutlined, DownloadOutlined, DeleteOutlined, UploadOutlined, WarningOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import Header160 from '../header/header';
import Highlighter from 'react-highlight-words';
import { cloneDeep } from 'lodash';
import Marquee from 'react-fast-marquee';
import './FileManagerPOA.css'
import { checkFolderName, createFolder } from '../../services/project/checkFolder';
import config from '../../config';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import { logAction } from './logActionsPOA';
import { fetchPoaDataByPath } from '../../services/poa/get_poaDocumentosMedios';

const { Content, Sider } = Layout;
const { Dragger } = Upload;
const { Dragger: AntDragger } = Upload; // Usa un alias para evitar conflictos

const { Search } = Input;
const { Option } = Select;
const { DirectoryTree } = Tree;

const baseUrl = config.baseUrl;



const truncateText = (text, maxLength) => {
    if (!text || typeof text !== 'string') {
        return ''; // Devuelve una cadena vacía si text es undefined, null, o no es una cadena
    }
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
};


const cleanFileName = (encodedFileName) => {
    // Primero decodifica el nombre del archivo
    const decodedFileName = decodeURIComponent(encodedFileName);

    // Luego aplica la lógica de limpiar el nombre del archivo
    const index = decodedFileName.indexOf('-');

    return index !== -1 ? decodedFileName.substring(index + 1) : decodedFileName;
};


const truncateTextName = (text, maxLength) => {
    if (!text || typeof text !== 'string') {
        return ''; // Devuelve una cadena vacía si text es undefined, null, o no es una cadena
    }
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
};

const processFileName = (fileName, maxLength) => {

    // Primero limpiamos el nombre del archivo para quitar el hash
    const cleanedFileName = cleanFileName(fileName);
    // Luego truncamos el nombre si es necesario
    return truncateTextName(cleanedFileName, maxLength);
};

const FileManagerPOA = (codigo_tarea) => {
    const [treeData, setTreeData] = useState([]);
    const [treeFilesData, settreeFilesData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const userLogin = user.username;
    const [versionFrom, setVersionFrom] = useState([null]);
    const tmpCodigo = String(codigo_tarea.codigo_tarea.codigo_tarea).replace(/\./g, '');
    const codigo_proyecto_busqueda = 'tarea_' + tmpCodigo;
    const [loading, setLoading] = useState(true);
    const [expandAll, setExpandAll] = useState(true);
    const [tituloNameProyecto, settituloNameProyecto] = useState('');
    const nameproyecto = "Tarea POA";
    const [form] = Form.useForm();
    const [showWarning, setShowWarning] = useState(false);
    const [rootNodeSelected, setRootNodeSelected] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState(null);
    const [activeButton, setActiveButton] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(selectedItem ? selectedItem.name : '');
    const [originalName, setOriginalName] = useState(selectedItem ? selectedItem.name : ""); // Inicializar originalName con selectedItem.name si existe
    const [proyectos, setProyectos] = useState([]);
    const [SearchValueCod, setSearchValueCod] = useState('');
    //Control carga de medios de verificacion
    const [isModalFileVisible, setIsModalFileVisible] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const inputRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [dataFile, setDataFile] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar el estado del botón

    //Control de Permisos
    const appName = 'file-managerPOA';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionDelete = permissions.priv_delete === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionExport = permissions.priv_export === 'Y';
    const permissionPrint = permissions.priv_print === 'Y';

    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
    };

    const showCreateFolderModal = () => {
        setIsModalVisible(true);
    };

    // Enfocar el campo de entrada cuando el modal se abre
    useEffect(() => {
        if (isModalVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isModalVisible]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleCreateFolder();
        }
    };


    useEffect(() => {
        const fetchData = async () => {

            if (!codigo_proyecto_busqueda) {
                setLoading(false);
                setShowWarning(true);
                settituloNameProyecto("FAO - UTF COL 160 COL")
                return;
            }
            settituloNameProyecto(`${codigo_proyecto_busqueda} ${nameproyecto}`);
            try {
                const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
                const codActividadParametro = 'actividad_' + tmpcodActividadParametro;


                const response = await axios.get(`${baseUrl}/api/directorytreepoa?codigActividad=${codActividadParametro}&codigoTarea=${codigo_proyecto_busqueda}`);
                const data = response.data;
                const indicador = codigo_tarea.codigo_tarea.key;

                //Control de exepción para 221

                let mv;


                if (codigo_proyecto_busqueda === 'tarea_221') {

                    if (indicador === 'indicador_32') {

                        mv = 'medio_verificacion_27';
                    } else if (indicador === 'indicador_33') {

                        mv = 'medio_verificacion_28';

                    } else if (indicador === 'indicador_34') {

                        mv = 'medio_verificacion_29';

                    }

                } else {

                    mv = data[0].children[0].children[0].name; // Nombre medio Verificacion
                }

                const fullPath = "DocumentosPOA/" + codActividadParametro + "/" + codigo_proyecto_busqueda + "/" + indicador + "/" + mv;
                const documents = await fetchPoaDataByPath(fullPath);

                setDataFile(documents);
                setVersionFrom(tmpcodActividadParametro);
                // Función para filtrar y obtener solo los children de los nodos tipo 'indicador'
                const getIndicadorChildren = (nodes) => {

                    // Iterar sobre los nodos
                    return nodes.flatMap(node => {
                        if (node.typeS === 'indicador' && node.name === indicador) {
                            // Si el nodo es tipo 'indicador', devolver sus children
                            return node.children;
                        } else if (node.children && node.children.length > 0) {
                            // Si el nodo tiene children, aplicar la función recursivamente
                            return getIndicadorChildren(node.children);
                        }
                        // Si el nodo no es 'indicador' y no tiene children, devolver un array vacío
                        return [];
                    });
                };
                // Llamar a la función para obtener solo los children de los nodos tipo 'indicador'
                const filteredData = getIndicadorChildren(data);
                setTreeData(filteredData);


                setTableParams(prevParams => ({
                    ...prevParams,
                    pagination: {
                        ...prevParams.pagination,

                    },
                }));

                // Siempre expande el nodo raíz '0' al cargar los datos
                setExpandedKeys(keys => {
                    if (!keys.includes('0')) {
                        return [...keys, '0'];
                    }
                    return keys;
                });

                if (!expandAll && data.length > 0) {
                    const initialKeysToExpand = data.slice(0, 9).map(item => item.key);
                    setExpandedKeys(initialKeysToExpand);
                }

            } catch (error) {
                console.error('Error fetching file tree:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [codigo_proyecto_busqueda, expandAll]);


    useEffect(() => {
        const fetchProyectos = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/api/arbolProyectos`);
                const data = response.data.data;
                setProyectos(data);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching proyectos:', error);
                setLoading(false);
            }
        };

        fetchProyectos();
    }, [setLoading]);


    //Funciones carga de soportes POA
    const handleOkFile = () => {

        if (selectedItem && selectedItem.pathFromClient) {
            // Tu lógica aquí de crear archivo
        } else {
            console.error('selectedItem o pathFromClient es nulo o indefinido.');
        }
        setIsModalFileVisible(true);
        form.resetFields();

    };

    const handleCancelFile = () => {
        form.resetFields(); // Resetea los campos del formulario
        setIsModalFileVisible(false);
    };

    const [hover, setHover] = useState(false);

    const handleCustomRequest = async ({ file, onSuccess, onError, resourceType }) => {
        try {

            if (!file) {
                return onError(new Error('Nombre del soporte es requerido'));
            }

            const nodeRaiz = 'DocumentosPOA';
            const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
            const codActividadParametro = 'actividad_' + tmpcodActividadParametro;


            const tmpRuta = nodeRaiz + '/' + codActividadParametro + '/' + selectedItem.pathFromClient;
            // Crear y enviar el FormData con el archivo y pathFromClient
            const resourceInfo = await handleAction({
                user: userLogin, // Reemplaza con el usuario actual
                actionType: 'uploadFile',
                nameServer: file.name, // Nombre del servidor
                resourceDetails: {
                    nameResource: file.name,
                    Path: `${codActividadParametro}/${selectedItem.pathFromClient}`,
                    fatherResource: selectedItem.name,
                    typeResource: 'file',
                    key: `${selectedItem.pathFromClient}/${file.name}`,
                    fullPath: `${tmpRuta}/${file.name}`,
                    resourceType: resourceType
                }
            });


            const formData = new FormData();
            formData.append('pathFromClient', tmpRuta);
            formData.append('file', file);
            formData.append('resourceInfo', JSON.stringify(resourceInfo));

            const response = await fetch(`${baseUrl}/api/upload`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (response.ok && data.status === 'success') {
                message.success(`${file.name} Archivo cargado.`);

                // Actualiza el árbol de directorios con el nuevo archivo
                const uid = generateRandomUid();
                const newKey = `${selectedItem.key} - ${uid}`;

                const newFileNode = {
                    name: file.name,
                    key: newKey,
                    type: 'file',
                    level: selectedItem.level + 1,
                    path: `${selectedItem.pathFromClient}/${data.serverFileName}`, // Usa las comillas invertidas correctas
                    pathFromClient: selectedItem.pathFromClient,
                    uid: uid,
                    serverFileName: data.serverFileName,
                    isLeaf: true,
                    viewName: data.viewName,
                    typeS: 'Soporte',
                    resourceType: resourceType
                };

                const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
                const codActividadParametro = 'DocumentosPOA/actividad_' + tmpcodActividadParametro;

                const now = new Date(); // Fecha y hora actual
                const isoString = now.toISOString(); // Convierte a formato ISO 8601

                //Actualizamos los datos del FileData
                const newFileNodeDataFile =
                {
                    nameResource: file.name,
                    fullPath: `${codActividadParametro}/${selectedItem.pathFromClient}/${data.serverFileName}`,
                    user: userLogin,
                    state: 1,
                    typeAction: "uploadFile",
                    dateTime: `${isoString}`,
                    detailJSON: JSON.stringify(resourceInfo)
                }

                setTreeData((prevTreeData) => addFileToTree(prevTreeData, selectedItem.pathFromClient, newFileNode));
                setDataFile((prevDataFile) => [...prevDataFile, newFileNodeDataFile]);


                // Encuentra el nodo actualizado
                const updatedNode = findNode(treeData, selectedItem.pathFromClient);

                if (updatedNode) {
                    // Actualiza el estado de selectedItem para reflejar los cambios en el árbol
                    setSelectedItem(prevSelectedItem => {
                        // Si la carpeta seleccionada es la misma que el nodo actualizado, actualiza sus hijos
                        if (prevSelectedItem.path === updatedNode.path) {
                            const updatedChildren = [...updatedNode.children, {
                                name: file.name,
                                key: newKey,
                                type: 'file',
                                level: selectedItem.level + 1,
                                path: `${selectedItem.pathFromClient}/${data.serverFileName}`, // Usa las comillas invertidas correctas
                                pathFromClient: selectedItem.pathFromClient,
                                uid: uid,
                                serverFileName: data.serverFileName,
                                isLeaf: true,
                                viewName: file.name,
                                typeS: 'SoporteS',
                            }];
                            return {
                                ...updatedNode,
                                children: updatedChildren,
                                pathFromClient: updatedNode.path
                            };
                        }
                        return prevSelectedItem;
                    });

                    // Actualiza dataSource de la tabla
                    updateTreeFilesData(updatedNode);


                }

                // Marca la subida como exitosa
                onSuccess("ok");
            } else {
                throw new Error(`Error en la carga de ${file.name}`);
            }
        } catch (error) {
            message.error(`${file.name} Error en la carga, vuelve a intentarlo.`);
            onError(error); // Marca la subida como fallida
        }
    };


    const handleFormSubmit = async () => {
        if (!fileToUpload) {
            message.error('Por favor, cargue un archivo.');
            return;
        }

        try {
            await form.validateFields();
            setIsSubmitting(true);

            let titulo;

            const versionFromNumber = Number(versionFrom);
            const resourceType = form.getFieldValue('resourceType');


            // Verificar si la versión es distinta de 13
            if (versionFromNumber !== 13) {
                // Obtener el valor del campo 'titulo' del formulario
                titulo = form.getFieldValue('titulo');

                // Si 'titulo' está vacío o indefinido, utiliza el valor inicial
                if (!titulo) {

                    const allInitialValues = form.getFieldsValue(true); // Obtener todos los valores, incluyendo los iniciales
                    const tituloInicial = allInitialValues.titulo;
                    titulo = tituloInicial;
                }

            } else {
                // Si la versión es 13, usar el nombre del ítem seleccionado como título
                titulo = selectedItem.name;
            }



            // Obtener la extensión del archivo original
            const fileExtension = fileToUpload.name.split('.').pop();
            const newFileName = `${titulo}.${fileExtension}`;

            const decodedFileName = decodeURIComponent(newFileName);

            // Crear un nuevo archivo con el nuevo nombre
            const renamedFile = new File([fileToUpload], decodedFileName, { type: fileToUpload.type });

            //ResourceINFO
            // Subir el archivo manualmente
            handleCustomRequest({
                file: renamedFile,
                onSuccess: () => {
                    // Limpiar la lista de archivos después de la subida
                    setFileList([]);
                    setFileToUpload(null);
                    setIsModalFileVisible(false);
                    setIsSubmitting(false); // Reactivar el botón

                },
                onError: (error) => {
                    console.error('Error en la subida:', error);
                    setIsSubmitting(false);
                },
                resourceType: resourceType,
            });


        } catch (errorInfo) {
            setIsSubmitting(false);
        }
    };

    const searchInTree = (node, searchTerm, expandedKeys) => {
        let foundMatch = false;

        // Verifica si el nombre del nodo actual incluye el término de búsqueda (ignorando mayúsculas/minúsculas)
        if (node.viewName.toLowerCase().includes(searchTerm.toLowerCase())) {

            node.highlighted = true;

            foundMatch = true;

            // Aplica el resaltado al nombre del nodo
            node.title = (
                <span>
                    {node.viewName.replace(
                        new RegExp(`(${searchTerm})`, 'gi'),
                        (text, match) => (
                            <span style={{ backgroundColor: 'yellow', color: '#AAA' }}>{match}</span>
                        )
                    )}
                </span>
            );
        } else {
            node.highlighted = false;
        }

        // Verifica si el nodo actual tiene hijos y realiza la búsqueda en ellos
        if (node.children && node.children.length > 0) {
            node.children.forEach(childNode => {
                if (searchInTree(childNode, searchTerm, expandedKeys)) {
                    expandedKeys.push(node.key); // Expande el nodo actual si se encontró una coincidencia en sus hijos
                    foundMatch = true;
                }
            });
        }

        return foundMatch;
    };

    const onChange = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (treeData.length > 0) {
            const emptyFolders = filterEmptyFolders(treeData);
            settreeFilesData(emptyFolders);

            // Combina las claves expandidas anteriores con la expansión inicial del nodo 0
            setExpandedKeys(prevExpandedKeys => {
                const nodeZeroKey = treeData[0]?.key;
                const updatedExpandedKeys = prevExpandedKeys.includes(nodeZeroKey)
                    ? prevExpandedKeys
                    : [...prevExpandedKeys, nodeZeroKey];
                return updatedExpandedKeys;
            });
        }
    }, [treeData]);

    const onClickBuscar = () => {
        if (searchValue.trim() === '') {
            return; // No hacer nada si el campo de búsqueda está vacío
        }

        const clonedTreeData = cloneDeep(treeData);
        const newExpandedKeys = [];

        // Función recursiva de búsqueda que expande los nodos donde se encuentra el término de búsqueda
        clonedTreeData.forEach(node => searchInTree(node, searchValue, newExpandedKeys));

        // Asegúrate de que el nodo 0 esté siempre expandido
        const nodeZeroKey = treeData[0]?.key;
        if (nodeZeroKey && !newExpandedKeys.includes(nodeZeroKey)) {
            newExpandedKeys.push(nodeZeroKey);
        }

        setTreeData(clonedTreeData); // Actualiza el árbol con los datos modificados
        setExpandedKeys(newExpandedKeys); // Actualiza las claves expandidas
    };


    const clearHighlight = (nodes) => {
        nodes.forEach(node => {
            node.highlighted = false; // Establece todos los nodos como no destacados
            if (node.children) {
                clearHighlight(node.children); // Recursivamente limpia los hijos si existen
            }
        });
    };

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const handleSelect = async (selectedKeys, info) => {
        const selectedNode = info.node;
        const nombreNodoRaiz = codigo_proyecto_busqueda;


        if (selectedNode.name === nombreNodoRaiz) {
            setRootNodeSelected(true);
            setSelectedItem(null);
        } else {
            setSelectedItem({
                ...selectedNode,
                pathFromClient: selectedNode.path
            });
            setRootNodeSelected(false);

            if (selectedNode.type === 'file') {
                fetchFileUrl(selectedNode.path);
            }

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: selectedNode.children ? selectedNode.children.length : 0,
                    current: 1
                }
            });
        }
    };

    const getUploadProps = (pathFromClient) => {
        if (!pathFromClient) {
            // Establecer el nodo raíz como el nodo seleccionado
            const rootNode = treeData[0]; // Asegúrate de que treeData tenga al menos un nodo raíz
            if (rootNode) {
                setSelectedItem(rootNode);
            }
            return;
        }

        return {
            name: 'file',
            multiple: false, // Solo permitir un archivo a la vez
            listType: 'text', // Tipo de lista de archivos que se mostrará
            openFileDialogOnClick: true,
            showUploadList: true, // Mostrar la lista de archivos
            maxCount: 1,
            beforeUpload: (file) => {
                // Almacenar el archivo temporalmente y evitar la subida automática
                setFileToUpload(file);
                return false; // Previene la carga automática
            },
            onChange(info) {
                const { fileList } = info;

                // Obtener el último archivo en la lista
                const file = fileList[fileList.length - 1];

                if (fileList.length > 0) {
                    // Solo mantener el último archivo cargado
                    const updatedFileList = [file];


                    // Actualiza la lista de archivos en el estado
                    setFileList(prevFileList => {
                        // Limpiar la lista de archivos y agregar el nuevo archivo
                        return [file];
                    });

                    setFileList(updatedFileList);

                    // Maneja los mensajes de estado del archivo
                    if (file.status === 'done') {
                        message.success(`${file.name} archivo cargado exitosamente.`);
                    } else if (file.status === 'error') {
                        message.error(`${file.name} fallo en la carga.`);
                    }
                } else {
                    // Limpiar la lista si no hay archivos

                    setFileList([]);
                }
            }

        };
    };


    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1, // Página actual
            pageSize: 30, // Tamaño de página
            total: 0, // Total de elementos
        },
    });

    const handleEditFolder = async (path, nuevoNombre) => {
        const nodeRaiz = 'DocumentosPOA';
        const regex = /^[a-zA-Z0-9 _áéíóúÁÉÍÓÚñÑ.,()-´]+$/;


        if (!regex.test(nuevoNombre)) {
            message.error('El nombre contiene caracteres no permitidos.');
            return; // Salir de la función si el nombre no es válido
        }


        const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
        const codActividadParametro = 'actividad_' + tmpcodActividadParametro;

        // Verifica si `path` contiene el substring 'actividad'

        const shouldAssignPath = !path.includes('actividad');

        // Encuentra el último "/" y corta el path antes de él
        const lastSlashIndex = path.lastIndexOf('/');
        const basePath = path.substring(0, lastSlashIndex); // Obtiene la ruta sin la última carpeta




        const tmpRuta = shouldAssignPath ? `${codActividadParametro}/${basePath}/${nuevoNombre}` : path;
        const oldPath = shouldAssignPath ? `${codActividadParametro}/${path}` : path;

        // Encuentra el último "/" y corta el path antes de él
        const fatherlastSlashIndex = tmpRuta.lastIndexOf('/');
        const fatherResource = tmpRuta.substring(0, fatherlastSlashIndex); // Obtiene la ruta sin la última carpeta

        // Llama a la función de registro
        const resourceInfo = await handleAction({
            user: userLogin, // Reemplaza con el usuario actual
            actionType: 'updateFolder',
            nameServer: nuevoNombre, // Nombre del servidor
            resourceDetails: {
                nameResource: nuevoNombre,
                Path: tmpRuta,
                fatherResource: `${nodeRaiz}/${fatherResource}`,
                typeResource: 'folder',
                key: `${nodeRaiz}/${tmpRuta}`,
                fullPath: `${nodeRaiz}/${tmpRuta}`,
                resourceType: 'Folder POA'
            }
        });
        const formData = new FormData();
        formData.append('ruta', oldPath);
        formData.append('nuevoNombre', nuevoNombre);
        formData.append('nodeRaiz', nodeRaiz);
        formData.append('resourceInfo', JSON.stringify(resourceInfo));

        try {
            const response = await axios.post(`${baseUrl}/api/editNameDirectories`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=UTF-8',
                }
            });

            if (response.status === 200) {
                // Actualiza el nombre en el árbol de datos localmente

                const tmpRuta = response.data.nuevoPath;
                const newPath = tmpRuta.replace(/actividad_\d+\//, '');

                // Llama a updateNodeName y espera su ejecución
                const wasUpdated = await updateNodeName(nuevoNombre, newPath);

                if (wasUpdated) {
                    await reloadTree();
                    message.success('Nombre de la carpeta actualizado correctamente.');
                }

            } else {
                // Puedes mostrar el mensaje de error según la respuesta del servidor
                message.error('Error al actualizar el nombre de la carpeta. Inténtalo de nuevo.');
            }
        } catch (error) {

            console.error('Error fetching file tree:', error);
            message.error('Error al actualizar el nombre de la carpeta. Inténtalo de nuevo.');
        }
    };

    const updateNodeName = async (newName, newPath) => {
        if (!selectedItem) {
            return; // Salir si no hay ningún nodo seleccionado
        }

        // Realizar una copia profunda de los datos del árbol para evitar mutaciones directas
        const updatedTreeData = cloneDeep(treeData);

        // Función recursiva para buscar y actualizar el nombre y path del nodo en el árbol
        const updateNode = (nodes, keyToUpdate, newName, newPath) => {
            for (let node of nodes) {
                if (node.key === keyToUpdate) {
                    node.name = newName; // Actualizar el nombre del nodo encontrado
                    node.serverFileName = newName; // Actualizar el nombre del nodo encontrado
                    node.viewName = newName;
                    node.key = newPath; // Actualizar la clave usando el nuevo path

                    if (node.path) {
                        node.path = newPath; // Actualizar el path del nodo encontrado
                    }

                    // Actualizar los hijos con el nuevo path
                    if (node.children) {
                        updateChildrenPaths(node.children, newPath);
                    }

                    return true; // Indicar que se actualizó correctamente
                }
                if (node.children) {
                    if (updateNode(node.children, keyToUpdate, newName, newPath)) {
                        return true; // Si se actualizó en un nodo hijo
                    }
                }
            }
            return false; // No se encontró el nodo para actualizar
        };

        const updateChildrenPaths = (children, newPath) => {
            children.forEach((child) => {
                const newChildPath = `${newPath}/${child.name}`;
                child.path = newChildPath; // Actualizar el path del hijo
                child.key = newChildPath; // Usar el nuevo path como clave

                // Llamar recursivamente si hay más hijos
                if (child.children) {
                    updateChildrenPaths(child.children, newChildPath);
                }
            });
        };


        // Llamar a la función para actualizar el nodo en el árbol clonado
        const wasUpdated = updateNode(updatedTreeData, selectedItem.key, newName, newPath);

        if (wasUpdated) {
            // Actualizar el estado del árbol con los datos actualizados
            setTreeData([...updatedTreeData]);

            const updatedSelectedItem = {
                ...selectedItem,
                name: newName,
                key: newPath,
                path: newPath,
                pathFromClient: newPath,
                viewName: newName,
            };
            setSelectedItem(updatedSelectedItem);

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    current: 1, // Reinicia a la primera página
                },
            });

            // Salir del modo de edición
            setIsEditing(false);
        } else {

            message.error('No se pudo actualizar el nodo.');
        }
    };
    const toggleEditMode = () => {
        setIsEditing(!isEditing);

        if (!isEditing) {
            setOriginalName(selectedItem.viewName);
            setNewName(selectedItem.viewName); // Restablecer newName al nombre original
        }
    };

    const handleNameChange = (e) => {

        let value = e.target.value.normalize('NFC'); // Normalizar a forma compuesta

        // Define los caracteres permitidos (letras, números, espacios y guiones bajos)
        const regex = /^[a-zA-Z0-9 _áéíóúÁÉÍÓÚñÑ.,()-´]+$/;

        if (regex.test(value) || value === '') {
            setNewFolderName(value);
        } else {
            message.error('El nombre contiene caracteres no permitidos 1.');
        }
    };

    const handleEditNameChange = (e) => {
        // Normalizamos el valor para manejar caracteres compuestos
        const value = e.target.value.normalize('NFC');
        // Define los caracteres permitidos (incluye letras con tildes, ñ, y caracteres especiales básicos)
        const regex = /^[a-zA-Z0-9 _áéíóúÁÉÍÓÚñÑ.,()-´]+$/;


        // Si el valor cumple con la expresión regular o está vacío (permite limpiar el campo)
        if (regex.test(value) || value === '') {
            setNewName(value); // Actualiza el valor del nombre
        } else {
            message.error('El nombre contiene caracteres no permitidos.');
        }
    };



    const cancelEditMode = () => {
        setIsEditing(false);
        setNewName(selectedItem.viewName); // Restablece el nombre al original
    };

    // Función para generar un número aleatorio único
    const generateRandomUid = () => {
        return Math.random().toString(36).substr(2, 9); // Genera una cadena aleatoria de 9 caracteres
    };
    // Función para agregar un archivo al árbol de datos con una clave única
    const addFileToTree = (treeData, pathFromClient, fileToAdd, parentKey) => {
        // Hacer una copia profunda de treeData para no mutar el estado directamente
        const updatedTreeData = JSON.parse(JSON.stringify(treeData));
        // Función recursiva para buscar y agregar el archivo en el árbol
        const addToNode = (nodes, path, file, parentKey) => {
            for (let node of nodes) {
                if (node.path === path) {
                    // Encontrar el nodo correcto y agregar el archivo como hijo
                    if (!node.children) {
                        node.children = [];
                    }

                    // Generar una clave única para el nuevo archivo
                    const newKey = `${node.key} - ${file.uid}`;

                    node.children.push({
                        name: file.name,
                        key: newKey,
                        type: 'file', // Ajustar según la estructura de tu árbol
                        level: node.level + 1, // Ajustar el nivel del nodo según tu estructura
                        path: `${path}/${file.serverFileName}`, // Asegúrate de usar el nombre del servidor aquí
                        pathFromClient: file.pathFromClient, // Agregar pathFromClient
                        uid: file.uid, // Agregar uid
                        serverFileName: file.serverFileName,
                        viewName: file.name,
                        typeS: 'Soporte',
                        isLeaf: true,
                    });
                    return true; // Indicar que se agregó el archivo correctamente
                }
                if (node.children && addToNode(node.children, path, file, parentKey)) {
                    return true; // Si se encontró y se agregó en un nodo hijo
                }
            }
            return false; // No se encontró el nodo para agregar el archivo
        };

        // Llamar a la función para agregar el archivo en el árbol
        addToNode(updatedTreeData, pathFromClient, fileToAdd, parentKey);

        return updatedTreeData; // Devolver el árbol actualizado
    };

    const deleteNodeFromTree = (nodes, pathToDelete) => {
        let parentKey = null;

        const deleteNodeRecursively = (nodes, pathToDelete) => {
            return nodes
                .map(node => {
                    // Mostrar el nodo actual y el path que se quiere eliminar

                    // Verifica que el nodo actual tenga la propiedad 'path'
                    if (node && node.path === pathToDelete) {

                        return null; // Eliminar el nodo actual
                    }

                    // Verifica si el nodo tiene hijos
                    if (Array.isArray(node.children)) {
                        const updatedChildren = deleteNodeRecursively(node.children, pathToDelete);

                        // Verificar si se han eliminado todos los hijos
                        if (updatedChildren.length === 0) {
                            parentKey = node.key;

                            return { ...node, children: [] }; // Mantener nodo vacío si sus hijos están vacíos
                        }


                        return { ...node, children: updatedChildren }; // Devolver nodo con hijos actualizados
                    }

                    // Devolver nodo sin cambios si no tiene hijos
                    return node;
                })
                .filter(node => node !== null); // Filtrar nodos nulos
        };

        const updatedTreeData = deleteNodeRecursively(nodes, pathToDelete);
        return { updatedTreeData, parentKey };
    };




    // Función para encontrar un nodo en el árbol por clave
    const findNodeByKeyF = (nodes, key) => {
        for (const node of nodes) {


            if (node.key === key) {

                return node;
            }
            if (node.children) {
                const foundNode = findNodeByKeyF(node.children, key);
                if (foundNode) {
                    return foundNode;
                }
            }
        }

        return null;
    };


    const handleDeleteFile = async (file) => {

        const nodeRaiz = 'DocumentosPOA';
        const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
        const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
        // Obtener la ruta del archivo
        const filePath = file.path
            ? file.path.substring(0, file.path.lastIndexOf('/') + 1) + file.serverFileName
            : (file.pathFromClient && file.serverFileName
                ? `${file.pathFromClient}/${file.serverFileName}`
                : null);

        let filePathNode = file.path.substring(0, file.path.lastIndexOf('/') + 1) + file.name;


        if (!filePath) {
            message.error('No se encontró el nombre del archivo en el servidor.');
            return;
        }

        let tmpFilepathserver = codActividadParametro + '/' + filePath;
        const resourceInfo = await handleAction({
            user: userLogin,
            actionType: 'deleteFile',
            nameServer: file.serverFileName,
            resourceDetails: {
                nameResource: file.serverFileName,
                Path: tmpFilepathserver,
                fatherResource: selectedItem.name,
                typeResource: 'file',
                key: file.path,
                fullPath: `${nodeRaiz}/${tmpFilepathserver}`
            }
        });



        // Eliminar el archivo del servidor
        const deleted = await deleteFile(tmpFilepathserver, nodeRaiz, resourceInfo);

        if (deleted) {
            // Actualizar el estado de selectedItem eliminando el archivo
            // Eliminación exitosa en el backend, actualiza el árbol localmente

            const { updatedTreeData, parentKey } = deleteNodeFromTree(treeData, filePathNode);

            // Actualizar el estado del árbol
            setTreeData(updatedTreeData);

            // Seleccionar el nodo padre o el nodo raíz si no tiene padre
            if (parentKey) {
                const parentNode = findNodeByKey(updatedTreeData, parentKey);
                if (parentNode) {
                    setSelectedItem(parentNode);
                }
            } else {
                const rootNode = updatedTreeData[0];
                if (rootNode) {
                    setSelectedItem(rootNode);
                }
            }

            // Si selectedItem está definido, actualizar sus children excluyendo el archivo eliminado
            if (selectedItem) {
                const updatedChildren = selectedItem.children.filter(child => child.path !== filePathNode);
                setSelectedItem({ ...selectedItem, children: updatedChildren });
            }
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    current: 1, // Reinicia a la primera página
                },
            });


            message.success('Archivo eliminado correctamente.');
        }
    };

    const deleteFile = async (filePath, nodeRaiz, data) => {
        const formData = new FormData();
        formData.append('filePath', filePath);
        formData.append('nodeRaiz', nodeRaiz);
        formData.append('resourceInfo', JSON.stringify(data));

        try {
            const responsedelete = await fetch(`${baseUrl}/api/delete-file`, {
                method: 'POST',
                body: formData,
            });
            const data = await responsedelete.json();
            if (data.status === 'success') {

                message.success('Archivo eliminado correctamente.');
                return true;
            } else {
                message.error('Error al eliminar el archivo, vuelve a intentarlo.');
                return false;
            }
        } catch (error) {
            message.error('Error al eliminar el archivo, vuelve a intentarlo.');
            return false;
        }
    };


    const descriptionDirectories = [
        { key: "Ficha para la revisión", description: "Ficha para la revisión de iniciativas a estructurar por ART." },
        { key: "Priorización de territorios", description: "Priorización de territorios, cadenas de valor y comunidades para proyectos estructurados según el PME y otras fuentes." },
        { key: "Acta Paso 4", description: "Acta de mesa técnica con ART del Paso 4." },
        { key: "Documento de análisis", description: "Documento de análisis para definición de potenciales alcances y socios del proyecto a estructurar." },
        { key: "Acta Paso 5", description: "Acta de mesa técnica con ART del Paso 5." },
        { key: "Asistencia taller Marco Lógico", description: "Listado de asistencia desagregados por sexo y pertenencia étnica del Taller." },
        { key: "Memoria Marco Lógico", description: "Memoria de Reunión del Taller de insumos de Marco Lógico con comunidad." },
        { key: "Documento del Proyecto", description: "Documento de identificación general del proyecto." },
        { key: "Acta Paso 6", description: "Acta de mesa técnica con ART del Paso 6." },
        { key: "Caracterización de población", description: "Caracterización de población participante." },
        { key: "Instrumento MER", description: "Instrumento Medición del Emprendimiento Rural – MER" },
        { key: "Formato de campo", description: "Formato de campo diligenciado para el componente de infraestructura." },
        { key: "Ficha de evaluación técnica", description: "Ficha de evaluación técnica para proyectos de infraestructura productiva diligenciada." },
        { key: "Homologación MER-ICO", description: "Homologación MER-ICO aplicado" },

    ];

    const extractDateFromFilename = (filename) => {
        // Usa una expresión regular para encontrar la fecha en el nombre del archivo
        const dateMatch = filename.match(/(\d{2})(\d{2})(\d{4})/);
        if (dateMatch) {
            // Convierte la fecha a un formato que se pueda comparar fácilmente
            const [_, day, month, year] = dateMatch;
            return new Date(`${year}-${month}-${day}`);
        }
        return new Date(0); // Devuelve una fecha predeterminada si no se encuentra una fecha
    };


    const normalizeString = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    const getDirectoryDescription = () => {
        if (selectedItem && selectedItem.type === 'folder') {
            const selectedKey = normalizeString(selectedItem.name); // Normaliza el nombre del directorio seleccionado
            const directory = descriptionDirectories.find(dir => {
                const normalizedKey = normalizeString(dir.key);
                return normalizedKey === selectedKey;
            });

            return directory ? directory.description : ""; // Retornar la descripción si se encuentra, de lo contrario cadena vacía
        }
        return "";
    };

    const handleTableChange = (pagination) => {
        setTableParams({
            ...tableParams,
            pagination
        });
    };


    const renderSelectedFiles = () => {
        if (selectedItem && selectedItem.type === 'folder') {
            return (
                <Table
                    dataSource={selectedItem.children}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    scroll={{
                        y: 340,
                    }}
                    columns={[

                        {
                            title: 'Opciones',
                            key: 'action',
                            width: 200,
                            align: 'center',
                            render: (text, record) => (
                                <Space size="middle">
                                    {permissionExport && (
                                        <Tooltip title="Descargar">
                                            <Popconfirm
                                                title='Descargar archivos'
                                                description="¿Segur@ que quieres descargar el directorio?"
                                                onConfirm={() => handleDescargar(record)}
                                                okText="Sí"
                                                cancelText="No"
                                                okButtonProps={{
                                                    loading: loading,
                                                }}
                                            >
                                                <Button
                                                    type="link"
                                                    icon={<DownloadOutlined style={{ fontSize: '1.1rem' }} />}
                                                    rel="noopener noreferrer"
                                                    className="ico_table"
                                                />

                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                    {record.type === 'file' && (
                                        <>
                                            {permissionDelete && (
                                                <Tooltip title="Eliminar archivo">
                                                    <Popconfirm
                                                        title='Eliminación de archivo'
                                                        description="¿Segur@ que quieres eliminar este archivo?"
                                                        onConfirm={() => handleDeleteFile(record)}
                                                        okText="Sí"
                                                        cancelText="No"
                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                    >
                                                        <Button
                                                            type="link"
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            className='ico_table'
                                                        />
                                                    </Popconfirm>
                                                </Tooltip>
                                            )}

                                            <div>
                                                <Tooltip title="Visualizar el archivo">
                                                    <Button onClick={() => fetchFileUrl(record)} style={{ border: 'none', background: 'none', cursor: 'pointer', padding: '10px !important' }}>
                                                        <EyeOutlined style={{ fontSize: '24px', color: '#08c' }} />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )}

                                    {record.canDelete && permissionDelete && (

                                        <Tooltip title="Eliminar del directorio">
                                            <Popconfirm
                                                title='Eliminación de directorio'
                                                description="¿Segur@ que quieres eliminar este directorio, no podras revertir esta operación?"
                                                onConfirm={() => handleDeleteFolder(record.path, record)}
                                                okText="Sí"
                                                cancelText="No"
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            >
                                                <Button
                                                    type="link"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    className='me-2 p-0'

                                                />
                                            </Popconfirm>
                                        </Tooltip>
                                    )
                                    }

                                </Space>
                            ),
                        },
                        {
                            title: 'Documento',
                            dataIndex: 'name',
                            key: 'key',
                            width: 400,
                            sorter: (a, b) => {
                                // Extrae y compara las fechas de los nombres de los archivos
                                const dateA = extractDateFromFilename(a.viewName);
                                const dateB = extractDateFromFilename(b.viewName);
                                return dateB - dateA; // Ordenar del más reciente al más antiguo
                            },
                            render: (text, record) => {
                                const maxLength = 50;
                                const maxLengthTooltip = 200;
                                let truncatedTitle;

                                if (record.type === 'file') {
                                    truncatedTitle = processFileName(record.viewName, maxLength);
                                } else {
                                    truncatedTitle = record.viewName;
                                }
                                const truncatedTooltip = processFileName(record.viewName, maxLengthTooltip);

                                return (
                                    <Tooltip title={truncatedTooltip} placement="right">
                                        <span>
                                            {record.type === 'folder' ? <FolderOutlined /> : <FileOutlined />}
                                            {` ${truncatedTitle}`}
                                        </span>
                                    </Tooltip>
                                );
                            }
                        },
                        {
                            title: 'Formato',
                            key: 'extension',
                            width: 100,
                            align: 'center',
                            render: (text, record) => {
                                // Verifica si record.name es una cadena de texto
                                if (typeof record.name === 'string') {
                                    if (record.type === 'folder' && typeof record.fileCount !== 'undefined') {
                                        return (
                                            <>
                                                <Tooltip title="Cantidad de archivos que contiene la carpeta">
                                                    <span>Folder</span> <span></span>
                                                    <Badge
                                                        className="site-badge-count-109"
                                                        count={record.fileCount}
                                                        overflowCount={9999}
                                                        style={{
                                                            backgroundColor: '#52c41a',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </>
                                        );
                                    } else if (record.type === 'folder' && record.children.some(child => child.type === 'file')) {
                                        return (
                                            <>
                                                <Tooltip title="Cantidad de archivos que contiene la carpeta">
                                                    <span>Folder</span> <span>  </span>
                                                    <Badge
                                                        className="site-badge-count-109"
                                                        count={record.children.length}
                                                        style={{
                                                            backgroundColor: '#52c41a',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </>
                                        );
                                    } else {
                                        if (record.type === 'folder') {
                                            return 'Folder'; // O cualquier otro mensaje adecuado para carpetas vacías
                                        } else {
                                            const fileNameParts = record.name.split('.');
                                            return fileNameParts.length > 1 ? fileNameParts.pop() : 'Archivo';
                                        }
                                    }
                                } else {
                                    // Maneja el caso en que record.name no es una cadena de texto
                                    return 'Desconocido'; // O cualquier otro valor predeterminado adecuado
                                }
                            },
                        },
                        {
                            title: 'Tipo de soporte',
                            dataIndex: 'name',
                            key: 'key',
                            width: 150,
                            render: (text, record) => {
                                let tipoSoporte = '-'; // Valor por defecto

                                if (record.metadato) {
                                    try {
                                        // Parseamos detailJSON para convertirlo en un objeto
                                        const detailData = JSON.parse(record.metadato.detailJSON);

                                        // Accedemos a resourceType
                                        tipoSoporte = detailData.resourceData ? detailData.resourceData.resourceType : 'No reportado';
                                    } catch (error) {
                                        console.error('Error al parsear detailJSON:', error);
                                        tipoSoporte = 'Error en datos';
                                    }
                                }

                                return (
                                    <span>
                                        {tipoSoporte}
                                    </span>
                                );
                            }
                        },
                        {
                            title: 'Usuario',
                            dataIndex: 'name',
                            key: 'key',
                            width: 150,
                            render: (text, record) => {

                                const usuario = record.metadato ? record.metadato.user : '-'; // Puedes ajustar el texto en caso de que sea null

                                return (
                                    <span>
                                        {usuario}
                                    </span>
                                );
                            }
                        },
                        {
                            title: 'Creación',
                            dataIndex: 'name',
                            key: 'key',
                            width: 150,
                            render: (text, record) => {
                                // Obtenemos la fecha y la formateamos
                                const fecha = record.metadato ? record.metadato.dateTime : '-'; // Obtiene la fecha
                                const creacion = fecha !== '-' ? new Date(fecha).toISOString().replace('T', ' ').substring(0, 19) : '-'; // Formateo

                                return (
                                    <span>
                                        {creacion}
                                    </span>
                                );
                            }
                        },


                    ]}
                />
            );
        } else {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <WarningOutlined style={{ color: 'red', marginRight: '8px', fontSize: '2rem' }} />
                    <h3>Seleccione el siguiente nivel del árbol para ver los archivos.</h3>
                </div>
            );
        }
    };

    // Función para volver a cargar el árbol
    const reloadTree = async () => {
        setLoading(true); // Asegúrate de establecer el estado de carga si es necesario
        try {
            const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
            const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
            const response = await axios.get(`${baseUrl}/api/directorytreepoa?codigActividad=${codActividadParametro}&codigoTarea=${codigo_proyecto_busqueda}`);

            const data = response.data;

            const mv = data[0].children[0].children[0].name; // Nombre medio Verificacion
            const indicador = codigo_tarea.codigo_tarea.key;
            const fullPath = "DocumentosPOA/" + codActividadParametro + "/" + codigo_proyecto_busqueda + "/" + indicador + "/" + mv;
            const documents = await fetchPoaDataByPath(fullPath);

            setDataFile(documents);


            // Función para filtrar y obtener solo los children de los nodos tipo 'indicador'
            const getIndicadorChildren = (nodes) => {
                return nodes.flatMap(node => {
                    if (node.typeS === 'indicador') {
                        return node.children;
                    } else if (node.children && node.children.length > 0) {
                        return getIndicadorChildren(node.children);
                    }
                    return [];
                });
            };

            const filteredData = getIndicadorChildren(data);

            // Actualiza los datos del árbol
            setTreeData(filteredData);

            // Expandir solo el nodo raíz
            // Conservar el selectedItem actual o actualizarlo si sigue en el nuevo árbol
            if (selectedItem) {
                const existingNode = findNodeByKeyF(filteredData, selectedItem.key);
                if (existingNode) {
                    setSelectedItem(existingNode); // Mantiene el nodo seleccionado si existe
                    setExpandedKeys([existingNode.key]); // Expande el nodo encontrado
                } else {
                    setSelectedItem(null); // O establece a null si no existe
                    setExpandedKeys([filteredData[0]?.key]); // Expande el nodo raíz si no hay nodo seleccionado
                }
            } else {
                // Si no hay selectedItem, expande solo el nodo raíz
                setExpandedKeys([filteredData[0]?.key]);
            }


        } catch (error) {
            console.error('Error fetching file tree:', error);
        } finally {
            setLoading(false);
        }
    };

    const onLimpiarBusqueda = async () => {
        setActiveButton(null);
        setSearchValue('');
        setSearchValueCod('');
        setRootNodeSelected(true);
        setSelectedItem(null);
        setLoading(true);

        try {
            const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
            const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
            const response = await axios.get(`${baseUrl}/api/directorytreepoa?codigActividad=${codActividadParametro}&codigoTarea=${codigo_proyecto_busqueda}`);

            const data = response.data;

            const mv = data[0].children[0].children[0].name; // Nombre medio Verificacion
            const indicador = codigo_tarea.codigo_tarea.key;
            const fullPath = "DocumentosPOA/" + codActividadParametro + "/" + codigo_proyecto_busqueda + "/" + indicador + "/" + mv;
            const documents = await fetchPoaDataByPath(fullPath);

            setDataFile(documents);


            // Función para filtrar y obtener solo los children de los nodos tipo 'indicador'
            const getIndicadorChildren = (nodes) => {
                return nodes.flatMap(node => {
                    if (node.typeS === 'indicador') {
                        return node.children;
                    } else if (node.children && node.children.length > 0) {
                        return getIndicadorChildren(node.children);
                    }
                    return [];
                });
            };

            const filteredData = getIndicadorChildren(data);

            // Actualiza los datos del árbol
            setTreeData(filteredData);

            // Expandir solo el nodo raíz
            setExpandedKeys([filteredData[0]?.key]);

        } catch (error) {
            console.error('Error fetching file tree:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteFolder = async (path, data) => {

        const nodeRaiz = 'DocumentosPOA';
        const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
        const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
        const tmpRuta = `${codActividadParametro}/${path}`;

        try {

            const nameServer = data.serverFileName && data.serverFileName.trim() !== '' ? data.serverFileName : data.name;

            const resourceInfo = await handleAction({
                user: userLogin, // Reemplaza con el usuario actual
                actionType: 'deleteFolder',
                nameServer: nameServer, // Nombre del servidor
                resourceDetails: {
                    nameResource: nameServer,
                    Path: tmpRuta,
                    fatherResource: data.name,
                    typeResource: 'folder',
                    key: data.path,
                    fullPath: `${nodeRaiz}/${data.path}`
                }
            });

            // Convertir resourceInfo a JSON string y usar FormData
            const formData = new FormData();
            formData.append('dirPath', tmpRuta);
            formData.append('nodeRaiz', nodeRaiz);
            formData.append('resourceInfo', JSON.stringify(resourceInfo));

            const response = await axios.post(`${baseUrl}/api/delete-directory`, formData);

            if (response.status === 200) {
                // Eliminación exitosa en el backend, actualiza el árbol localmente
                const { updatedTreeData, parentKey } = deleteNodeFromTree(treeData, path);

                // Actualizar el estado del árbol
                setTreeData(updatedTreeData);

                // Seleccionar el nodo padre o el nodo raíz si no tiene padre
                if (parentKey) {
                    const parentNode = findNodeByKey(updatedTreeData, parentKey);
                    if (parentNode) {
                        setSelectedItem(parentNode);
                    }
                } else {
                    const rootNode = updatedTreeData[0];
                    if (rootNode) {
                        setSelectedItem(rootNode);
                    }
                }

                // Si selectedItem está definido, actualizar sus children excluyendo el archivo eliminado
                if (selectedItem) {
                    const updatedChildren = selectedItem.children.filter(child => child.path !== path);
                    setSelectedItem({ ...selectedItem, children: updatedChildren });
                }
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        current: 1, // Reinicia a la primera página
                    },
                });

                message.success('Directorio y sus contenidos eliminados correctamente.');
            } else {
                message.error('Error al eliminar el directorio y sus contenidos. Inténtalo de nuevo.');
            }
        } catch (error) {
            console.error('Error al eliminar el directorio:', error);
            message.error('Error al eliminar el directorio y sus contenidos. Inténtalo de nuevo.');
        }
    };

    // Función para encontrar un nodo por su clave en el árbol
    const findNodeByKey = (nodes, key) => {
        for (const node of nodes) {
            if (node.key === key) {
                return node;
            }
            if (node.children) {
                const found = findNodeByKey(node.children, key);
                if (found) return found;
            }
        }
        return null;
    };

    // Modificación en deleteNodeFromTree para retornar el parentKey

    const findNode = (nodes, path) => {
        // Recorrer los nodos para encontrar el nodo con la ruta proporcionada
        for (let node of nodes) {
            if (node.path === path) {
                return node; // Devolver el nodo si se encuentra
            } else if (node.children) {
                // Si tiene hijos, buscar recursivamente en los hijos
                const foundNode = findNode(node.children, path);
                if (foundNode) return foundNode; // Devolver el nodo encontrado en los hijos
            }
        }
        return null; // Devolver null si no se encuentra el nodo con la ruta especificada
    };


    const titleRenderer = (node) => {
        const maxLength = 20; // Define el número máximo de caracteres
        let decodedFileName;

        let nombre;
        let truncatedTitle;

        if (node.type === 'folder') {

            nombre = node.viewName;
            truncatedTitle = truncateText(nombre, maxLength);;

        } else {

            decodedFileName = decodeURIComponent(node.viewName);
            nombre = cleanFileName(decodedFileName);
            truncatedTitle = truncateText(nombre, maxLength);

        }

        return (
            <Tooltip title={nombre} placement="right" style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <span
                    style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
                    className={node.highlighted ? 'highlightedNode' : ''}
                >
                    {truncatedTitle}
                </span>
            </Tooltip>
        );
    };




    const fetchFileUrl = (path) => {
        // Verificar si serverFileName no existe o está vacío
        let serverFileName = path.serverFileName;
        // Extraer el nombre del archivo del path completo
        const pathParts = path.path.split('/');
        let pathPartsExtrac = pathParts.pop(); // Extrae el último segmento, que sería el nombre del archivo
        // Limpia path para dejar solo el directorio
        let directorio = pathParts.join('/') + '/';
        // Construir el nuevo path
        const nuwPath = `${directorio}${serverFileName}`;
        // Procesar el código de la actividad
        const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
        const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
        // Construir el directorio base
        const baseDir = `${baseUrl}/DocumentosFAO/DocumentosPOA/${codActividadParametro}`;
        // Construir la URL completa del archivo
        const fileUrl = `${baseDir}/${nuwPath}`;
        // Abrir la URL en una nueva pestaña
        window.open(fileUrl, '_blank');
    };


    // Lógica de filtrado sin archivos
    const filterEmptyFolders = (treeData) => {
        // Función para determinar si un nodo es una carpeta final sin archivos
        const isEmptyFinalFolder = (node) => {

            if (node.type !== 'folder') {
                // Si es un archivo, no se considera como una carpeta final vacía

                return false;
            }

            // Si es una carpeta final sin archivos
            if (!node.children || node.children.length === 0) {

                return true;
            }


            // Verificar si todas las subcarpetas no tienen archivos
            const allSubfoldersEmpty = node.children.every(child => isEmptyFinalFolder(child));

            return allSubfoldersEmpty;
        };

        // Función para filtrar el árbol, manteniendo las ramas que contienen carpetas finales vacías
        const filterTree = (nodes) => {
            return nodes
                .map(node => {

                    // Filtrar los hijos recursivamente
                    const filteredChildren = node.children ? filterTree(node.children) : [];


                    // Determinar si la carpeta debe ser mantenida
                    if (node.type === 'folder') {
                        // Mantener la carpeta si es una carpeta final vacía o si tiene hijos que no se deben eliminar
                        const shouldKeep = isEmptyFinalFolder(node) || filteredChildren.length > 0;

                        return shouldKeep ? { ...node, children: filteredChildren } : null;
                    }

                    // Si es un archivo, no se debe mantener

                    return null;
                })
                .filter(node => node !== null); // Elimina nodos nulos
        };

        // Filtrar los datos del árbol a partir de la raíz
        const filteredTreeData = filterTree(treeData);


        return filteredTreeData;
        // setTreeData(filteredTreeData); // Actualiza el estado con los datos filtrados
    };

    //Logica del LOG        
    const handleAction = async ({ user, actionType, nameServer, resourceDetails }) => {
        // Usa logAction para crear el JSON
        const logEntry = logAction(user, actionType, nameServer, resourceDetails);
        return logEntry
    };




    // Lógica de filtrado con archivos
    const filterFolders = () => {
        // Función para determinar si un nodo tiene archivos en su subárbol
        const hasFiles = (node) => {
            if (node.type === 'file') return true;

            if (node.children && node.children.length > 0) {
                return node.children.some(child => hasFiles(child));
            }

            return false;
        };

        // Usar una pila para procesar los nodos iterativamente
        const stack = [...treeData];
        const foldersToRemove = new Set(); // Conjunto de carpetas que deben eliminarse

        // Primer paso: procesar nodos para identificar carpetas sin archivos
        while (stack.length > 0) {
            const node = stack.pop();

            if (node.type === 'folder') {
                if (node.children) {
                    // Agregar los hijos a la pila
                    node.children.forEach(child => stack.push(child));

                    // Marcar la carpeta para eliminar si no tiene archivos en su subárbol
                    if (!hasFiles(node)) {
                        foldersToRemove.add(node);
                    }
                }
            }
        }

        // Función para filtrar el árbol basado en las carpetas que deben eliminarse
        const filterTree = (nodes) => {
            return nodes
                .map(node => {
                    // Filtrar los hijos recursivamente
                    const filteredChildren = node.children ? filterTree(node.children) : [];

                    // Si el nodo es una carpeta, se mantiene si no debe eliminarse
                    if (node.type === 'folder') {
                        const shouldKeep = node === treeData[0] || !foldersToRemove.has(node);
                        return shouldKeep ? { ...node, children: filteredChildren } : null;
                    }

                    // Mantener archivos
                    return node;
                })
                .filter(node => node !== null); // Elimina nodos nulos
        };

        // Filtrar los datos del árbol a partir de la raíz
        const filteredTreeData = filterTree(treeData);

        return filteredTreeData;
        // setTreeData(filteredTreeData); // Actualiza el estado con los datos filtrados
    };


    // Handler para el botón
    const handleFilterEmptyFolders = () => {

        const filteredData = filterEmptyFolders(treeData);
        setTreeData(filteredData);
        setActiveButton('emptyFolders');


    };


    // Handler para el botón
    const handleFilterFolders = () => {

        const filteredData = filterFolders(treeData);
        setTreeData(filteredData);
        setActiveButton('folders');

    };

    const treeContainerStyle = {
        height: '500px', // Ajusta esta altura según tus necesidades
        overflowY: 'auto', // Habilita el scroll vertical
        border: '1px solid #d9d9d9', // Opcional: añade un borde para definir el área del tree
        padding: '8px', // Opcional: añade un padding interno
        boxSizing: 'border-box' // Asegura que el padding no aumente el tamaño del contenedor
    };

    const handleCreateFolder = async () => {
        // Verifica si el nombre de la carpeta ya existe
        try {

            const nodeRaiz = 'DocumentosPOA';
            const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
            const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
            const tmpRuta = `${codActividadParametro}/${selectedItem.path}`;
            const response = await checkFolderName(tmpRuta, newFolderName, nodeRaiz);

            if (response.data.status === 'error') {
                message.error(response.data.message);
                return;
            }

            // Llama a la función de registro
            const resourceInfo = await handleAction({
                user: userLogin, // Reemplaza con el usuario actual
                actionType: 'createFolder',
                nameServer: newFolderName, // Nombre del servidor
                resourceDetails: {
                    nameResource: newFolderName,
                    Path: tmpRuta,
                    fatherResource: selectedItem.name,
                    typeResource: 'folder',
                    key: `${selectedItem.path}/${newFolderName}`,
                    fullPath: `${nodeRaiz}/${tmpRuta}/${newFolderName}`
                }
            });
            // Si no existe, crea la carpeta
            await createFolder(tmpRuta, newFolderName, nodeRaiz, resourceInfo);
            message.success('Carpeta creada exitosamente');

            // Actualiza el árbol localmente
            const parentPath = selectedItem.path || '';
            addNodeToTree(parentPath, newFolderName);

            // Encuentra el nodo actualizado
            const updatedNode = findNode(treeData, parentPath);

            if (updatedNode) {
                // Actualiza el estado de selectedItem para reflejar los cambios en el árbol
                setSelectedItem(prevSelectedItem => {
                    // Si la carpeta seleccionada es la misma que el nodo actualizado, actualiza sus hijos
                    if (prevSelectedItem.path === updatedNode.path) {
                        const updatedChildren = [...updatedNode.children, {
                            name: newFolderName,
                            key: `${parentPath}/${newFolderName}`,
                            path: `${parentPath}/${newFolderName}`,
                            type: 'folder',
                            isLeaf: false,
                            children: [],
                            viewName: newFolderName,
                            canDelete: true,
                        }];
                        return {
                            ...updatedNode,
                            children: updatedChildren,
                            pathFromClient: updatedNode.path
                        };
                    }
                    return prevSelectedItem;
                });

                // Actualiza dataSource de la tabla
                updateTreeFilesData(updatedNode);

            }

            setIsModalVisible(false);
            setNewFolderName('');

        } catch (error) {
            console.error('Error al crear la carpeta:', error);
            message.error('Ocurrió un error al crear la carpeta, verifique que el nombre de la Carpeta no exista.');
        }
    };


    const updateTreeFilesData = (node) => {
        if (node && node.children) {
            // Asignar los hijos del nodo seleccionado a treeFilesData
            settreeFilesData(node.children);

        } else {
            // Si no hay nodos hijos, puedes limpiar o manejar el estado de otra manera
            settreeFilesData([]);

        }
    };

    useEffect(() => {
        renderSelectedFiles();
    }, [dataFile]); // Escucha cambios en dataFile



    const addNodeToTree = (path, newFolderName) => {
        // Realizar una copia profunda de los datos del árbol para evitar mutaciones directas
        const updatedTreeData = cloneDeep(treeData);

        // Función recursiva para buscar y agregar un nuevo nodo en el árbol
        const addNode = (nodes, pathToUpdate, newFolderName) => {
            return nodes.map(node => {
                if (node.path === pathToUpdate) {
                    // Asegurarse de que node.children esté definido y sea un array
                    const children = node.children || [];
                    const newNode = {
                        name: newFolderName,
                        key: `${pathToUpdate}/${newFolderName}`,
                        path: `${pathToUpdate}/${newFolderName}`,
                        type: 'folder',
                        isLeaf: false, // Indicar que es una carpeta (no una hoja)
                        children: [], // Inicialmente sin hijos
                        edit: true,
                        canDelete: true,
                        viewName: newFolderName,
                        typeS: 'Desconocido',
                    };

                    return {
                        ...node,
                        children: [...children, newNode],
                    };
                } else if (node.children) {
                    // Recursivamente buscar en los hijos
                    return {
                        ...node,
                        children: addNode(node.children, pathToUpdate, newFolderName),
                    };
                }
                return node;
            });
        };

        // Llamar a la función para agregar el nodo en el árbol clonado
        const newTreeData = addNode(updatedTreeData, path, newFolderName);

        // Actualizar el estado del árbol con los datos actualizados
        setTreeData(newTreeData);
    };


    // Asegúrate de que selectedItem no sea null o undefined
    const typeS = selectedItem?.typeS || '';

    // Convertir a mayúsculas y eliminar guiones bajos
    const formattedTypeS = typeS
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/^\w/, c => c.toUpperCase());

    const { codActividad, nameActividad, codigo_tarea: codigoTarea, tarea, indicador } = codigo_tarea.codigo_tarea;


    // Inicializar el valor predeterminado
    let medioVerificacion = "Medio de Verificación";

    // Asegúrate de que las propiedades existen antes de intentar acceder a ellas
    if (codigo_tarea && codigo_tarea.codigo_tarea && Array.isArray(codigo_tarea.codigo_tarea.medioV)) {
        // Obtener el array de medioV
        const medioVArray = codigo_tarea.codigo_tarea.medioV;

        // Extraer los valores de medioVerificacion de cada objeto en el array
        const medioVerificaciones = medioVArray.map(item => item.medioVerificacion);

        // Si hay valores en el array, asignar el primero (o todos si prefieres)
        if (medioVerificaciones.length > 0) {
            medioVerificacion = medioVerificaciones.join(', '); // Unir los valores con una coma, o solo usa [0] para el primer valor

        }
    }

    const handleDescargar = async (node) => {

        const nodeRaiz = 'DocumentosPOA';
        const tmpcodActividadParametro = String(codigo_tarea.codigo_tarea.codActividad).replace(/\./g, '');
        const codActividadParametro = 'actividad_' + tmpcodActividadParametro;
        const tmpRuta = codActividadParametro + '/' + node.path;

        const formData = new FormData();
        formData.append('ruta', tmpRuta); // Obtener la ruta del nodo
        formData.append('nodeRaiz', nodeRaiz);

        try {
            const response = await axios.post(`${baseUrl}/api/descargar-archivos`, formData, {
                responseType: 'blob', // Indicar que la respuesta es un blob (archivo)
            });

            // Crear un objeto URL para el blob y crear un enlace de descarga
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            // Verificar el tipo de nodo y ajustar el nombre del archivo
            if (node.type === 'folder') {
                a.download = `${node.viewName}.zip`; // Nombre del archivo para descargar si es una carpeta
            } else {
                // Si es un archivo, intenta mantener la extensión original
                const contentDisposition = response.headers['content-disposition'];
                let filename = processFileName(node.viewName, 1000);
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (filenameMatch.length === 2) {
                        filename = filenameMatch[1];
                    }
                }
                a.download = filename; // Nombre del archivo para descargar si es un archivo
            }

            a.click();

            // Liberar el objeto URL creado
            window.URL.revokeObjectURL(url);
            message.info('Descarga completa de archivos');
        } catch (error) {
            message.error('Error al descargar archivos');
        }
    };


    return (
        <Layout>
            {showWarning && (
                <Alert
                    message="Advertencia"
                    description="Debe seleccionar un código de tarea para ver los medios de verificación."
                    type="warning"
                    showIcon
                    className="mb-3"
                />

            )}
            {loading ? (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="container-fluid py-0 my-2" style={{ marginBottom: '10px !important' }}>
                    <Descriptions bordered column={1} style={{ padding: '0 0 0 10px !important' }}>
                        <Descriptions.Item label="Actividad">
                            {codActividad} - {nameActividad}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tarea">
                            {codigoTarea} - {tarea}
                        </Descriptions.Item>
                        <Descriptions.Item label="Indicador">
                            {indicador}
                        </Descriptions.Item>
                        <Descriptions.Item label="Medio de verificación">
                            <div>
                                {typeof medioVerificacion === 'string'
                                    ? medioVerificacion
                                    : JSON.stringify(medioVerificacion)}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                    <br />
                    <Layout>
                        <Sider width={300} className="site-layout-background">
                            <div style={{ marginBottom: 8 }}>
                                <Input
                                    placeholder="Buscar"
                                    value={searchValue}
                                    onChange={onChange}
                                    style={{ width: 300 }}
                                    suffix={
                                        <>
                                            <Tooltip title="Buscar archivos por nombre" placement="top">
                                                <Button
                                                    type="primary"
                                                    icon={<SearchOutlined />}
                                                    onClick={onClickBuscar}
                                                    style={{ marginRight: 8 }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Restablecer filtros de carpetas" placement="top">
                                                <Button
                                                    icon={<ReloadOutlined />}
                                                    onClick={onLimpiarBusqueda}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Filtrar carpetas sin archivos" placement="top">
                                                <Button
                                                    icon={<FileExclamationOutlined />}
                                                    onClick={handleFilterEmptyFolders}
                                                    style={{
                                                        backgroundColor: activeButton === 'emptyFolders' ? 'lightblue' : 'initial',
                                                        borderColor: activeButton === 'emptyFolders' ? 'blue' : 'initial',
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Filtrar carpetas con archivos" placement="top">
                                                <Button
                                                    icon={<FileZipOutlined />}
                                                    onClick={handleFilterFolders}
                                                    style={{
                                                        backgroundColor: activeButton === 'folders' ? 'lightblue' : 'initial',
                                                        borderColor: activeButton === 'folders' ? 'blue' : 'initial',
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    }
                                />

                            </div>
                            <div style={treeContainerStyle}>

                                <DirectoryTree
                                    treeData={treeData}
                                    expandedKeys={expandedKeys}
                                    onExpand={onExpand}
                                    onSelect={handleSelect}
                                    autoExpandParent={autoExpandParent}
                                    titleRender={titleRenderer}
                                    showLine={true}
                                    multiple
                                    defaultExpandAll={true}
                                />
                            </div>
                        </Sider>
                        <Layout style={{ padding: '0px 24px 24px' }}>
                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >

                                {selectedItem && !rootNodeSelected && selectedItem.type === 'file' && (
                                    <div style={{ textAlign: 'center', padding: '20px' }}>
                                        <WarningOutlined style={{ color: 'red', marginRight: '8px', fontSize: '2rem' }} />
                                        <h4>Seleccione el padre o la carpeta principal</h4>
                                    </div>
                                )}


                                {rootNodeSelected && !selectedItem && (
                                    <div style={{ textAlign: 'center', padding: '0px', marginTop: '-5px !important' }}>
                                        <p className="titulo_proyecto display-4">Bienvenid@</p>

                                        {treeData.length > 0 ? (
                                            <div>

                                                <Table
                                                    dataSource={treeData}
                                                    pagination={tableParams.pagination}
                                                    onChange={handleTableChange}
                                                    defaultExpandAllRows={true}
                                                    scroll={{
                                                        y: 340,
                                                    }}
                                                    columns={[
                                                        {
                                                            title: 'Resumen',
                                                            dataIndex: 'name',
                                                            key: 'key',
                                                            width: 260,

                                                            render: (text, record) => {
                                                                const maxLength = 50;
                                                                const maxLengthTooltip = 200;
                                                                let truncatedTitle;

                                                                if (record.type === 'file') {
                                                                    truncatedTitle = processFileName(record.viewName, maxLength);
                                                                } else {
                                                                    truncatedTitle = record.viewName;
                                                                }
                                                                const truncatedTooltip = processFileName(record.viewName, maxLengthTooltip);

                                                                return (
                                                                    <Tooltip title={truncatedTooltip} placement="right">
                                                                        <span>
                                                                            {record.type === 'folder' ? <FolderOutlined /> : <FileOutlined />}
                                                                            {` ${truncatedTitle}`}
                                                                        </span>
                                                                    </Tooltip>
                                                                );
                                                            }













                                                        },
                                                        {
                                                            title: 'Tipo',
                                                            key: 'extension',
                                                            width: 100,
                                                            align: 'center',
                                                            render: (text, record) => {
                                                                // Verifica si record.name es una cadena de texto
                                                                if (typeof record.name === 'string') {
                                                                    if (record.type === 'folder' && typeof record.fileCount !== 'undefined') {
                                                                        return (
                                                                            <>
                                                                                <Tooltip title="Cantidad de archivos que contiene la carpeta">
                                                                                    <span>Folder</span> <span></span>
                                                                                    <Badge
                                                                                        className="site-badge-count-109"
                                                                                        count={record.fileCount}
                                                                                        overflowCount={9999}
                                                                                        style={{
                                                                                            backgroundColor: '#52c41a',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            </>
                                                                        );
                                                                    } else if (record.type === 'folder' && record.children.some(child => child.type === 'file')) {
                                                                        return (
                                                                            <>
                                                                                <Tooltip title="Cantidad de archivos que contiene la carpeta">
                                                                                    <span>Folder</span> <span>  </span>
                                                                                    <Badge
                                                                                        className="site-badge-count-109"
                                                                                        count={record.children.length}
                                                                                        style={{
                                                                                            backgroundColor: '#52c41a',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            </>
                                                                        );
                                                                    } else {
                                                                        if (record.type === 'folder') {
                                                                            return 'Folder'; // O cualquier otro mensaje adecuado para carpetas vacías
                                                                        } else {
                                                                            const fileNameParts = record.name.split('.');
                                                                            return fileNameParts.length > 1 ? fileNameParts.pop() : 'Archivo';
                                                                        }
                                                                    }
                                                                } else {
                                                                    // Maneja el caso en que record.name no es una cadena de texto
                                                                    return 'Desconocido'; // O cualquier otro valor predeterminado adecuado
                                                                }
                                                            },

                                                        },

                                                    ]}

                                                />
                                            </div>

                                        ) : (
                                            <>
                                                <h4 className='text-center mb-2'>Seleccione alguna opción del menu para navegar por los archivos</h4>
                                            </>)
                                        }

                                    </div>
                                )}
                                {selectedItem && !rootNodeSelected && selectedItem.type !== 'file' && (
                                    <Space direction="vertical" style={{ width: '100%' }} size="large">
                                        <Row gutter={0}>
                                            <Col span={24}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '14px' }}>

                                                    <div style={{ flex: '1 1 auto' }}>
                                                        {isEditing ? (
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                                                <Input
                                                                    value={newName}
                                                                    onChange={handleEditNameChange}
                                                                    onBlur={() => {
                                                                        if (newName.trim() !== '') {
                                                                            handleEditFolder(selectedItem.path, newName.trim());
                                                                            toggleEditMode();
                                                                        } else {
                                                                            message.error('El nombre no puede estar vacío.');
                                                                            cancelEditMode();
                                                                        }
                                                                    }}
                                                                    onPressEnter={() => {
                                                                        if (newName.trim() !== '') {
                                                                            handleEditFolder(selectedItem.path, newName.trim());
                                                                            toggleEditMode();
                                                                        } else {
                                                                            message.error('El nombre no puede estar vacío.');
                                                                            cancelEditMode();
                                                                        }
                                                                    }}
                                                                    style={{ width: '85%' }}
                                                                />
                                                                <Button
                                                                    type="link"
                                                                    icon={<SaveOutlined />}
                                                                    onClick={() => {
                                                                        if (newName.trim() !== '') {
                                                                            handleEditFolder(selectedItem.path, newName.trim());
                                                                            toggleEditMode();
                                                                        } else {
                                                                            message.error('El nombre no puede estar vacío.');
                                                                            cancelEditMode();
                                                                        }
                                                                    }}
                                                                    className='btn btn-primary py-0'
                                                                >
                                                                    Guardar
                                                                </Button>
                                                                <Button
                                                                    type="link"
                                                                    icon={<CloseOutlined />}
                                                                    onClick={cancelEditMode}
                                                                    className='btn btn-danger py-0'
                                                                >
                                                                    Cancelar
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {formattedTypeS !== 'Desconocido' ? (
                                                                    <strong>{formattedTypeS}: </strong>
                                                                ) : (
                                                                    <strong>Folder: </strong>
                                                                )}
                                                                <strong>{selectedItem.viewName}</strong>
                                                            </>
                                                        )}
                                                    </div>
                                                    {!isEditing && (
                                                        <div style={{ display: 'inline-flex', justifyContent: 'flex-end', gap: '6px' }}>
                                                            {permissionUpdate && selectedItem.typeS !== 'medio_verificacion' && (
                                                                <Tooltip title="Editar nombre de la carpeta">
                                                                    <Button
                                                                        type="link"
                                                                        icon={<EditOutlined />}
                                                                        onClick={toggleEditMode}
                                                                        className='btn btn-primary py-0'
                                                                    >

                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                            {permissionPrint && (
                                                                <Tooltip title="Crear un directorio en la ubicación actual.">
                                                                    <Popconfirm
                                                                        title='Crear directorio'
                                                                        description="¿Segur@ que quieres crear un directorio?"
                                                                        onConfirm={showCreateFolderModal}
                                                                        okText="Sí"
                                                                        cancelText="No"
                                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                                    >
                                                                        <Button
                                                                            type="link"
                                                                            danger
                                                                            icon={<FolderAddFilled />}
                                                                            className='btn btn-primary py-0'
                                                                            style={{ color: '#FFF' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </Tooltip>
                                                            )}
                                                            {permissionDelete && selectedItem.typeS !== 'medio_verificacion' && (

                                                                <Tooltip title="Eliminar del directorio 2">
                                                                    <Popconfirm
                                                                        title='Eliminación de directorio'
                                                                        description="¿Segur@ que quieres eliminar este directorio, no podrás revertir esta operación?"
                                                                        onConfirm={() => handleDeleteFolder(selectedItem.path, selectedItem)}
                                                                        okText="Sí"
                                                                        cancelText="No"
                                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                                    >
                                                                        <Button
                                                                            type="link"
                                                                            danger
                                                                            icon={<DeleteOutlined />}
                                                                            className='btn btn-primary py-0'
                                                                            style={{ color: '#FFF', background: '#ee1a0b', borderColor: '#ee1a0b' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </Tooltip>
                                                            )}
                                                            {permissionExport && (
                                                                <Tooltip title="Descarga soportes">
                                                                    <Popconfirm
                                                                        title='Descargar soporte'
                                                                        description="¿Segur@ que quieres descargar el soporte?"
                                                                        onConfirm={() => handleDescargar(selectedItem)}
                                                                        okText="Sí"
                                                                        cancelText="No"
                                                                        okButtonProps={{
                                                                            loading: loading,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            type="link"
                                                                            icon={<DownloadOutlined />}
                                                                            className='btn btn-primary py-0'
                                                                        />
                                                                    </Popconfirm>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={1}>
                                            <Col span={20}>
                                                <Space direction="vertical" size="large" style={{ width: '100%', paddingTop: '0px !important', marginTop: '0px !important' }}>
                                                    {renderSelectedFiles()}
                                                </Space>
                                            </Col>
                                            <Col span={4}>

                                                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                                                    {selectedItem.type === 'folder' && permissionInsert &&
                                                        selectedItem.children?.every(child => child.type !== 'folder') ? (
                                                        <Button
                                                            onClick={handleOkFile}
                                                            type="primary"
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                height: '150px',
                                                                width: '150px',
                                                                margin: '15px 0px 0px 10px',
                                                                border: '2px dashed #1890ff',
                                                                background: hover ? '#e6f7ff' : '#f5f5f5', // Cambio de color en hover
                                                                textAlign: 'center',
                                                                padding: '10px',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'normal',
                                                                lineHeight: '1.2',
                                                                cursor: 'pointer',
                                                                transition: 'background 0.3s ease' // Transición suave al cambiar de color
                                                            }}
                                                            onMouseEnter={() => setHover(true)}
                                                            onMouseLeave={() => setHover(false)}
                                                        >
                                                            <CloudUploadOutlined style={{ fontSize: '40px', marginBottom: '10px', color: '#1677ff' }} />
                                                            <p style={{ fontSize: '12px', color: '#888', margin: 0 }}>
                                                                Haga clic para cargar el soporte.
                                                            </p>
                                                        </Button>

                                                    ) : null}
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Space>
                                )}

                            </Content>
                        </Layout>
                    </Layout>


                    <Modal
                        title="Crear nueva carpeta"
                        open={isModalVisible}
                        onOk={handleCreateFolder}
                        onCancel={() => {
                            setIsModalVisible(false);
                            setNewFolderName(''); // Limpiar el nombre de la carpeta
                        }}
                        okText="Crear"
                        cancelText="Cancelar"
                    >
                        <Input
                            ref={inputRef} // Asigna la referencia al campo de entrada
                            placeholder="Nombre de la nueva carpeta"
                            value={newFolderName}
                            onChange={handleNameChange} // Cambia el valor usando la función handleNameChange
                            onKeyDown={handleKeyDown} // Maneja la tecla Enter
                        />
                    </Modal>

                    <Modal
                        title={<span style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', display: 'block' }}>Cargar soportes</span>}
                        open={isModalFileVisible}
                        onOk={handleOkFile}
                        onCancel={handleCancelFile}
                        width="45vw"
                        style={{
                            maxHeight: '60vh',
                            top: 20, // Ajusta este valor según lo necesario para mover el modal más arriba
                        }}
                        footer={null}
                        styles={{ paddingTop: '20px' }} // Opcional: para ajustar el padding superior del contenido del modal
                    >
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>

                                {permissionInsert && selectedItem?.pathFromClient && (

                                    <div>
                                        <p style={{ textAlign: 'center', marginBottom: '20px', color: '#5c5a5a' }}>Los documentos que se carguen deben corresponder con versiones finales del medio de verificación en formato PDF o Excel.</p>


                                        {versionFrom === '13' ? (<>

                                            <h4>{selectedItem.name}</h4>
                                            <div className='mt-0'>
                                                {getDirectoryDescription() && (
                                                    <Alert
                                                        banner
                                                        message={

                                                            <>  {getDirectoryDescription()}</>

                                                        }
                                                    />
                                                )}
                                            </div>

                                            <Form
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 50 }}
                                                layout="vertical"
                                                form={form}
                                                initialValues={{
                                                    fecha: null,
                                                    titulo: selectedItem.name,
                                                    resourceType: null,
                                                }}
                                                onFinish={handleFormSubmit}


                                            >
                                                <Form.Item
                                                    label="Nombre del soporte"
                                                    name="titulo"
                                                // Establece el valor inicial del campo
                                                >
                                                    <Input value={selectedItem.name} disabled /> {/* Deshabilita el campo para que no pueda ser editado */}
                                                </Form.Item>

                                                <Form.Item
                                                    label="Tipo de soporte"
                                                    name="resourceType"
                                                    rules={[{ required: true, message: 'Por favor, seleccione un tipo de soporte' }]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value="Memoria">Memoria</Radio>
                                                        <Radio value="Acta">Acta</Radio>
                                                        <Radio value="PPT">PPT</Radio>
                                                        <Radio value="Asistencia">Asistencia</Radio>
                                                        <Radio value="Informe">Informe</Radio>
                                                        <Radio value="Foto">Foto</Radio>
                                                        <Radio value="Grabación">Grabación</Radio>
                                                        <Radio value="Correo Aprobación">Correo Aprobación</Radio>
                                                        <Radio value="Documento técnico">Documento técnico</Radio>
                                                        <Radio value="Otro">Otro</Radio>
                                                    </Radio.Group>
                                                </Form.Item>

                                                <Form.Item
                                                    label="Carga de Soporte"
                                                    name="soporte"
                                                    rules={[{ required: true, message: 'Por favor, seleccione un soporte' }]}
                                                >
                                                    <div className="centered-dragger">
                                                        <Tooltip title="Subir Soporte">
                                                            <Dragger
                                                                {...getUploadProps(selectedItem.pathFromClient)}
                                                                customRequest={handleCustomRequest} // Usa handleCustomRequest para la carga manual
                                                                onChange={({ fileList }) => setFileList(fileList)}
                                                                style={{ width: '300px' }} // Ajusta el ancho según tus necesidades
                                                            >
                                                                <p className="ant-upload-drag-icon">
                                                                    <InboxOutlined />
                                                                </p>
                                                                <p className="ant-upload-text">Haga clic para cargar el archivo.</p>
                                                                <p className="ant-upload-hint">Soporte para un archivo.</p>
                                                            </Dragger>
                                                        </Tooltip>
                                                    </div>
                                                </Form.Item>
                                                <Form.Item>
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                                                        <Button
                                                            type="primary "
                                                            onClick={handleFormSubmit}
                                                            style={{ marginRight: 8 }}
                                                            disabled={isSubmitting}

                                                        >
                                                            {isSubmitting ? 'Guardando...' : 'Guardar'}
                                                        </Button>
                                                        <Button
                                                            type="primary"
                                                            onClick={handleCancelFile}
                                                            danger
                                                        >
                                                            Cancelar
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </Form>
                                        </>
                                        ) : (

                                            <Form
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 50 }}
                                                layout="vertical"
                                                form={form}
                                                initialValues={{
                                                    fecha: null,
                                                    titulo: '',
                                                    resourceType: null,
                                                }}
                                                onFinish={handleFormSubmit}
                                            //onValuesChange={handleFormChange}
                                            >
                                                <Form.Item
                                                    label="Nombre del soporte"
                                                    name="titulo"
                                                    rules={[
                                                        { required: true, message: 'Por favor, ingrese el Nombre del soporte' },
                                                        {
                                                            //En el segundo capo pemitir espacios, colocar came case
                                                            pattern: /^[0-9]{8}_[A-ZÁÉÍÓÚÑ][a-zA-Z0-9 _\-áéíóúÁÉÍÓÚñÑ.,]*_[A-ZÁÉÍÓÚÑ][a-zA-Z0-9 _\-áéíóúÁÉÍÓÚñÑ.,]*$/,
                                                            message: (
                                                                <div>
                                                                    <p>El formato debe ser ddmmyyyy_Tipo_Ubicación. Ej: 18112023_Memoria_ElCharco</p>
                                                                    <ul>
                                                                        <li>Fecha en la que se realizó el espacio o el documento, en formato día-mes-año</li>
                                                                        <li>Guion bajo</li>
                                                                        <li>Nombre descriptivo corto del archivo</li>
                                                                        <li>Guion bajo</li>
                                                                        <li>Especificar el municipio o vereda en la que se realizó el espacio</li>
                                                                    </ul>
                                                                </div>
                                                            ),
                                                        },
                                                    ]}

                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Tipo de soporte"
                                                    name="resourceType"
                                                    rules={[{ required: true, message: 'Por favor, seleccione un tipo de soporte' }]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value="Memoria">Memoria</Radio>
                                                        <Radio value="Acta">Acta</Radio>
                                                        <Radio value="PPT">PPT</Radio>
                                                        <Radio value="Asistencia">Asistencia</Radio>
                                                        <Radio value="Informe">Informe</Radio>
                                                        <Radio value="Foto">Foto</Radio>
                                                        <Radio value="Grabación">Grabación</Radio>
                                                        <Radio value="Correo Aprobación">Correo Aprobación</Radio>
                                                        <Radio value="Documento técnico">Documento técnico</Radio>
                                                        <Radio value="Otro">Otro</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Carga de Soporte"
                                                    name="soporte"
                                                    rules={[{ required: true, message: 'Por favor, seleccione un soporte' }]}
                                                >
                                                    <div className="centered-dragger">
                                                        <Tooltip title="Subir Soporte">
                                                            <Dragger
                                                                {...getUploadProps(selectedItem.pathFromClient)}
                                                                customRequest={handleCustomRequest} // Usa handleCustomRequest para la carga manual
                                                                onChange={({ fileList }) => setFileList(fileList)}
                                                                style={{ width: '300px' }} // Ajusta el ancho según tus necesidades
                                                            >
                                                                <p className="ant-upload-drag-icon">
                                                                    <InboxOutlined />
                                                                </p>
                                                                <p className="ant-upload-text">Haga clic para cargar el archivo.</p>
                                                                <p className="ant-upload-hint">Soporte para un archivo.</p>
                                                            </Dragger>

                                                        </Tooltip>

                                                    </div>
                                                </Form.Item>
                                                <Form.Item>
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                                                        <Button
                                                            type="primary "
                                                            onClick={handleFormSubmit}
                                                            style={{ marginRight: 8 }}
                                                            disabled={isSubmitting}

                                                        >
                                                            {isSubmitting ? 'Guardando...' : 'Guardar'}
                                                        </Button>
                                                        <Button
                                                            type="primary"
                                                            onClick={handleCancelFile}
                                                            danger
                                                        >
                                                            Cancelar
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </Form>
                                        )}
                                    </div>
                                )}
                            </div>
                            {loading && (
                                <div style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1000
                                }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Spin size="large" />
                                        <Progress percent={uploadProgress} style={{ marginTop: 16 }} />
                                    </div>
                                </div>
                            )}
                        </div>

                    </Modal>
                </div >
            )}
        </Layout >
    );
};

export default FileManagerPOA;
