import React, { useState, useEffect } from 'react';
import { Button, Table, Alert, Spin, Select, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ClearOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import getParticipantesEstructutacion from '../../services/estructuracion/obtenerParticipantesEstructuracionService';
import { fetchAllProyectos } from '../../services/estructuracion/get_proyectos';
import Formulario from './Formulario';
import './Formulario.css'

import ExportToExcelCaracterizacion from './exportExcelCaracterizacion';

const { Option } = Select;


const Caracterizacion = () => {
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [filteredParticipantes, setFilteredParticipantes] = useState([]);

    const [originalParticipantes, setOriginalParticipantes] = useState([]); // Estado para almacenar la lista original
    const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
    const [sortOrder, setSortOrder] = useState({});
    const [sortField, setSortField] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [proyectos, setProyectos] = useState([]);
    const [isVisibleForm, setVisibleForm] = useState(false); //Cambio mostrar formulario

    //Estados para los filtros
    const [numeroIdentificacion, setNumeroIdentificacion] = useState(null);
    const [selectedProyectos, setSelectedProyectos] = useState(null); // Cambiado a una lista
    const [listadepartamentosFilter, setListadepartamentosFilter] = useState([]);
    const [municipiosFilterInicial, setMunicipiosFilterInicial] = useState([]);
    const [listamunicipiosFilter, setListamunicipiosFilter] = useState([]);
    const [departamentoSeleccionadoFilter, setDepartamentoSeleccionadoFilter] = useState(null);
    const [municipioSeleccionadoFilter, setMunicipioSeleccionadoFilter] = useState(null);




    const appName = 'caracterizacion';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionExport = permissions.priv_export === 'Y';

    const obtenerParticipantesEstructuracion = async () => {
        try {
            const { status, data } = await getParticipantesEstructutacion();

            // Extraer departamentos únicos (formulario)
            const uniqueDepartamentos = [...new Map(data.map(item =>
                [item.detalle.departamento, { departamento: item.detalle.departamento }]
            )).values()];

            // Filtrar datos para excluir entradas donde municipio o departamento estén vacíos
            const filteredDataMunicipios = data.filter(item =>
                item.detalle.municipio && item.detalle.departamento
            );

            // Extraer municipios únicos junto con sus departamentos
            const uniqueMunicipiosUnicos = [...new Map(filteredDataMunicipios.map(item =>
                [`${item.detalle.municipio}-${item.detalle.departamento}`, { municipio: item.detalle.municipio, departamento: item.detalle.departamento }]
            )).values()];

            setStatus(status);
            setFilteredParticipantes(data)
            setOriginalParticipantes(data) // Guardar la lista original
            setListadepartamentosFilter(uniqueDepartamentos)
            setListamunicipiosFilter(uniqueMunicipiosUnicos)
            setMunicipiosFilterInicial(uniqueMunicipiosUnicos)

        } catch (error) {
            setStatus(500);
            setOriginalParticipantes([]);
            setFilteredParticipantes([]);
        } finally {
            setLoading(false);
        }
    };

    const obtenerProyectos = async () => {
        try {
            const data = await fetchAllProyectos();
            setProyectos(data);
        } catch (error) {
            setProyectos([]);
        }
    };

    useEffect(() => {
        obtenerProyectos();
        obtenerParticipantesEstructuracion();
    }, []);

    //Funcion para manejar los filtros
    const filterData = () => {
        let filtered = originalParticipantes;

        if (numeroIdentificacion) {
            filtered = filtered.filter(item =>
                item.detalle.numeroIdentificacion.includes(numeroIdentificacion)
            );
        }

        

        if (departamentoSeleccionadoFilter) {
            filtered = filtered.filter(item =>
                item.detalle.departamento === departamentoSeleccionadoFilter
            );
        }

        if (municipioSeleccionadoFilter) {
            filtered = filtered.filter(item =>
                item.detalle.municipio === municipioSeleccionadoFilter
            );
        }

        if (selectedProyectos) {
            filtered = filtered.filter(item =>
                item.detalle.proyecto_estructuracion === selectedProyectos
            );
        }

        setFilteredParticipantes(filtered);
    };

    //Filtrar municipios por departamento
    const handleDepartamentoFilterChange = (value) => {
        setMunicipioSeleccionadoFilter('');
        setDepartamentoSeleccionadoFilter(value);
        //Filtrar la lista por el id_departamento
        const filteredList = municipiosFilterInicial.filter(item => item.departamento === value);
        setListamunicipiosFilter(filteredList)
    };


    useEffect(() => {
        filterData();
    }, [numeroIdentificacion, selectedProyectos, departamentoSeleccionadoFilter, municipioSeleccionadoFilter]);

    const renderErrorMessage = (status) => {
        if (status === 404) {
            return (
                <Alert
                    type="warning"
                    message="Advertencia"
                    description="Lo sentimos, no se encuentran participantes disponibles para mostrar."
                    showIcon
                    icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
                />
            );
        } else if (status === 500) {
            return (
                <Alert
                    type="error"
                    message="Error"
                    description="Lo sentimos, ha ocurrido un error al obtener los participantes. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co"
                    showIcon
                    icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                />
            );
        }
    };


    const showForm = () => {
        setVisibleForm(true)

    };
    const handleCloseForm = () => {
        setVisibleForm(false)
    };

    const clearAllFilters = () => {
        setSelectedProyectos([]);
        setFilteredInfo({});
        setSelectedProyectos(''); // Restablecer los proyectos seleccionados
        setNumeroIdentificacion('');
        setDepartamentoSeleccionadoFilter('');
        setMunicipioSeleccionadoFilter('');
        obtenerParticipantesEstructuracion()
    };

    const columns = [
        { title: 'Codigo', dataIndex: ['detalle', 'proyecto_codigo'], key: 'proyecto_codigo', width: 100 },
        { title: 'Proyecto', dataIndex: ['detalle', 'nombre_proyecto'], key: 'nombre_proyecto' },
        { title: 'Nombre', dataIndex: ['detalle', 'nombre'], key: 'nombre' },
        { title: 'Apellido', dataIndex: ['detalle', 'apellido'], key: 'apellido' },
        { title: 'Número de Identificación', dataIndex: ['detalle', 'numeroIdentificacion'], key: 'numeroIdentificacion', width: 150 },
        { title: 'Fecha de Nacimiento', dataIndex: ['detalle', 'fechaNacimiento'], key: 'fechaNacimiento', width: 150, render: (text) => new Date(text).toLocaleDateString() },
        { title: 'Edad', width: 100, dataIndex: ['detalle', 'edad'], key: 'edad' },
        { title: 'Sexo', dataIndex: ['detalle', 'sexo'], key: 'sexo' },
        { title: 'Departamento', width: 150, dataIndex: ['detalle', 'departamento'], key: 'departamento' },
        { title: 'Municipio', dataIndex: ['detalle', 'municipio'], key: 'municipio', width: 100 },
    ];

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setPagination(pagination);
        setSortOrder(sorter.order);
        setSortField(sorter.field);
        setFilteredInfo(filters);
    };

    const noDataConfig = {
        emptyText: (
            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <InboxOutlined style={{ fontSize: '2rem' }} />
                <span>Sin resultados</span>
            </span>
        ),
    };

    if (loading) {
        return <div className="text-center"><Spin size="large" /></div>;
    }


    return (
        <div className="container">

            {isVisibleForm === false ? ( // Condicional que verifica si no se debe mostrar Edahform
                <>
                    <React.Fragment>
                        {status === 404 || status === 500 ? (
                            renderErrorMessage(status)
                        ) : (
                            <React.Fragment>
                                <div className="row mb-2 d-flex align-items-center">
                                    <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                                        <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                                            <p>PROYECTO UTF <br /> COL 160 COL</p>
                                        </div>
                                        <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                                            <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                        <h2 className="text-center mb-2">Listado de participantes</h2>
                                        <div className="row mb-4">
                                            <div className="col-md-12">
                                                <div className="input-group shadow-sm">
                                                    {permissionInsert && (

                                                        <Button
                                                            type="danger"
                                                            className="btn btn-primary me-2"
                                                            size="large"
                                                            icon={<PlusOutlined />}
                                                            onClick={showForm} // Abre el modal

                                                        >
                                                            Nueva
                                                        </Button>
                                                    )}

                                                    {permissionExport && <ExportToExcelCaracterizacion data={filteredParticipantes} />}

                                                    <Button
                                                        type="danger"
                                                        className="btn btn-primary"
                                                        size="large"
                                                        onClick={clearAllFilters}
                                                        icon={<ClearOutlined />}
                                                    >
                                                        Limpiar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        {originalParticipantes.length > 0 ? (
                                            <>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-3 mb-3">
                                                                <div className="form-group">
                                                                    <label htmlFor="filterInput">Número de Identificación:</label>
                                                                    <Input
                                                                        type="number"
                                                                        value={numeroIdentificacion}
                                                                        onChange={(e) => setNumeroIdentificacion(e.target.value)}
                                                                        id="filterInput"
                                                                        placeholder="Ingrese un número"
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 mb-3">
                                                                <div className="form-group">
                                                                    <label htmlFor="projectSelect">Departamento:</label>
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Seleccione un departamento"
                                                                        optionFilterProp="children"
                                                                        value={departamentoSeleccionadoFilter}
                                                                        onChange={(value) => handleDepartamentoFilterChange(value)}
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        className="select-project"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        {listadepartamentosFilter.map((d) => (
                                                                            <Option key={d.departamento} value={d.departamento}>{d.departamento}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 mb-3">
                                                                <div className="form-group">
                                                                    <label htmlFor="projectSelect">Municipio:</label>
                                                                    <Select
                                                                        showSearch
                                                                        value={municipioSeleccionadoFilter}
                                                                        onChange={(value) => setMunicipioSeleccionadoFilter(value)}
                                                                        placeholder="Seleccione municipios"
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        className="select-project"
                                                                        style={{ width: '100%' }}
                                                                    >

                                                                        {listamunicipiosFilter.map((m) => (
                                                                            <Option ey={m.municipio} value={m.municipio}>{m.municipio}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 mb-3">
                                                                <div className="form-group">
                                                                    <label htmlFor="departmentSelect">Proyecto:</label>
                                                                    <Select
                                                                        placeholder="Selecciona proyectos"
                                                                        onChange={(value) => setSelectedProyectos(value)}
                                                                        value={selectedProyectos}
                                                                        style={{ width: '300px', marginBottom: '10px' }}
                                                                    >
                                                                        {proyectos.map((proyecto) => (
                                                                            <Select.Option key={proyecto.proyecto_oid} value={proyecto.proyecto_oid}>
                                                                                {proyecto.proyecto_codigo} - {proyecto.nombre_proyecto}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="participant-count-container">
                                                            <h1 class="participant-count-title">
                                                                Total registros: <span class="participant-count-number">{filteredParticipantes.length}</span>
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div>

                                                {filteredParticipantes.length > 0 ? (
                                                    <Table
                                                        columns={columns}
                                                        dataSource={filteredParticipantes}
                                                        rowKey="oid"
                                                        bordered
                                                        pagination={pagination}
                                                        onChange={handleTableChange}
                                                        sortDirections={['ascend', 'descend']}
                                                        sortOrder={sortOrder}
                                                        onSorterChange={setSortOrder}
                                                        sortField={sortField}
                                                        loading={loading}
                                                        locale={noDataConfig}
                                                        scroll={{ y: 500, x: 'max-content' }} />
                                                ) : (
                                                    <div className="text-center mt-4">
                                                        <Alert
                                                            message="Sin resultados"
                                                            description="Lo sentimos, no se encuentran participantes disponibles para mostrar."
                                                            type="info"
                                                            showIcon
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="text-center mt-4">
                                                <Alert
                                                    message="Sin resultados"
                                                    description="Lo sentimos, no se encuentran participantes disponibles para mostrar."
                                                    type="info"
                                                    showIcon
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>



                            </React.Fragment>
                        )}
                    </React.Fragment>
                </>
            ) : (
                <Formulario
                    onClose={handleCloseForm}
                    fetchdata={obtenerParticipantesEstructuracion}
                />

            )}


        </div>
    );

};

export default Caracterizacion;
