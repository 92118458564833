import React, { useState, useEffect } from 'react';
import { getIppta } from '../../services/ippta/get_ipptaRadar';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';

// Registrar las escalas y elementos que utilizarás
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const IpptaRadar = ({ formData  }) => {

  const [data, setData] = useState({
    labels: [
      'PROTECCIÓN DE FUENTES DE AGUA',
      'MANEJO DEL AGUA PARA EL CONSUMO HUMANO',
      'AHORRO DE AGUA EN EL HOGAR Y LOS SISTEMAS PRODUCTIVOS',
      'PROTECCIÓN DEL BOSQUE EN LA FINCA',
      'CONECTIVIDAD ENTRE EL PAISAJE Y EL AGROECOSISTEMA',
      'MANEJO DE RESIDUOS SÓLIDOS',
      'GENERACIÓN DE INGRESOS E ÍNDICE DE AUTOEMPLEO',
      'ORGANIZACIÓN PARA LA COMERCIALIZACIÓN DE LOS PRODUCTOS',
      'AUTOSUFICIENCIA ALIMENTARIA FAMILIAR',
      'PARTICIPACIÓN DE LA FAMILIA EN PROCESOS COMUNITARIOS.',
      'GESTIÓN DEL CONOCIMIENTO AGROECOLÓGICO',
      'DIVERSIDAD DE CULTIVOS',
      'DIVERSIDAD DE ANIMALES (INCLUYENDO PECES)',
      'INTEGRACIÓN ENTRE ANIMALES Y CULTIVOS',
      'MANEJO DEL SUELO',
      'BIENESTAR ANIMAL (SI APLICA) A NIVEL DE FINCA',
      'PRODUCCIÓN DE INSUMOS EN LA FINCA',
      'PRODUCIR Y CUSTODIAR SEMILLAS EN LA FINCA',

    ],
    datasets: [
      {
        label: 'Medición 1',
        data: [], // Inicializa vacío
        backgroundColor: 'rgba(0, 0, 0, 0)',
        //backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 4,
      },
      {
        label: 'Medición 2',
        data: [], // Inicializa vacío
        backgroundColor: 'rgba(0, 0, 0, 0)',
        //backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 4,
      },
    ],
  });

  const options = {
    scales: {
      r: {
          min: 0,
          max: 5,
          ticks: {
              stepSize: 1,
          },
      },
  },
  responsive: true,
    layout: {
        padding: {
            top: 0, // Ajusta el espacio superior
            bottom: 400,
            left: 0,
            right: 0,
        },
    }
};
  useEffect(() => {
    fetchInitialData();
  }, [formData]); 

  const fetchInitialData = async () => {
    try {
      let response = "";
      // Llamar al servicio con la identificación proporcionada
      if (!formData) {
        response = await getIppta();  // Si no hay identificación, hacer una llamada sin parámetro
      } else {
        response = await getIppta(formData);  // Llamar al servicio con la identificación
      }
      // Verifica si 'datasets' existe antes de actualizar el estado
      if (response.data && response.data.datasets) {
        setData((prevData) => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              data: response.data.datasets[0].data, // Actualiza Medición 1
            },
            {
              ...prevData.datasets[1],
              data: response.data.datasets[1].data, // Actualiza Medición 2
            },
          ],
        }));
      }
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };
  return (
    <div> {/* Clase para centrar el contenido */}
    {data && data.datasets && (
        <Radar data={data} options={options} />
      )}
  </div>
  );
};

export default IpptaRadar;
