import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Modal, Tabs, Rate, Progress, Row, Col, Select, Flex, message, Button } from 'antd';
import { getProyectos, getMunicipiosDepartamentos, fetchParticipantData, getEntidad } from '../../services/ippta/get_dataIppta'; // Asegúrate de que esta ruta sea correcta
import { UserOutlined, FundOutlined, ArrowLeftOutlined, ArrowRightOutlined, SendOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import './IpptaForm.css';
import { saveIpptaData } from '../../services/ippta/post_ippta';
import { updateIpptaData } from '../../services/ippta/update_ippta';
const { TabPane } = Tabs;
const { Option } = Select;

const IpptaForm = ({ visible, onClose, formData, loadData }) => {
  const [form] = Form.useForm();
  const [ratings, setRatings] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [proyectos, setProyectos] = useState([]);
  const [entidadesService, setEntidadesService] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [filteredMunicipios, setFilteredMunicipios] = useState([]); // Nuevo estado para los municipios filtrados
  const [activeTabKey, setActiveTabKey] = useState('1'); // Estado para controlar el tab activo
  const twoColors = { '0%': '#108ee9', '100%': '#87d068', };
  const [ambientalRatings, setAmbientalRatings] = useState([0, 0, 0, 0, 0, 0]); // Inicializa con valores
  const [socioeconomicoRatings, setSocioeconomicoRatings] = useState([0, 0, 0, 0, 0]);
  const [productivaRatings, setProductivaRatings] = useState([0, 0, 0, 0, 0, 0, 0]);
  const usernameFromState = useSelector((state) => state.auth.user);
  const [medicionData, setMedicionData] = useState("Inicial");
  const [oidRegistro, setOidRegistro] = useState(null);

  // Función para resetear el formulario y las calificaciones
  const resetFormAndRatings = useCallback(() => {
    form.resetFields();
    loadData();
    setOidRegistro(null);
    setAmbientalRatings(Array(6).fill(0));
    setSocioeconomicoRatings(Array(5).fill(0));
    setProductivaRatings(Array(7).fill(0));
    setRatings(Array(6).fill(0)); // Asegúrate de que esto esté sincronizado
    setActiveTabKey('1');
  }, [form, loadData]); // Añade aquí las dependencias necesarias

  useEffect(() => {
    fetchData();
  }, []);

  // Función para cargar los datos iniciales
  const fetchData = async () => {
    try {
      const proyectosData = await getProyectos();
      setProyectos(proyectosData.data || []); // Asegúrate de que sea un arreglo
      const municipiosData = await getMunicipiosDepartamentos();
      // Extraer departamentos y municipios
      const departamentosData = municipiosData.data || [];
      setDepartamentos(departamentosData); // Ahora departamentos es un array de departamentos
      // Inicialmente, no hay municipios filtrados
      setFilteredMunicipios([]);
      const entidad = await getEntidad();
      setEntidadesService(entidad || []);
    } catch (error) {
      console.error('Error al cargar datos:', error);
    }
  };

  // Añade la función para manejar el cierre del modal
  const handleCancel = () => {
    const fieldsValue = form.getFieldsValue();
    const hasFilledFields = Object.values(fieldsValue).some(value => value !== undefined && value !== '');
    const hasSelectedRatings = ratings.some(rating => rating > 0);

    if (hasFilledFields || hasSelectedRatings) {
      Modal.confirm({
        title: '¿Está seguro de cerrar?',
        content: 'Hay campos que han sido diligenciados o calificaciones seleccionadas. Si cierra, se perderán los datos.',
        onOk: () => {
          resetFormAndRatings(); // Llamar a la nueva función
          onClose();

        },
      });
    } else {
      resetFormAndRatings(); // Llamar a la nueva función
      onClose();
    }
  };
  // Añade la función para manejar el filtro de departamentos y municipios
  const handleDepartamentoChange = (value) => {
    const selectedDepartamento = departamentos.find(departamento => departamento.oid === value);
    if (selectedDepartamento) {
      setFilteredMunicipios(selectedDepartamento.municipios); // Filtra los municipios por el departamento seleccionado
      form.setFieldsValue({ municipio: undefined }); // Reinicia el campo municipio
    }
  };

  // Añade la función para manejar el cambio de pestañas
  const handleTabChange = async (key) => {
    if (key === '2' || key === '3' || key === '4') {
      try {
        await form.validateFields();
        setActiveTabKey(key);
      } catch (error) {
        message.error('Por favor complete los campos obligatorios en el formulario antes de continuar.');
      }
    } else {
      setActiveTabKey(key);
    }
  };

  // Añade la función para manejar el cambio de calificaciones de la dimensión ambiental
  const handleAmbientalRateChange = (value, index) => {
    const newRatings = [...ambientalRatings];
    newRatings[index] = value;
    setAmbientalRatings(newRatings);

  };

  // Añade la función para manejar el cambio de calificaciones de la dimensión socioeconómica
  const handleSocioeconomicoRateChange = (value, index) => {
    const newRatings = [...socioeconomicoRatings];
    newRatings[index] = value;
    setSocioeconomicoRatings(newRatings);
  };

  // Añade la función para manejar el cambio de calificaciones de la dimensión productiva
  const handleProductivaRateChange = (value, index) => {
    const newRatings = [...productivaRatings];
    newRatings[index] = value;
    setProductivaRatings(newRatings);
  }

  //Me carga los datos del participante
  const handleIdentificacionChange = useCallback(async (value) => {
    if (value) {
      const identificacion = value.identificacion;
      const medicion = value.medicion;
      const data = await fetchParticipantData(identificacion, medicion);

      if (data && data.componente_general.length > 0) {
        const participantData = data.componente_general[0];
        setOidRegistro(participantData.oid);

        const selectedDepartamento = departamentos.find(dep => dep.oid === participantData.departamento);
        if (selectedDepartamento) {
          setFilteredMunicipios(selectedDepartamento.municipios);
        }

        form.setFieldsValue({
          proyecto: participantData.codigo_proyecto,
          medicion: participantData.medicion === 1 ? "Inicial" : "Final",
          identificacion: participantData.identificacion,
          participante: participantData.participante,
          celular: participantData.celular,
          nombreFamiliar1: participantData.nombreFamiliar1,
          nombreFamiliar2: participantData.nombreFamiliar2,
          nombreFamiliar3: participantData.nombreFamiliar3,
          departamento: participantData.departamento,
          municipio: participantData.municipio,
          vereda: participantData.vereda,
          entidad: participantData.entidad,
          predio: participantData.predio,
          supervisor: participantData.supervisor_proyecto,
          responsableP: participantData.profesional_responsable,
        });

        if (data.componente_ambiental.length > 0) {
          const ambiental = data.componente_ambiental[0];
          setAmbientalRatings([
            ambiental.indicador1 || 0,
            ambiental.indicador2 || 0,
            ambiental.indicador3 || 0,
            ambiental.indicador4 || 0,
            ambiental.indicador5 || 0,
            ambiental.indicador6 || 0
          ]);
        }

        if (data.componente_socioeconomico.length > 0) {
          const socioeconomico = data.componente_socioeconomico[0];
          setSocioeconomicoRatings([
            socioeconomico.indicador1 || 0,
            socioeconomico.indicador2 || 0,
            socioeconomico.indicador3 || 0,
            socioeconomico.indicador4 || 0,
            socioeconomico.indicador5 || 0
          ]);
        }

        if (data.componente_producto.length > 0) {
          const productiva = data.componente_producto[0];
          setProductivaRatings([
            productiva.indicador1 || 0,
            productiva.indicador2 || 0,
            productiva.indicador3 || 0,
            productiva.indicador4 || 0,
            productiva.indicador5 || 0,
            productiva.indicador6 || 0,
            productiva.indicador7 || 0
          ]);
        }
      } else {
        // Si no se encuentra el registro, limpiar los valores
        form.setFieldsValue({
          participante: '',
          celular: '',
          nombreFamiliar1: '',
          nombreFamiliar2: '',
          nombreFamiliar3: '',
          departamento: '',
          municipio: '',
          vereda: '',
          entidad: '',
          predio: '',
          supervisor: '',
          responsableP: '',
        });
        setFilteredMunicipios([]);
        setAmbientalRatings(Array(6).fill(0));
        setSocioeconomicoRatings(Array(5).fill(0));
        setProductivaRatings(Array(7).fill(0));
      }
    }
  }, [form, departamentos]);


  useEffect(() => {
    if (formData) {
      handleIdentificacionChange(formData);
    }
  }, [formData, handleIdentificacionChange]); // Agregar handleIdentificacionChange

  // Función para validar los campos obligatorios
  const validateRequiredFields = async () => {
    try {
      await form.validateFields(); // Valida todos los campos obligatorios
      return true; // Si la validación es exitosa
    } catch (error) {
      message.error('Por favor completa todos los campos obligatorios.');
      return false; // Si hay errores en la validación
    }
  };

  // Función para guardar los datos
  const handleSave = async () => {
    const values = form.getFieldsValue(); // Obtiene todos los valores del formulario
    // Asegúrate de incluir las calificaciones en los valores.
    const completeValues = {
      ...values,
      medicion: values.medicion ? values.medicion : "Inicial",
      ambientalRatings, // Asumiendo que tienes los ratings guardados en esta variable
      socioeconomicoRatings,
      productivaRatings, // Asegúrate de tener una variable para las calificaciones de la dimensión productiva
      user: usernameFromState.username,
    };
    // Verifica si hay campos vacíos en los valores completados
    try {
      // Enviar los datos al backend a través del servicio
      const data = await saveIpptaData(completeValues);
      setOidRegistro(data.generalId); // Guarda el OID del registro
      setActiveTabKey((prevKey) => (parseInt(prevKey) + 1).toString())
      if (activeTabKey === '4') {
        message.success('Datos eviado correctamente');
        resetFormAndRatings();
        onClose(); // Cierra el modal después de guardar
      }
    } catch (error) {
      message.error('Error al guardar los datos.');
    }
  };

  // Función para actualizar los datos
  const handleUpdate = async () => {
    const values = form.getFieldsValue(); // Obtiene todos los valores del formulario
    // Crea el objeto con los valores completos, incluyendo las calificaciones
    const completeValues = {
      ...values,
      medicion: values.medicion ? values.medicion : "Inicial", // Asignar "Inicial" si el valor es undefined
      ambientalRatings, // Suponiendo que tienes estas variables definidas
      socioeconomicoRatings,
      productivaRatings,
      user: usernameFromState.username, // Obteniendo el nombre del usuario desde el estado
    };

    try {
      // Llama al servicio de actualización
      await updateIpptaData(oidRegistro, completeValues);
      setActiveTabKey((prevKey) => (parseInt(prevKey) + 1).toString())
      if (activeTabKey === '4') {
        message.success('Datos eviado correctamente');
        resetFormAndRatings();
        onClose(); // Cierra el modal después de la actualización
      }
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
      message.error('Error al actualizar los datos.');
    }
  };
  // Función para saber si actualiza o guarda los datos
  const datos = async () => {
    const isValid = await validateRequiredFields();
    if (!isValid) {
      // Si la validación falla, detener el flujo
      return;
    }
    if (oidRegistro) {
      handleUpdate();
    } else {
      handleSave();
    }
  }
  // Función para ir a la siguiente pestaña
  const goToPreviousTab = () => {
    setActiveTabKey((prevKey) => (parseInt(prevKey) - 1).toString());
  };

  // Calculo del total de estrellas para la Dimensión 1
  const totalEnvironmentalStars = ambientalRatings.reduce((acc, curr) => acc + curr, 0);
  const maxEnvironmentalStars = 30; // 6 categorías * 5 estrellas
  const percentDimension1 = Math.round((totalEnvironmentalStars / maxEnvironmentalStars) * 100);

  // Calculo del total de estrellas para la Dimensión 2
  const totalSocioeconomicStars = socioeconomicoRatings.reduce((acc, curr) => acc + curr, 0);
  const maxSocioeconomicStars = 25; // 5 categorías * 5 estrellas
  const percentDimension2 = Math.round((totalSocioeconomicStars / maxSocioeconomicStars) * 100);

  // Calculo del total de estrellas para la Dimensión 3
  const totalProductivaStars = productivaRatings.reduce((acc, curr) => acc + curr, 0);
  const maxProductivaStars = 35; // 5 categorías * 5 estrellas
  const percentDimension3 = Math.round((totalProductivaStars / maxProductivaStars) * 100);

  return (
    <Modal
      visible={visible}
      title={<span style={{ fontSize: '24px', fontWeight: 'bold' }}>IPPTA</span>}
      cancelText="Cancelar"
      onCancel={handleCancel}
      width={850}
      footer={null}
      style={{ textAlign: 'center' }}
    >
      <Tabs activeKey={activeTabKey} onChange={handleTabChange} animated>
        <TabPane tab={<span><UserOutlined /> Datos personales</span>} key="1">
          <Form form={form} layout="vertical" >
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item
                  name="proyecto"
                  label="Proyecto:"
                  rules={[{ required: true, message: 'Por favor seleccione un proyecto!' }]}
                >
                  <Select
                    style={{ textAlign: 'left' }}
                    placeholder="Seleccione un proyecto"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {proyectos?.map(proyecto => (
                      <Option key={proyecto.proyecto_oid} value={proyecto.proyecto_oid}>
                        {`${proyecto.proyecto_codigo} - ${proyecto.nombre_proyecto}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="medicion"
                  label="Medición:"
                >
                  <Select
                    placeholder="Seleccione una medición"
                    value={medicionData}
                    onChange={(value) => {
                      setMedicionData(value);
                    }}
                    style={{ textAlign: 'left', width: '100%' }}
                  >
                    <Option value="Inicial">Inicial</Option>
                    <Option value="Final">Final</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {[
                {
                  name: "identificacion",
                  label: "Identificación:",
                  placeholder: "Escribe el número de identificación.",
                  rules: [
                    { required: true, message: "Por favor, ingresa el número de identificación." },
                  ],
                  type: "number", // Campo solo numérico
                },
                {
                  name: "participante",
                  label: "Participante del proyecto:",
                  placeholder: "Escribe el nombre del participante.",
                  rules: [{ required: true, message: "Por favor, ingresa el nombre del participante." }],
                  type: "text", // Campo para texto
                },
                {
                  name: "celular",
                  label: "Celular:",
                  placeholder: "Escribe el número de celular.",
                  rules: [
                    { required: true, message: "Por favor, ingresa el número de celular." },
                    {
                      pattern: /^\d{10}$/, // Asegura que tenga exactamente 10 dígitos
                      message: "El número de celular debe tener 10 dígitos.",
                    },
                  ],
                  type: "number", // Campo solo numérico
                },
              ].map(({ name, label, placeholder, rules, type }) => (
                <Col span={8} key={name}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={rules}
                  >
                    <Input
                      placeholder={placeholder}
                      type={type} // Aplica el tipo de campo adecuado
                      onKeyPress={type === "number" ? (event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault(); // Evita caracteres no numéricos para los campos de identificación y celular
                        }
                      } : undefined}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row gutter={16}>
              {Array.from({ length: 3 }, (_, index) => (
                <Col span={8} key={`familiar${index}`}>
                  <Form.Item
                    name={`nombreFamiliar${index + 1}`}
                    label="Nombre Familiar:"
                  >
                    <Input placeholder={`Escribe el nombre de ${index === 0 ? 'un' : index === 1 ? 'otro' : 'un tercer'} familiar`} />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="departamento"
                  label="Departamento:"
                  style={{ textAlign: 'left' }}
                  rules={[{ required: true, message: 'Por favor seleccione un departamento!' }]}
                >
                  <Select
                    placeholder="Seleccione un departamento"
                    showSearch
                    optionFilterProp="children"
                    onChange={handleDepartamentoChange}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {departamentos?.map(departamento => (
                      <Option key={departamento.oid} value={departamento.oid}>
                        {departamento.departamento}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="municipio"
                  label="Municipio:"
                  style={{ textAlign: 'left' }}
                  rules={[{ required: true, message: 'Por favor seleccione un municipio!' }]}
                >
                  <Select placeholder="Seleccione un municipio">
                    {filteredMunicipios.map(municipio => (
                      <Option key={municipio.oid} value={municipio.oid}>
                        {municipio.municipio}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="vereda"
                  label="Vereda:"
                  rules={[{ required: true, message: 'Por favor, ingresa el nombre de la vereda.' }]}
                >
                  <Input placeholder="Escribe el nombre de la vereda" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {[
                { name: "entidad", label: "Entidad:" },
                { name: "predio", label: "Predio:" },
                { name: "supervisor", label: "Supervisor del proyecto:" },
                { name: "responsableP", label: "Profesional responsable:" },
              ].map(({ name, label }) => (
                <Col span={12} key={name}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: true,
                        message: name === "entidad"
                          ? "Por favor, seleccione la entidad." // Mensaje específico para entidad
                          : `Por favor, ingresa el nombre del ${label.toLowerCase()}.`,
                      },
                    ]}
                  >
                    {name === "entidad" ? (
                      <Select
                        style={{ textAlign: 'left' }}
                        placeholder="Seleccione una entidad"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {entidadesService?.map((entidad) => (
                          <Option key={entidad.entidad_oid} value={entidad.entidad_oid}>
                            {entidad.entidadd}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input placeholder={`Escribe el nombre del ${label.toLowerCase()}`} />
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>

          </Form>
        </TabPane>
        <TabPane tab={<span><FundOutlined /> Dimensión 1</span>} key="2">
          <Row
            gutter={16}
            style={{
              background: "#042956",
              textAlign: "center",
              padding: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35px' }}>
              <h5 style={{ color: "#fff", textAlign: 'center' }}>Dimensión Ambiental</h5>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row gutter={12}>
                {[
                  { name: "fuentesAguaProteccion", label: "1. Protección de fuentes de agua." },
                  { name: "aguaConsumoManejo", label: "2. Manejo del agua para consumo humano." },
                  { name: "ahorroAguaHogar", label: "3. Ahorro de agua en el hogar y sistemas productivos." },
                  { name: "bosqueProteccion", label: "4. Protección del bosque en la finca." },
                  { name: "conectividadPaisaje", label: "5. Conectividad entre paisaje y agroecosistema." },
                  { name: "residuosSolidosManejo", label: "6. Manejo de residuos sólidos." },
                ].map(({ name, label }, index) => (
                  <Col key={name}>
                    <div style={{ padding: '2px' }}>
                      <span style={{ fontSize: '15px', marginRight: '10px' }}>{label}</span>
                      <div style={{ display: 'flex', alignItems: 'flex-start', padding: '10px' }}>
                        <Rate
                          allowHalf={false}
                          count={5}
                          style={{ fontSize: '25px', color: '#328BC7' }}
                          value={ambientalRatings[index]}
                          onChange={(value) => handleAmbientalRateChange(value, index)}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={10} style={{ padding: '16px', textAlign: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Calificación de la Dimensión
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension1} format={(percent) => `${totalEnvironmentalStars}`} />
              </Flex>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Nivel de transición Agroecológica
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension1} strokeColor={twoColors} format={(percent) => percent === 100 ? `${percent}%` : `${percent}%`} />
              </Flex>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={<span><FundOutlined /> Dimensión 2</span>} key="3">
          <Row
            gutter={16}
            style={{
              background: "#042956",
              textAlign: "center",
              padding: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col>
              <h5 style={{ color: "#fff", textAlign: 'center' }}>Dimensión Socioeconomico</h5>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row gutter={12}>
                {[
                  { name: "generacionautoempleo", label: "7. Generación de ingresos e índice de autoempleo." },
                  { name: "organizacionComercializacion", label: "8. Organización para la comercialización de productos." },
                  { name: "autosuficienciaAlimentaria", label: "9. Autosuficiencia alimentaria familiar." },
                  { name: "participacionFamiliar", label: "10. Participación de la familia en procesos comunitarios." },
                  { name: "gestionConocimientoAgroecologico", label: "11. Gestión del conocimiento agroecológico." },
                ].map(({ name, label }, index) => (
                  <Col key={name}>
                    <div style={{ padding: '2px' }}>
                      <span style={{ fontSize: '15px', marginRight: '10px' }}>{label}</span>
                      <div style={{ display: 'flex', alignItems: 'flex-start', padding: '10px' }}>
                        <Rate
                          allowHalf={false}
                          count={5}
                          style={{ fontSize: '25px', color: '#328BC7' }}
                          value={socioeconomicoRatings[index]}
                          onChange={(value) => handleSocioeconomicoRateChange(value, index)}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={10} style={{ padding: '16px', textAlign: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Calificación de la Dimensión
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension2} format={(percent) => `${totalSocioeconomicStars}`} />
              </Flex>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Nivel de transición Agroecológica
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension2} strokeColor={twoColors} format={(percent) => percent === 100 ? `${percent}%` : `${percent}%`} />
              </Flex>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={<span><FundOutlined /> Dimensión 3</span>} key="4">
          <Row
            gutter={16}
            style={{
              background: "#042956",
              textAlign: "center",
              padding: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col>
              <h5 style={{ color: "#fff", textAlign: 'center' }}>Dimensión Productiva</h5>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row gutter={12}>
                {[
                  { name: "diversidadCultivos", label: "12. Diversidad de cultivos" },
                  { name: "diversidadAnimales", label: "13. Diversidad de animales (incluyendo peces)" },
                  { name: "integracionAnimalesCultivos", label: "14. Integración entre animales y cultivos" },
                  { name: "manejoSuelo", label: "15. Manejo del suelo" },
                  { name: "bienestarAnimal", label: "16. Bienestar animal (si aplica) a nivel de finca" },
                  { name: "produccionInsumos", label: "17. Producción de insumos en la finca" },
                  { name: "custodiaSemillas", label: "18. Producir y custodiar semillas en la finca" },
                ].map(({ name, label }, index) => (
                  <Col key={name}>
                    <div style={{ padding: '2px' }}>
                      <span style={{ fontSize: '15px', marginRight: '10px' }}>{label}</span>
                      <div style={{ display: 'flex', alignItems: 'flex-start', padding: '10px' }}>
                        <Rate
                          allowHalf={false}
                          count={5}
                          style={{ fontSize: '25px', color: '#328BC7' }}
                          value={productivaRatings[index]}
                          onChange={(value) => handleProductivaRateChange(value, index)}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={10} style={{ padding: '16px', textAlign: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Calificación de la Dimensión
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension3} format={(percent) => `${totalProductivaStars}`} />
              </Flex>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Nivel de transición Agroecológica
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension3} strokeColor={twoColors} format={(percent) => percent === 100 ? `${percent}%` : `${percent}%`} />
              </Flex>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', marginTop: 16 }}>
        <Button
          onClick={goToPreviousTab}
          disabled={activeTabKey === '1'} // Deshabilitar si estamos en la primera pestaña
          style={{ flex: 1 }} // Asignar el mismo ancho
        >
          <ArrowLeftOutlined /> {/* Ícono para Atrás */}
          <span style={{ marginLeft: 8 }}>Atrás</span> {/* Espacio entre ícono y texto */}
        </Button>

        <Button
          onClick={datos} // Cambia la función de clic según el estado
          style={{ flex: 1 }} // Asignar el mismo ancho
        >
          {activeTabKey === '4' ? <><SendOutlined /> <span style={{ marginLeft: 8 }}>Enviar</span></> : <><ArrowRightOutlined /> <span style={{ marginLeft: 8 }}>Siguiente</span></>} {/* Cambia el texto del botón */}
        </Button>
      </div>
    </Modal>
  );
};

export default IpptaForm;
