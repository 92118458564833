import React, { useState, useEffect, useCallback } from 'react';
import { Spin, Layout, Table, Descriptions, Modal, Button, Form, Badge, Dropdown, Space } from 'antd';
import { UploadOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import { getPlanesPedagogicos } from '../../services/planesPedagogicos/planesPedagogicosServices';
import PPSoporte from './PlanPedagogicoSoporte';
const { Content } = Layout;


const PlanPedagogicoI = (codigo_tarea) => {
    const [treeData, setTreeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState("Cargar Soporte");
    const [dataPPSoporte, setDataPPSoporte] = useState([]);
    const [totalGeneral, setTotalGeneral] = useState(0);
    //Control de Permisos
    const appName = 'file-managerPOA';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionDelete = permissions.priv_delete === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionExport = permissions.priv_export === 'Y';
    const permissionPrint = permissions.priv_print === 'Y';

    const fetchPlanesPedagogicos = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getPlanesPedagogicos();
            const transformedData = transformData(response.data);  // Asumo que tienes una función de transformación de datos
            setTreeData(transformedData);
        } catch (error) {
            console.error('Error fetching planes pedagógicos:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchPlanesPedagogicos();
    }, [fetchPlanesPedagogicos]);

    const { codActividad, nameActividad, codigo_tarea: codigoTarea, tarea, indicador } = codigo_tarea.codigo_tarea;
    let medioVerificacion = "Medio de Verificación";
    const transformData = (data) => {
        return data.map((proyecto, index) => {
            const avanceEcas = calculatePercentage(proyecto.ecas[0].cantidadArchivos, proyecto.ecas[0].meta);
            const avanceVisitas = calculatePercentage(proyecto.visitas[0].cantidadArchivos, proyecto.visitas[0].meta);
            const avanceSesiones = calculatePercentage(proyecto.sesiones[0].cantidadArchivos, proyecto.sesiones[0].meta);
            const general = calcularGeneral(avanceEcas, avanceVisitas, avanceSesiones);

            const archivosPorSubcarpeta = {
                ...proyecto.ecas[0].archivosPorSubcarpeta,
                ...proyecto.visitas[0].archivosPorSubcarpeta,
                ...proyecto.sesiones[0].archivosPorSubcarpeta,
            };

            return {
                key: proyecto.oid,  // Clave única para cada proyecto
                contador: index + 1,  // Añadir un contador que empieza en 1
                name: proyecto.nombre,  // Mostramos el nombre del proyecto
                ejecucionEcas: proyecto.ecas[0].cantidadArchivos,
                metasEcas: proyecto.ecas[0].meta,
                avanceEcas: `${avanceEcas}%`,
                ejecucionVisitas: proyecto.visitas[0].cantidadArchivos,
                metasVisitas: proyecto.visitas[0].meta,
                avanceVisitas: `${avanceVisitas}%`,
                ejecucionSesiones: proyecto.sesiones[0].cantidadArchivos,
                metaSesiones: proyecto.sesiones[0].meta,
                avanceSesiones: `${avanceSesiones}%`,
                general: `${general}%`,
                soporte: proyecto.soporte || 0,
                oid: proyecto.oid,
                archivosPorSubcarpeta,
            };
        });
    };

    const handleUploadSoporte = (data) => {
        setTreeData(prevData =>
            prevData.map(item =>
                item.oid === data.oid ? { ...item, soporte: item.soporte + 1 } : item
            )
        );
    }


    const handleDeleteSoporte = (data) => {
        setTreeData(prevData =>
            prevData.map(item =>
                item.oid === data ? { ...item, soporte: item.soporte - 1 } : item
            )
        );
    }

    const calculatePercentage = (ejecucion, metas) => {
        return metas === 0 ? 0 : ((ejecucion / metas) * 100).toFixed(2);
    };

    const calcularGeneral = (ecaPercentage, visitaPercentage, sesionPercentage) => {
        const ecaValue = parseFloat(ecaPercentage) / 100;
        const visitaValue = parseFloat(visitaPercentage) / 100;
        const sesionValue = parseFloat(sesionPercentage) / 100;
        const total = ecaValue + visitaValue + sesionValue;
        const promedio = total / 3;
        return (promedio * 100).toFixed(2);
    };

    const calculateTotal = (data, field) => {
        console.log(data);
        return data.reduce((total, item) => total + (parseFloat(item[field]) || 0), 0);
    };

    const showModal = (item) => {
        setDataPPSoporte(item);
        setModalTitle(`${item.name}`);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setDataPPSoporte(null);
        setModalTitle("Cargar Soporte");
        setIsModalVisible(false);
    };


    return (
        <Layout>
            {loading ? (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="container-fluid py-0 my-2" style={{ marginBottom: '10px !important' }}>
                    <Descriptions bordered column={1} style={{ padding: '0 0 0 10px !important' }}>
                        <Descriptions.Item label="Actividad">
                            {codActividad} - {nameActividad}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tarea">
                            {codigoTarea} - {tarea}
                        </Descriptions.Item>
                        <Descriptions.Item label="Indicador">
                            {indicador}
                        </Descriptions.Item>
                        <Descriptions.Item label="Medio de verificación">
                            <div>
                                {typeof medioVerificacion === 'string'
                                    ? medioVerificacion
                                    : JSON.stringify(medioVerificacion)}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label={<span style={{ fontWeight: 'bold', color: '#0869C6' }}>Avance General</span>}>
                            <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                {totalGeneral} %
                            </div>
                        </Descriptions.Item>

                    </Descriptions>
                    <br />
                    <Layout>
                        <Layout style={{ padding: '0px 24px 24px' }}>
                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                <div style={{ textAlign: 'center', padding: '0px', marginTop: '-5px !important' }}>
                                    <p className="titulo_proyecto display-4">Bienvenid@</p>
                                    <div>
                                        <Table
                                            dataSource={treeData}
                                            scroll={{ y: 400 }}  // Puedes ajustar la altura aquí si es necesario
                                            pagination={{
                                                pageSize: 20,  // Número de filas por página
                                            }}
                                            columns={[
                                                {
                                                    title: 'No.',  // Título del contador
                                                    dataIndex: 'contador',  // Columna de contador
                                                    key: 'contador',
                                                    width: 80,
                                                    align: 'center',
                                                },
                                                {
                                                    title: 'Planes pedagógicos implementados',
                                                    dataIndex: 'name',
                                                    key: 'name',
                                                    width: 300,
                                                },
                                                {
                                                    title: 'ECAS',
                                                    children: [
                                                        {
                                                            title: 'Metas',
                                                            dataIndex: 'metasEcas',
                                                            key: 'metasEcas',
                                                            width: 80,
                                                            align: 'center',
                                                        },
                                                        {
                                                            title: 'Ejecución',
                                                            dataIndex: 'ejecucionEcas',
                                                            key: 'ejecucionEcas',
                                                            width: 100,
                                                            align: 'center',
                                                        },
                                                        {
                                                            title: '% Avance',
                                                            dataIndex: 'avanceEcas',
                                                            key: 'avanceEcas',
                                                            width: 100,
                                                            align: 'center',
                                                        },
                                                    ],
                                                },
                                                {
                                                    title: 'Visitas de acompañamiento',
                                                    children: [
                                                        {
                                                            title: 'Metas',
                                                            dataIndex: 'metasVisitas',
                                                            key: 'metasVisitas',
                                                            width: 80,
                                                            align: 'center',
                                                        },
                                                        {
                                                            title: 'Ejecución',
                                                            dataIndex: 'ejecucionVisitas',
                                                            key: 'ejecucionVisitas',
                                                            width: 100,
                                                            align: 'center',
                                                        },
                                                        {
                                                            title: '% Avance',
                                                            dataIndex: 'avanceVisitas',
                                                            key: 'avanceVisitas',
                                                            width: 100,
                                                            align: 'center',
                                                        },
                                                    ],
                                                },
                                                {
                                                    title: 'Sesiones de capacitación',
                                                    children: [
                                                        {
                                                            title: 'Metas',
                                                            dataIndex: 'metaSesiones',
                                                            key: 'metaSesiones',
                                                            width: 80,
                                                            align: 'center',
                                                        },
                                                        {
                                                            title: 'Ejecución',
                                                            dataIndex: 'ejecucionSesiones',
                                                            key: 'ejecucionSesiones',
                                                            width: 100,
                                                            align: 'center',
                                                        },
                                                        {
                                                            title: '% Avance',
                                                            dataIndex: 'avanceSesiones',
                                                            key: 'avanceSesiones',
                                                            width: 100,
                                                            align: 'center',
                                                        },
                                                    ],
                                                },
                                                {
                                                    title: '% general de avance',
                                                    dataIndex: 'general',
                                                    key: 'general',
                                                    width: 100,
                                                    align: 'center',
                                                },
                                                {
                                                    title: 'Soporte',
                                                    dataIndex: 'soporte',
                                                    key: 'soporte',
                                                    width: 100,
                                                    align: 'center',
                                                    render: (text, record) => (
                                                        <Button
                                                            icon={<UploadOutlined style={{ color: record.soporte === 0 ? 'red' : 'green', fontSize: '20px' }} />}
                                                            onClick={() => showModal(record)}
                                                        />),
                                                },
                                            ]}
                                            expandable={{
                                                expandedRowRender: (record) => {
                                                    // Aquí puedes obtener archivosPorSubcarpeta si lo necesitas
                                                    const archivos = record.archivosPorSubcarpeta;
                                                    // Procesar archivosPorSubcarpeta para agrupar datos por fecha de corte
                                                    const dataSource = {};
                                                    Object.entries(archivos).forEach(([key, value]) => {
                                                        // Extraer la fecha de corte de la clave
                                                        const match = key.match(/(\d+)\.\s*(.*?)(\s+\w+-\w+-\d+)/);
                                                            if (match) {
                                                            const corte = match[3].trim(); // Obtener la parte de la fecha de corte
                                                            const tipo = match[2].trim(); // Obtener el tipo (Capacitación, ECA, Visitas)
                                                            // Inicializar la entrada en el objeto si no existe
                                                            if (!dataSource[corte]) {
                                                                dataSource[corte] = {
                                                                    corte: corte,
                                                                    visitas: 0,
                                                                    eca: 0,
                                                                    capacitacion: 0,
                                                                };
                                                            }
                                                            // Asignar el valor correspondiente
                                                            if (tipo.includes('Capacitación')) {
                                                                dataSource[corte].capacitacion += value;
                                                            } else if (tipo.includes('ECA')) {
                                                                dataSource[corte].eca += value;
                                                            } else if (tipo.includes('Visitas')) {
                                                                dataSource[corte].visitas += value;
                                                            }
                                                        }
                                                    });
                                                    // Convertir el objeto a un array para el dataSource
                                                    const dataSourceArray = Object.values(dataSource);
                                                    return (
                                                        <Table
                                                            style={{ marginTop: '15px', width: '800px', paddingBottom: '20px' }}
                                                            dataSource={dataSourceArray}
                                                            pagination={false}
                                                            columns={[
                                                                {
                                                                    title: 'Fechas de Corte',
                                                                    dataIndex: 'corte',
                                                                    key: 'corte',
                                                                    render: (text) => {
                                                                      return text.replace(/\b\w+/g, (word) => {
                                                                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                                      });
                                                                    },
                                                                  },                                                                  
                                                                {
                                                                    title: 'Visitas',
                                                                    align: 'center',
                                                                    dataIndex: 'visitas',
                                                                    key: 'visitas',
                                                                },
                                                                {
                                                                    title: 'ECA',
                                                                    align: 'center',
                                                                    dataIndex: 'eca',
                                                                    key: 'eca',
                                                                },
                                                                {
                                                                    title: 'Capacitación',
                                                                    align: 'center',
                                                                    dataIndex: 'capacitacion',
                                                                    key: 'capacitacion',
                                                                },
                                                            ]}
                                                        />
                                                    );
                                                },
                                                rowExpandable: (record) => Object.keys(record.archivosPorSubcarpeta).length > 0,
                                                expandIcon: ({ onExpand, expanded, record }) => (
                                                    <span onClick={e => onExpand(record)} style={{ cursor: 'pointer' }}>
                                                        {expanded ? <MinusOutlined /> : <PlusOutlined />}
                                                    </span>
                                                ),
                                            }}
                                            summary={(pageData) => {
                                                const totalEjecucionSesiones = parseFloat((calculateTotal(pageData, 'general') / 18).toFixed(2));
                                                setTotalGeneral(totalEjecucionSesiones);
                                                return (
                                                    <>
                                                        <Table.Summary.Row>
                                                            <Table.Summary.Cell index={0} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={1} ><strong>Total</strong></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={2} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={3} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={4} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={5} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={6} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={7} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={8} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={9} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={10} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={11} align="center"></Table.Summary.Cell>
                                                            <Table.Summary.Cell index={12} align="center">{totalEjecucionSesiones}%</Table.Summary.Cell>
                                                        </Table.Summary.Row>
                                                    </>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                            </Content>
                        </Layout>
                    </Layout>
                </div>
            )}
            <Modal
                title={<h5 style={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: '10px' }}>{modalTitle}</h5>}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={400}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <PPSoporte
                        dataPPSoporte={dataPPSoporte}
                        onUpload={handleUploadSoporte} // Pasar función de subir
                        onDelete={handleDeleteSoporte} // Pasar función de eliminar
                    />
                </Form>
            </Modal>
        </Layout>
    );
};

export default PlanPedagogicoI;
