// Importaciones
import React, { useEffect, useState } from 'react';
import { Button, Table, Spin, Row, Col, Progress, Flex, Modal, Tooltip, Select, Input } from 'antd';
import { DownloadOutlined, PlusOutlined, EditOutlined, BarChartOutlined,  ClearOutlined  } from '@ant-design/icons';
import { getIppta } from '../../services/ippta/get_ippta';
import { selectAppPermissions } from '../../store/auth/selectors';
import { useSelector } from 'react-redux';
import IpptaForm from './IpptaForm';
import IpptaRadar from './IpptaRadar';
import { getProyectos } from '../../services/ippta/get_dataIppta'; // Asegúrate de que esta ruta sea correcta
import IpptaExport from './IpptaExport';
import { getIpptaExportable } from '../../services/ippta/get_ippta';
// Define las columnas de la tabla
const twoColors = {
  '0%': '#108ee9',
  '100%': '#87d068',
};
const { Option } = Select;
const Ippta = () => {
  const [dataSource, setDataSource] = useState([]); // Para almacenar los datos
  const [loading, setLoading] = useState(true); // Para controlar la carga
  const [status, setStatus] = useState(null); // Para manejar el estado de error
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showRadar, setShowRadar] = useState(false);
  const [formData, setFormData] = useState(null);
  const [identificacionGrafica, setIdentificacionGrafica] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [originalData, setOriginalData] = useState([]); // Datos originales
  const [identificacionFilter, setIdentificacionFilter] = useState('');
  const [proyectoFilter, setProyectoFilter] = useState('');
  const [proyectos, setProyectos] = useState([]);
  // Control de Permisos
  const appName = 'sistematizacionIppta'; // El nombre de la aplicación actual
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionInsert = permissions.priv_insert === 'Y';
  const permissionExport = permissions.priv_export === 'Y';
  

  const handleEdit = (record) => {
    setFormData(record); // Establece los datos del registro que se está editando
    setIsModalVisible(true); // Abre el modal
  };

  const handleChart = (record) => {
    setIdentificacionGrafica(record.identificacion);
    setShowRadar(true);
  };


  const fetchData = async () => {
    setLoading(true); // Comenzar a cargar
    try {
      const proyectosData = await getProyectos();
      setProyectos(proyectosData.data || []); // Asegúrate de que sea un arreglo

      const result = await getIppta();
      const combinedData = result.data.map(item => ({
        ...item,
        codigoProyecto: `${item.codigo} - ${item.proyecto}`,
        medicionGeneral: `${Math.round(
          (
            (item.productiva / 35) +
            (item.socioeconomico / 25) +
            (item.ambiental / 30)
          ) / 3 * 100
        )}%`
      }));
      setDataSource(combinedData);
      setOriginalData(combinedData);
      setStatus(null); // Reiniciar el estado de error
    } catch (error) {
      setStatus(error.response ? error.response.status : 500);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  if (loading) {
    return <Spin tip="Cargando..." />;
  }


  const showModal = () => {
    setFormData(null);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setFormData(null);
    setIsModalVisible(false);
  };

  const handleCloseModalRadar = () => {
    setShowRadar(false);
  };



  const exportToExcel = async () => {
    const response = await getIpptaExportable();
    const data = response.data;
  
    // Filtrar los datos según los filtros aplicados
    const filteredData = data.filter(item => {
      return (
        (identificacionFilter === '' || item.identificacion.includes(identificacionFilter)) &&
        (proyectoFilter === '' || item.proyecto === proyectoFilter) // Ajustar a item.proyecto
      );
    });
  
    const updatedData = filteredData.map(item => {
      // Función para calcular la suma de los indicadores
      const calcularSuma = (prefijo, cantidad) => {
        return Array.from({ length: cantidad }, (_, i) => item[`${prefijo}_indicador${i + 1}`] || 0)
                    .reduce((acc, curr) => acc + curr, 0);
      };
  
      // Sumas de indicadores
      const sumaProductivo = calcularSuma('productivo', 7); // Cambia '7' según la cantidad de indicadores productivos
      const sumaSocioeconomico = calcularSuma('socioeconomico', 5); // Cambia '5' según la cantidad de indicadores socioeconómicos
      const sumaAmbiental = calcularSuma('ambiental', 6); // Cambia '6' según la cantidad de indicadores ambientales
  
      // Cálculo de porcentajes
      const porcentajeProductivo = ((sumaProductivo / 35) * 100).toFixed(2) + '%';
      const porcentajeSocioeconomico = ((sumaSocioeconomico / 25) * 100).toFixed(2) + '%';
      const porcentajeAmbiental = ((sumaAmbiental / 30) * 100).toFixed(2) + '%';
  
      // Promedio total de los porcentajes
      const porcentajeTotal = (
        ((sumaProductivo / 35) + (sumaSocioeconomico / 25) + (sumaAmbiental / 30)) / 3 * 100
      ).toFixed(2) + '%';
  
      return {
        ...item,
        porcentajeProductivo,
        porcentajeSocioeconomico,
        porcentajeAmbiental,
        porcentajeTotal
      };
    });
    setExportData(updatedData);
  };
  
  
  
  const filteredData = dataSource.filter(item => {
    return (
      (identificacionFilter === '' || item.identificacion.includes(identificacionFilter)) &&
      (proyectoFilter === '' || item.codigo === proyectoFilter) // Filtro por proyecto_codigo
    );
  });


  const handleClearFilters = () => {
    setIdentificacionFilter('');  // Reinicia el filtro de identificación
    setProyectoFilter('');        // Reinicia el filtro de proyecto
    setDataSource(originalData);   // Vuelve a cargar los datos originales
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1 // Enumeración
    }, {
      title: 'Acciones',
      key: 'acciones',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '30px', justifyContent: 'center' }}>
          <Tooltip title="Editar">
            <EditOutlined

              onClick={() => handleEdit(record)}
              style={{ cursor: 'pointer', color: '#1890ff' }}
            />
          </Tooltip>
          <Tooltip title="Ver gráfico">
            <BarChartOutlined
              onClick={() => handleChart(record)} // Asegúrate de definir esta función
              style={{ cursor: 'pointer', color: '#1890ff' }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Proyecto', // Cambiar título a uno combinado
      dataIndex: 'codigoProyecto', // Campo combinado
      key: 'codigoProyecto',
      responsive: ['md'], // Visible en pantallas medianas en adelante
      render: (text) => <span>{text}</span>, // Renderiza el texto
    }, {
      title: 'Identificación',
      dataIndex: 'identificacion',
      key: 'identificacion',
      responsive: ['md'], // Visible en pantallas medianas en adelante
    },
    {
      title: 'Participante',
      dataIndex: 'participante',
      key: 'participante',
      responsive: ['sm'], // Visible en pantallas pequeñas y más grandes
    },
    /*{
      title: 'Celular',
      dataIndex: 'celular',
      key: 'celular',
      responsive: ['sm'], // Visible en pantallas pequeñas y más grandes
    },*/
    {
      title: 'Vereda',
      dataIndex: 'vereda',
      key: 'vereda',
      responsive: ['md'], // Visible en pantallas medianas en adelante
    },
    {
      title: 'Municipio',
      dataIndex: 'municipio',
      key: 'municipio',
      responsive: ['lg'], // Visible solo en pantallas grandes
    },
    {
      title: 'Departamento',
      dataIndex: 'departamento',
      key: 'departamento',
      responsive: ['md'], // Visible en pantallas medianas en adelante
    },
    /*{
      title: 'Entidad',
      dataIndex: 'entidad',
      key: 'entidad',
      responsive: ['lg'], // Visible solo en pantallas grandes
    },*/
    {
      title: 'Medición General',
      dataIndex: 'medicionGeneral',
      key: 'medicionGeneral',
      responsive: ['lg'],
      render: (text) => {
        // Extraer el valor numérico del texto y convertirlo a número
        const percent = parseInt(text.replace('%', ''), 10);
        return (
          <Flex vertical gap="middle" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Progress percent={percent} strokeColor={twoColors} format={percent === 100 ? () => '100%' : () => `${percent}%`} // Muestra el porcentaje
            />
          </Flex>
        );
      },
    }
  ];

  return (
    <div className="container">
      <React.Fragment>
        <React.Fragment>
          <div className="row mb-2 d-flex align-items-center" >
            <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
              <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                <p>PROYECTO UTF <br /> COL 160 COL</p>
              </div>
              <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
              <h2 className="text-center mb-2">IPPTA</h2>
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="input-group shadow-sm">
                    {permissionInsert && (
                      <React.Fragment>
                        <Button
                          type="danger"
                          className="btn btn-primary me-2"
                          size="large"
                          icon={<PlusOutlined />}
                          onClick={showModal} // Abre el modal
                        >
                          Nueva
                        </Button>
                        <IpptaForm
                          visible={isModalVisible}
                          onClose={handleCloseModal}
                          formData={formData}
                          loadData={fetchData}
                        />
                      </React.Fragment>
                    )}
                    {permissionExport && (
                      <Button
                        type="danger"
                        className="btn btn-primary me-2"
                        size="large"
                        onClick={exportToExcel}
                        icon={<DownloadOutlined />}
                      >
                        Excel
                      </Button>                    
                    )}{exportData && <IpptaExport data={exportData} />}                
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row gutter={24}>
            <Row gutter={24} style={{ marginBottom: '20px', width: '100%' }}>
              <Col xs={24} sm={4} style={{ marginBottom: '10px' }}>
                <Input
                  placeholder="Filtrar por Identificación"
                  value={identificacionFilter}
                  onChange={(e) => setIdentificacionFilter(e.target.value)}
                  style={{ height: '40px', padding: '10px' }}
                />
              </Col>
              <Col xs={24} sm={8} style={{ marginBottom: '10px' }}>
                <Select
                   style={{ textAlign: 'left', width: '100%', height: '40px' }} 
                  placeholder="Filtrar por proyecto"
                  showSearch
                  value={proyectoFilter || undefined}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setProyectoFilter(value)}
                  dropdownStyle={{ zIndex: 1000 }}
                >
                  {proyectos?.map(proyecto => (
                    <Option key={proyecto.proyecto_oid} value={proyecto.proyecto_codigo}>
                      {`${proyecto.proyecto_codigo} - ${proyecto.nombre_proyecto}`}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={8} style={{ textAlign: 'left', marginBottom: '10px'}}>
                <Button
                  type="default"
                  onClick={handleClearFilters}
                  icon={<ClearOutlined />}
                  style={{ height: '40px' }} 
                >
                  Limpiar Filtros
                </Button>
              </Col>
            </Row>
            <Col span={24}>
              <Table
                className="custom-ippta-table"
                dataSource={filteredData}
                columns={columns}                
                pagination={true}
                
              />
            </Col>
          </Row>
        </React.Fragment>
      </React.Fragment>
      <Modal
        title={<h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Gráfico IPPTA</h3>}
        visible={showRadar}
        width={1200}
        bodyStyle={{ height: '1100px' }}
        onCancel={handleCloseModalRadar}
        footer={null} // Oculta el footer
      >
        <IpptaRadar formData={identificacionGrafica} />
      </Modal>
    </div>
  );
};

export default Ippta;
